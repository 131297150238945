import { Col, Row, Table, Pagination, FormInstance, Button, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useExportDataMutation, useLazyGetFieldReportQuery, useLazyGetFieldReportTotalQuery } from "../redux/fieldReportApi";

interface DataListTable {
    key: string,
    index: number,
    amount_field?: number,
    amount_field_edited?: number,
    field?: string,
    type?: string,
    percent?: string
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetFieldReportQuery();
    const [triggerReportTotal, responseReportTotal] = useLazyGetFieldReportTotalQuery();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [triggerExport] = useExportDataMutation();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);

    useEffect(() => {
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.length) {
            let newTableData: DataListTable[] = [];
            responseReport.data.forEach((item, index) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    amount_field: item.amount_field,
                    amount_field_edited: item.amount_field_edited,
                    field: item.key,
                    type: item.type,
                    percent: ((item.amount_field_edited || 0) / (item.amount_field || 1)) + "%"
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Loại dữ liệu",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Tên trường",
            dataIndex: "field",
            key: "field",
        },
        {
            title: "Tổng số",
            dataIndex: "amount_field",
            key: "amount_field",
        },
        {
            title: "Số lần chỉnh sửa",
            dataIndex: "amount_field_edited",
            key: "amount_field_edited",
        },
        {
            title: "Tỷ lệ chỉnh sửa (%)",
            dataIndex: "percent",
            key: "percent",
        },
    ];

    const getData = () => {
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportTotal({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}></Col>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;