import { Breadcrumb, Button, Col, Form, Input, Row, Select } from "antd";
import { ReactComponent as Home } from '../../../assets/logos/home.svg';
import React, { useEffect, useState } from "react";
import ReportList from "./components/ReportList";
import { useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { TYPE_DATA } from "../../../assets/ts/constant";
import { DatePicker } from "../../../custom-components";
import { subMonths, startOfMonth, endOfMonth, startOfYear, subYears, endOfYear } from "date-fns";
import { useLocation } from "react-router-dom";
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
const { RangePicker } = DatePicker;

export const TYPE_QS: Record<string, string> = {
    "Excel": "Excel",
    "PDF": "PDF",
};

const UploadDuLieuPDFReport = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [searchParam, setSearchParam] = useState({});
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();

    useEffect(() => {
        triggerUserCheck({});
        triggerProvince({});
        if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            setSearchParam(newSearchParam);
            form.setFieldsValue(newSearchParam)
        }
        //  else {
        //     form.setFieldsValue({ dates: [new Date(), new Date()] });
        //     setSearchParam({ dates: [new Date(), new Date()] });
        // }
    }, [triggerUserCheck, state]);

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] === "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceId) {
                districtTrigger(changedValues.provinceId).unwrap()
                    .then((res: any) => setDistricts(res));
            } else {
                setDistricts([]);
            }
            form.setFieldsValue({
                districtId: undefined,
                communeId: undefined,
            })

        } else if (Object.keys(changedValues)[0] === "districtId") {
            setCommunes([]);
            if (changedValues.districtId) {
                communeTrigger(changedValues.districtId).unwrap()
                    .then((res: any) => setCommunes(res));
            } else {
                setCommunes([]);
            }
            form.setFieldsValue({
                communeId: undefined
            })
        }
    }

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    const resetFormData = () => {
        form.resetFields();
    }
    const [isSearch, setIsSearch] = useState(false);
    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Báo cáo hiệu năng</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            {/* <div className='title-label' style={{ marginBottom: 20 }}>Báo cáo upload dữ liệu</div> */}

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChanged}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>

                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>

                            {/* <Col span={8} xs={24} md={12} lg={12} xl={8}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Ngày import"
                                name="dates"
                            >
                                <RangePicker
                                    format="DD/MM/Y"
                                    placeholder={["Bắt đầu", "Kết thúc"]}
                                    ranges={{
                                        'Hôm nay': [new Date(), new Date()],
                                        'Tháng này': [startOfMonth(new Date()), new Date()],
                                        'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                        '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                        '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                        'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                        'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                    }}
                                />
                            </Form.Item>
                        </Col> */}

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Tỉnh/Thành phố"
                                    name="provinceId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn tỉnh/Thành phố"
                                        optionFilterProp='label'
                                        options={(responseProvince?.data || []).map((item: any) => ({
                                            label: item.provinceName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Quận/Huyện"
                                    name="districtId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn quận huyện"
                                        optionFilterProp='label'
                                        options={(districts || []).map(item => ({
                                            label: item.districtName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Phường/Xã"
                                    name="communeId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn phường xã"
                                        options={(communes || []).map(item => ({
                                            label: item.communeName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount='responsive'
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Loại hộ tịch"
                                    name="typeIds"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại hộ tịch"
                                        optionFilterProp='label'
                                        options={Object.keys(TYPE_DATA).map(item => ({
                                            label: `${TYPE_DATA[item]} (${item})`,
                                            value: item
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount={"responsive"}
                                        mode="multiple"
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Năm mở sổ"
                                    name="namMoSo"
                                >
                                    <Input placeholder="Nhập năm mở sổ"></Input>
                                </Form.Item>
                            </Col>

                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Quyển số"
                                    name="excelName"
                                >
                                    <Input placeholder="Nhập quyển số"></Input>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12} xl={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Dữ liệu import"
                                    name=""
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn Dữ liệu import"
                                        optionFilterProp='label'
                                        options={Object.keys(TYPE_QS).map(item => ({
                                            label: `${TYPE_QS[item]}`,
                                            value: item
                                        }))}
                                        showSearch
                                        showArrow
                                        maxTagCount={"responsive"}
                                        defaultValue="PDF"
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>

                        </Row>
                        <div className='search-box-button'>
                            <Button htmlType='button' onClick={() => resetFormData()}>
                                Xóa điều kiện
                            </Button>
                            <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                        </div>
                    </> : <></>}

                </Form>
            </div>
            <ReportList form={form} searchParam={searchParam} />
        </>
    )
}

export default UploadDuLieuPDFReport;
