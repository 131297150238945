import { Button, Checkbox, Col, Collapse, Form, Input, notification, Row, Select, Spin, Tag } from "antd";
import {
    useLazyGetFieldConfigQuery
} from "../../check-data/redux/checkDataApi";
import { useContext, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { FieldConfig } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import { DEFAULT_FIELD_CONFIG, TYPE_FILE_PDF } from "../../../assets/ts/constant";
import { useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { useLazyGetSortIdQuery } from "../../data/redux/dataCheckApi";
import { Link, useLocation, useParams } from "react-router-dom";
import { useLazyGetDataInfoInitHistoryByIdQuery, useUpdateDataInfoInitMutation } from "../redux/dataInitHistoryApi";
import { ClockCircleOutlined, ExclamationCircleOutlined, UserOutlined } from "@ant-design/icons";
import moment from "moment";
import { useAppSelector } from "../../../redux/Hooks";
import { helpers } from "../../../assets/js/helper";
import Item from "antd/lib/list/Item";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PDFPageProxy } from 'react-pdf';
import { da } from "date-fns/locale";
import SplitPane from "react-split-pane";
import { DataInfoContext } from "../../../layouts/context/DataInfoProvider";
const { Panel } = Collapse;

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);

    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);

    useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
    return width;
};

const DetailDataInfoInitHistory = () => {
    const [form] = Form.useForm();
    const param = useParams();
    const { state } = useLocation();
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [orderNumber, setOrderNumber] = useState(1);
    const [pdfFileName, setPdfFileName] = useState('');
    const [displayFlex, setDisplayFlex] = useState(true);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [keyMapOlds, setKeyMapOlds] = useState<FieldConfig[]>([]);
    const userState = useAppSelector(state => state.user.user);
    const [editing, setEditing] = useState(false);
    const [districts, setDistricts] = useState<District[]>([]);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmNextextVisible, setConfirmNextextVisible] = useState(false);

    const [triggerData, dataResponse] = useLazyGetDataInfoInitHistoryByIdQuery();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [triggerCurrentSort, currentSortResponse] = useLazyGetSortIdQuery();
    const [triggerGetFieldConfig, fieldConfigResponse] = useLazyGetFieldConfigQuery();
    const [updateDataInit] = useUpdateDataInfoInitMutation();
    const [historyHaveEdit, setHistoryHaveEdit] = useState<boolean>(false);

    const wrapperDiv = useRef<HTMLInputElement>(null);
    // const width = useWidth(wrapperDiv);
    const file = useMemo(() => {
        let uri = '';
        let pathName = "";
        if (dataResponse?.data?.dataInfoInit?.filePDFId) {
            uri = `/api/v1/file/download-pdf/${dataResponse?.data?.dataInfoInit?.filePDFId}`;
        } else if (dataResponse?.data?.dataInfoInit?.excelName && pdfFileName) {
            let excelNames = dataResponse?.data?.dataInfoInit?.excelName?.split("_");
            let excelNames1 = dataResponse?.data?.dataInfoInit?.excelName?.split(".");
            pathName = `${excelNames?.[1]}/${excelNames?.[0]}/${dataResponse?.data?.dataInfoInit.typeId}/${excelNames1?.[2]}/${excelNames1?.[3]}/${pdfFileName}`
            uri = `/api/v1/file/download-pdf?dataInfoId=${dataResponse?.data?.dataInfoInit?.id}&pathFile=${pathName}`;
        }
        // if (dataResponse?.data?.filePdf?.id) {
        //     uri = `/api/v1/file/download-pdf/${dataResponse?.data?.filePdf?.id}`;
        return {
            url: process.env.REACT_APP_API_URL + uri,
            httpHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        }
        // }
    }, [dataResponse?.data?.id, dataResponse?.data?.excelName, pdfFileName]);
    const [initialPos, setInitialPos] = useState(0);
    const [initialSize, setInitialSize] = useState(0);

    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const canvas1 = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);

    useEffect(() => {
        if (clientY === 0 || !canvas.current || !isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
        if (canvas1.current) {
            const context1 = canvas1.current.getContext('2d');
            const width1 = canvas1.current.width;
            const rect1 = canvas1.current.getBoundingClientRect();
            context1.save();
            context1.beginPath();
            context1.moveTo(0, clientY);
            context1.lineTo(width1, clientY);
            context1.strokeStyle = 'red';
            context1.stroke();
            context1.restore();
        }
    }, [clientY, isRendered]);

    useEffect(() => {
        if (prevScale.current === scale) {
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY) => prevClientY * (scale / prevScale.current));
            setIsRendered(false);
        }
    }, [scale])

    useEffect(() => {
        const slider = document.getElementById("block-view-pdf");
        let isDown = false;
        let startX: any;
        let startY: any;
        let scrollLeft: any;
        let scrollTop: any;
        if (slider) {
            slider.addEventListener('mousedown', (e) => {
                isDown = true;
                slider.classList.add('active');
                startX = e.pageX - slider.offsetLeft;
                startY = e.pageY - slider.offsetTop;
                scrollLeft = slider.scrollLeft;
                scrollTop = slider.scrollTop;
                setIsDrag(false);
            });
            slider.addEventListener('mouseleave', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mouseup', () => {
                isDown = false;
                slider.classList.remove('active');
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageX - slider.offsetLeft;
                const walk = (x - startX) * 3; //scroll-fast
                slider.scrollLeft = scrollLeft - walk;
                setIsDrag(true);
            });
            slider.addEventListener('mousemove', (e) => {
                if (!isDown) return;
                e.preventDefault();
                const x = e.pageY - slider.offsetTop;
                const walk = (x - startY) * 3; //scroll-fast
                slider.scrollTop = scrollTop - walk;
                setIsDrag(true);
            });
        }
    }, []);

    useEffect(() => {
        if (displayFlex) {
            setWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
            setInitWidth((wrapperDiv?.current?.getBoundingClientRect()?.width || 0) / 3 || undefined);
        }
    }, [wrapperDiv?.current?.getBoundingClientRect()?.width, displayFlex]);

    const onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if (!isDrag) {
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top) * scaleY);
        }
    }

    useEffect(() => {
        if (param?.id) {
            triggerData(param.id);
        }
    }, [triggerData, param?.id]);

    const initial = (e: any) => {
        let resizable = document.getElementById('block-view-pdf');
        setInitialPos(e.clientX);
        setInitialSize(resizable?.offsetWidth || 0);
    }

    const resize = (e: any) => {
        let resizable = document.getElementById('block-view-pdf');
        if (resizable) {
            resizable.style.width = `${initialSize + e.clientX - initialPos}px`;
        }
    }

    useEffect(() => {
        // If have filePdfId is DataInfoInit
        if (dataResponse?.data?.filePdf?.id || dataResponse?.data?.dataInfoInit?.typeId) {
            triggerProvince({});
            let typeFile = dataResponse?.data?.filePdf?.typeId || dataResponse?.data?.dataInfoInit?.typeId;
            if (typeFile) {
                triggerGetFieldConfig({ types: typeFile, years: convertYearToTypePdf(typeFile) }).unwrap().then(res => {
                    let dataKeys = dataResponse?.data?.dataInfoInitNew?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
                    let dataKeyOlds = dataResponse?.data?.dataInfoInit?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
                    let dataFieldValue = dataResponse?.data?.dataInfoInitNew?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {});

                    // Cập nhật thông tin đầu vào cho data info init
                    form.setFieldsValue(dataFieldValue);
                    if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]) {
                        districtTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.tinhThanh]).unwrap()
                            .then(dists => setDistricts(dists));
                    }
                    if (dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]) {
                        communeTrigger(dataFieldValue[DEFAULT_FIELD_CONFIG[typeFile]?.quanHuyen]).unwrap()
                            .then(coms => setCommunes(coms));
                    }

                    if (dataFieldValue?.[DEFAULT_FIELD_CONFIG[typeFile]?.tenfilepdf]) {
                        setPdfFileName(dataFieldValue[DEFAULT_FIELD_CONFIG?.[typeFile]?.tenfilepdf]);
                    }

                    // Cập nhật thông tin key maps
                    setKeyMaps((res.fieldKeyMap || dataResponse?.data?.dataInfoInitNew?.dataKeyMap).map((item: any, index: number) => {
                        return {
                            ...item,
                            // dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            // dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                            beforeEdited: dataKeys[item.dataKeyAscii]?.isEdited,
                        }
                    }));
                    // Cập nhật thông tin key maps
                    setKeyMapOlds((res.fieldKeyMap || dataResponse?.data?.dataInfoInit?.dataKeyMap).map((item: any, index: number) => {
                        return {
                            ...item,
                            // dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            // dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeyOlds[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                            beforeEdited: dataKeyOlds[item.dataKeyAscii]?.isEdited
                        }
                    }));
                });
            } else {
                setKeyMaps([]);
            }
            // else not filePdfId is DataInfo
        } else if (dataResponse?.data) {
            let dataKeys = dataResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
            triggerCurrentSort(dataResponse?.data?.dataFile?.id).unwrap()
                .then((res: any) => {
                    setKeyMaps((res?.keyMaps || dataResponse?.data?.keyMaps).map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                        }
                    }));
                }).catch((err: any) => {
                    setKeyMaps(dataResponse?.data?.keyMaps.map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                        }
                    }));
                });
        }
    }, [triggerGetFieldConfig, triggerProvince, dataResponse]);



    const convertYearToTypePdf = (typeFile: string) => {
        // KS.1994.01.--.001.PDF
        let yearStr = dataResponse?.data?.filePdf?.fileName?.split(".")?.[1];
        let yearNum = yearStr ? parseInt(yearStr) : 0;
        // TODO: LƯU Ý CHECK LẠI RULE NÀY KHI TRIỂN KHAI CHO QUẢNG NGÃI (ĐẶC BIỆT GIAI ĐOẠN <= 1977)
        // Chuyển từ năm sang file loại file: A3, A4
        if (typeFile === "KH" && yearNum === 2007 && dataResponse?.data?.filePdf?.communeCode === "xatinhantay") {
            return TYPE_FILE_PDF.A3;
        }
        if (yearNum > 2006) {
            if (typeFile === "KH" &&
                dataResponse?.data?.filePdf?.communeCode === "sotuphap" &&
                dataResponse?.data?.filePdf?.districtCode === "thanhphoquangngai") {
                if (yearNum < 2011) {
                    return TYPE_FILE_PDF.A3;
                }
            }
            return TYPE_FILE_PDF.A4;
        }
        if (yearNum > 1977) {
            return TYPE_FILE_PDF.A3;
        }
        switch (typeFile) {
            case "KS":
                return TYPE_FILE_PDF.A4
            default:
                return TYPE_FILE_PDF.A4;
        }
    }

    // Check quyền chỉnh sửa dữu liệu nhập liệu
    const checkPermission = (path: string) => {
        if (userState.username === "admin" ||
            userState.username === dataResponse?.data?.createBy ||
            (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
        return false;
    }

    const onFinish = (values: any) => {
        form.validateFields().then(res => {
            toggleConfirmModal();
        })
    }

    const onSubmitCheckData = () => {
        // If have filePdfId is DataInfoInit
        let keyMapsObj = keyMaps.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})
        if (fieldConfigResponse?.data?.type &&
            (dataResponse?.data?.filePdf?.id || dataResponse?.data?.dataInfoInit?.typeId) &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen] &&
            keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa]) {
            updateDataInit({
                keyMaps,
                id: dataResponse?.data?.dataInfoInitNew?.id,
                communeId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.phuongXa],
                districtId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.quanHuyen],
                provinceId: keyMapsObj?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tinhThanh],
                status: "2"
            }).unwrap()
                .then(res => {
                    notification.success({ message: "Cập nhật kết quả thành công" });
                    toggleConfirmModal();
                    setEditing(false);
                }).catch(err => {
                    notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
                })
        } else {
            // updateData({
            //     keyMaps,
            //     id: dataResponse?.data?.id,
            //     isCheck: true,
            // }).unwrap()
            //     .then(res => {
            //         notification.success({ message: "Cập nhật kết quả thành công" });
            //         // triggerData(param.id);
            //         toggleConfirmModal();
            //     }).catch(err => {
            //         notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            //     })
        }
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
        if (!newDisplayFlex) {
            setWidth(initWidth * 2);
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    // Change dataValue Of Data Info Init
    const changeDataInit = (value: any, index: number, item: FieldConfig) => {
        let dataKeys = dataResponse?.data?.dataInfoInitNew?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        // Nếu không cho sửa thì không được sửa
        if (!item.isEdit) return;
        if (item?.dataKeyAscii) {
            form.setFieldsValue({ [item.dataKeyAscii]: value });
        }
        switch (item.dataKeyAscii) {
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                form.setFieldsValue({ quanHuyen: undefined, phuongXa: undefined })
                setDistricts([]);
                setCommunes([]);
                districtTrigger(value).unwrap()
                    .then(res => setDistricts(res));
                break;
            case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                form.setFieldsValue({ phuongXa: undefined })
                setCommunes([]);
                communeTrigger(value).unwrap()
                    .then(res => setCommunes(res));
                break;
        }
        // KS.2007.01.2007-07-11.082.pdf
        let changeFilePdf = item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].so ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quyenSo ||
            item.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].ngayDangKy;
        let tenFilePdf = "";
        if (changeFilePdf) {
            let type = dataResponse?.data?.filePdf?.fileName?.split(".")?.[0] || dataResponse?.data?.dataInfoInit?.typeId;
            let so = form?.getFieldsValue()?.[DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].so];
            let quyenSo = form?.getFieldsValue()?.quyenSo?.split("/");
            let ngayDangKy = form?.getFieldsValue()?.ngayDangKy?.split(".");
            // ngayDangKy = ngayDangKy.length === 3 ? ngayDangKy : [];
            let temp = '';
            if (ngayDangKy?.length === 3) {
                temp = `${ngayDangKy?.[2] || ""}-${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}`;
            } else if (ngayDangKy?.length === 2) {
                temp = `${ngayDangKy?.[1] || ""}-${ngayDangKy?.[0] || ""}-`;
            } else {
                temp = `${ngayDangKy?.[0] || ""}--`;
            }
            if (so.indexOf('/') > 0)
                so = so.substring(0, so.indexOf('/'));
            tenFilePdf = `${type}.${quyenSo?.[1] || '--'}.${quyenSo?.[0] || '--'}.${temp}.${so || ""}.pdf`;
            form.setFieldsValue({
                [DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tenfilepdf]: tenFilePdf,
            })
            setPdfFileName(tenFilePdf);
        }
        setKeyMaps(keyMaps.map((x, i) => {
            if (x.dataKeyAscii === item.dataKeyAscii) return {
                ...x,
                dataValue: value || "",
                isEdited: value !== dataKeys[item?.dataKeyAscii || ""]?.dataValue && !!(dataKeys[item?.dataKeyAscii || ""]?.dataValue || value),
            }
            if (x.dataKeyAscii === DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type]?.tenfilepdf && changeFilePdf) return {
                ...x,
                dataValue: tenFilePdf,
            }
            if ((x.dataKeyAscii || "").toLowerCase().includes((item.dataKeyAscii || "").toLowerCase()) &&
                (x.dataKeyAscii || "").toLowerCase().includes("bangchu")) {
                let txt = helpers.dateToWord(value || '');
                form.setFieldsValue({ [x.dataKeyAscii]: txt });
                return {
                    ...x,
                    dataValue: txt
                }
            }
            return x;
        }));
    }

    // Change dataValue of Data info
    const changeDataInfo = (e: any, key: string) => {
        let dataKeys = dataResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        let currentKeyMaps = [...keyMaps];
        currentKeyMaps = currentKeyMaps.map(item => {
            if (item.dataKeyAscii === key) {
                return {
                    ...item,
                    dataValue: e.target.value || "",
                    isEdited: e.target.value !== dataKeys[key].dataValue,
                }
            }
            return item;
        })
        setKeyMaps(currentKeyMaps);
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    // Convert object field to element
    const convertTypeDataToElement1 = (item: FieldConfig, index: number) => {
        if (item.data && item.data.length) {
            return <Form.Item
                name={item.dataKeyAscii}
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    <div
                        style={{
                            fontWeight: 700,
                            wordBreak: "break-word",
                            color: item.isEdited ? "#ff4d4f" : "",
                            textDecoration: item.beforeEdited ? "underline red" : "",
                        }}
                    >{index + 1}.{item.dataKey || ""}</div>
                </div >}
                rules={createRules(item)}
            >
                <Select
                    className="custom-select"
                    disabled={!editing && item.isEdit}
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeDataInit(value, index, item)}
                    value={item.dataValue}
                >
                </Select>
            </Form.Item >
        }

        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited ? "#ff4d4f" : "",
                                    textDecoration: item.beforeEdited ? "underline red" : "",
                                }}>{index + 1}.{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!editing || !item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div style={{
                                fontWeight: 700,
                                wordBreak: "break-word",
                                color: item.isEdited ? "#ff4d4f" : "",
                                textDecoration: item.beforeEdited ? "underline red" : "",
                            }}>{index + 1}.{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!editing || !item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].phuongXa:
                    return <Form.Item
                        name={item.dataKeyAscii || ""}
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div style={{
                                fontWeight: 700,
                                wordBreak: "break-word",
                                color: item.isEdited ? "#ff4d4f" : "",
                                textDecoration: item.beforeEdited ? "underline red" : "",
                            }}>{index + 1}.{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled={!editing || !item.isEdit}
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
            }
        }

        return <Form.Item
            name={item.dataKeyAscii}
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                <div style={{
                    fontWeight: 700,
                    wordBreak: "break-word",
                    color: item.isEdited ? "#ff4d4f" : "",
                    textDecoration: item.beforeEdited ? "underline red" : "",
                }}>{index + 1}.{item.dataKey || ""}</div>
            </div>}
            rules={createRules(item)}
        ><Input.TextArea
                placeholder={item.placeholder || ''}
                disabled={!editing || !item.isEdit}
                onFocus={(e) => e.currentTarget.select()}
                // onChange={(e) => changeDataInit(e.target.value, index, item)}
                // value={item.dataValue}
                onBlur={(e) => updateDataInitBlur(e.target.value, index, item)}
            /></Form.Item>;
    }

    // Format lại dữ liệu sau khi nhập liệu
    const updateDataInitBlur = (value: any, index: number, item: FieldConfig) => {
        if (item.type === "date") {
            value = helpers.customDate(value);
            changeDataInit(value, index, item);
        } else if (item.type === "time") {
            value = helpers.customTime(value, item.format);
            changeDataInit(value, index, item);
        } else if (item.format?.toLowerCase().includes("capitalcase")) {
            value = helpers.toTitleCase(value);
            changeDataInit(value, index, item);
        } else {
            changeDataInit((value || "").trim(), index, item);
        }
        form.validateFields([item.dataKeyAscii]);
    }

    const createRules = (item: any) => {
        let rules: Object[] = [];
        if (item.isDefault) {
            rules = [...rules, { required: true, message: "Trường dữ liệu bắt buộc" }];
        }
        if (item.format && item.format !== "capitalcase") {
            rules = [...rules, { pattern: new RegExp(item.format), message: "Dữ liệu không đúng định dạng" }];
        }
        return rules;
    }

    const checkChangeData = () => {
        return keyMaps.find(x => x.isEdited);
    }

    // Chuyển dữ liệu khác
    const nextDataInfo = () => {
        if (param?.id) {
            if (confirmNextextVisible || !checkChangeData()) {
                triggerData(param.id);
                if (confirmNextextVisible) {
                    setConfirmNextextVisible(!confirmNextextVisible);
                }
            } else {
                setConfirmNextextVisible(!confirmNextextVisible);
            }
        }
    }

    const displayHeaderHistory = () => {
        return <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <div>
                Dữ liệu đã chỉnh sửa
                <span onClick={e => e.stopPropagation()} style={{ marginLeft: 10 }}>
                    <Checkbox
                        checked={historyHaveEdit}
                        onChange={(e) => changeHistoryHaveEdit(e)}
                    >Chỉ xem trường bạn đã sửa</Checkbox>
                </span>
            </div>
            {/* <div><ClockCircleOutlined /> {moment(item.createdDate).format("HH:mm DD/MM/YYYY")}</div> */}
        </div>
    }

    const changeHistoryHaveEdit = (e: any) => {
        e.stopPropagation();
        // let historys = [...historyHaveEdit];
        // let indexOfItem = historyHaveEdit.indexOf(item.id);
        // if (indexOfItem !== -1) {
        // 	historys.splice(indexOfItem, 1);
        // } else {
        // 	historys.push(item.id);
        // }
        setHistoryHaveEdit(!historyHaveEdit);
    }

    const handleCopy = () => {
        const textToCopy = window.location.protocol + '//' + window.location.hostname + ':' + window.location.port + '/data/info_init_detail/' + dataResponse?.data?.dataInfoInit?.id;
        if (navigator.clipboard) {
            navigator.clipboard.writeText(textToCopy)
                .then(() => {
                    notification.success({ message: 'Đã sao chép thành công' })
                    console.log('Đã sao chép thành công:', textToCopy);
                })
                .catch((error) => {
                    notification.error({ message: 'Lỗi sao chép' })
                    console.error('Lỗi sao chép:', error);
                });
        } else {
            // Use the 'out of viewport hidden text area' trick
            const textArea = document.createElement("textarea");
            textArea.value = textToCopy;

            // Move textarea out of the viewport so it's not visible
            textArea.style.position = "absolute";
            textArea.style.left = "-999999px";

            document.body.prepend(textArea);
            textArea.select();

            try {
                document.execCommand('copy');
                notification.success({ message: 'Đã sao chép thành công' })
            } catch (error) {
                console.error(error);
            } finally {
                textArea.remove();
            }
        }
    };

    // Convert object field to element
    const convertTypeDataToElement2 = (item: FieldConfig, index: number) => {
        if (item.data && item.data.length) {
            return <Form.Item
                style={{ marginBottom: 10 }}
                label={<div style={{ display: "flex" }}>
                    <div
                        style={{
                            fontWeight: 700,
                            wordBreak: "break-word",
                            color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                            // textDecoration: item.beforeEdited ? "underline red" : "",
                        }}>{index + 1}.{item.dataKey || ""}</div>
                </div>}
                rules={createRules(item)}
            >
                <Select
                    className="custom-select"
                    disabled
                    allowClear
                    placeholder={item.placeholder || "--Lựa chọn--"}
                    optionFilterProp='label'
                    options={(item.data || []).map((x: any) => ({
                        label: `${x.id}${x.name ? (" - " + x.name) : ""}`,
                        value: x.id
                    }))}
                    showSearch
                    showArrow
                    onChange={(value) => changeDataInit(value, index, item)}
                    value={item.dataValue}
                >
                </Select>
            </Form.Item>
        }

        if (item.isDefault && fieldConfigResponse?.data?.type && (!item.data || !item.data.length)) {
            switch (item.dataKeyAscii) {
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].tinhThanh:
                    return <Form.Item
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                                    // textDecoration: item.beforeEdited ? "underline red" : "",
                                }}>{index + 1}.{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(responseProvince?.data || []).map((item) => ({
                                label: item.provinceName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].quanHuyen:
                    return <Form.Item
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                                    // textDecoration: item.beforeEdited ? "underline red" : "",
                                }}>{index + 1}.{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(districts || []).map((item) => ({
                                label: item.districtName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
                case DEFAULT_FIELD_CONFIG[fieldConfigResponse?.data?.type].phuongXa:
                    return <Form.Item
                        style={{ marginBottom: 10 }}
                        label={<div style={{ display: "flex" }}>
                            <div
                                style={{
                                    fontWeight: 700,
                                    wordBreak: "break-word",
                                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                                    // textDecoration: item.beforeEdited ? "underline red" : "",
                                }}>{index + 1}.{item.dataKey || ""}</div>
                        </div>}
                        rules={[
                            { required: true, message: "Trường dữ liệu bắt buộc" },
                        ]}
                    >
                        <Select
                            disabled
                            allowClear
                            placeholder={item.placeholder || "--Lựa chọn--"}
                            optionFilterProp='label'
                            options={(communes || []).map((item) => ({
                                label: item.communeName,
                                value: item.id
                            }))}
                            showSearch
                            showArrow
                            onChange={(value) => changeDataInit(value, index, item)}
                            value={item.dataValue}
                        >
                        </Select>
                    </Form.Item>
            }
        }

        return <Form.Item
            style={{ marginBottom: 10 }}
            label={<div style={{ display: "flex" }}>
                <div style={{
                    fontWeight: 700,
                    wordBreak: "break-word",
                    color: item.isEdited || item.beforeEdited ? "#ff4d4f" : "",
                    // textDecoration: item.beforeEdited ? "underline red" : "",
                }}>{index + 1}.{item.dataKey || ""}</div>
            </div>}
            rules={createRules(item)}
        ><Input.TextArea
                placeholder={item.placeholder || ''}
                disabled
                onFocus={(e) => e.currentTarget.select()}
                // onChange={(e) => changeDataInit(e.target.value, index, item)}
                value={item.dataValue}
                onBlur={(e) => updateDataInitBlur(e.target.value, index, item)}
            /></Form.Item>;
    }

    const [height, setHeight] = useState<any>();
    const [width, setWidth] = useState<any>();
    const [initWidth, setInitWidth] = useState<any>();

    const changeSize = (size: number) => {
        if (displayFlex) {
            setWidth(size);
            setClientY(clientY * (size / width));
        } else {
            setHeight(size);
            setClientY(clientY * (size / height));
        }
        setIsRendered(false);
    }

    const { updateDataResponse } = useContext(DataInfoContext);

    useEffect(() => {
        updateDataResponse(dataResponse?.data);
        // updateTableData(tableData);
    });

    return (
        <Spin tip="Đang tải..." spinning={fieldConfigResponse.isFetching}>
            <div className="content-box" style={{ margin: "0px 20px", padding: "20px" }}>
                <div className="body-block-create-info-init" ref={wrapperDiv}>
                    <SplitPane
                        split={displayFlex ? "vertical" : "horizontal"} size={displayFlex ? width : height}
                        minSize={"50%"}
                        onChange={(size) => changeSize(size)}
                        maxSize={"100%"}
                    >
                        <div
                            style={displayFlex ? {
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                // resize: "horizontal",
                                overflow: "auto"
                            } : {
                                // borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber,
                                padding: 10,
                                width: "100%",
                                overflow: "auto"
                            }}
                        >
                            <div className="header-block">
                                <div>
                                    <label style={{ backgroundColor: '#fefbd8', fontWeight: 'bold', fontSize: 10 }} className='search-title'>{dataResponse?.data?.dataInfoInitNew?.pathFile || ''}</label>

                                </div>
                                <div>
                                    <ZoomOut
                                        onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale === 1 ? 1 : scale - 1 })}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} />
                                    <ZoomIn
                                        onClick={() => setScale((prev: number) => { prevScale.current = prev; return scale + 1 })}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} />
                                    <Rotate
                                        onClick={() => setRotate(rotate - 90)}
                                        style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} />
                                    <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} />
                                    <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} />
                                    {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} /> */}
                                    <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginRight: 20, marginBottom: 0 }} />
                                    {/* <label
                                        style={{ wordBreak: "break-word" }}
                                        className='search-title'
                                    >({dataResponse?.data?.pathFile || ''})</label> */}
                                </div>
                            </div>
                            <div style={{
                                overflowY: "auto",
                                height: displayFlex ? "calc(100vh - 160px)" : "calc(100% - 55px)",
                            }}
                                id="block-view-pdf" >
                                {/* <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page width={width} rotate={rotate} scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document> */}
                                <TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev + 1 });
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev: number) => { prevScale.current = prev; return prev - 1 || 1 });
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}>
                                            {Array.from(new Array(Math.round(numPages / 2)), (el, index) => (
                                                <div style={{ display: "flex" }} key={`grouppage${index + 1}`}>
                                                    <Page
                                                        width={width}
                                                        // height={height}
                                                        rotate={rotate}
                                                        scale={scale}
                                                        key={`page_${index * 2 + 1}`}
                                                        pageNumber={index * 2 + 1}
                                                        canvasRef={canvas}
                                                        onRenderSuccess={onRenderSuccess}
                                                        onClick={onClickPage}
                                                    />
                                                    {
                                                        (index * 2 + 2) <= numPages ? <Page
                                                            width={width}
                                                            // height={height}
                                                            rotate={rotate}
                                                            scale={scale}
                                                            key={`page_${index * 2 + 2}`}
                                                            pageNumber={index * 2 + 2}
                                                            canvasRef={canvas1}
                                                            onRenderSuccess={onRenderSuccess}
                                                            onClick={onClickPage}
                                                        /> : <></>
                                                    }

                                                </div>
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                            </div>
                        </div>
                        {/* {displayFlex ? <div
                        id='Draggable'
                        draggable='true'
                        onDragStart={initial}
                        onDrag={resize}
                    ></div> : <></>} */}
                        <div
                            style={displayFlex ? {
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                position: "absolute"
                                // resize: "horizontal",
                                // overflow: "auto"
                            } : {
                                // borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                                order: orderNumber === 1 ? 3 : 1,
                                padding: 10,
                                width: "100%",
                                boxSizing: "border-box",
                                // position: "absolute"
                            }}
                        >
                            <Form
                                name="searchBox"
                                autoComplete="off"
                                form={form}
                                colon={false}
                                style={{ marginTop: 0 }}
                                layout="vertical"
                                labelWrap
                                labelAlign="left"
                            >
                                <label style={{ backgroundColor: '#fefbd8' }} className='search-title'>Dữ liệu hiện tại: {pdfFileName} - <span>
                                    Người cập nhật gần nhất: <UserOutlined style={{ cursor: 'pointer', width: 20, height: 20, marginBottom: 0 }} />
                                    <span style={{ margin: "0px 3px" }}>{dataResponse?.data?.dataInfoInitNew?.modifiedBy}</span>
                                    - <ClockCircleOutlined style={{ cursor: 'pointer', width: 20, height: 20, marginBottom: 0 }} />
                                    <span style={{ margin: "0px 3px" }}>{moment(dataResponse?.data?.dataInfoInitNew?.modifiedDate).format("HH:mm DD/MM/YYYY")}</span>
                                </span>
                                </label>
                                <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                        {keyMaps?.length && keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                            return (<Col span={8} key={item.dataKeyAscii}>
                                                {dataResponse?.data?.filePdf?.id || dataResponse?.data?.dataInfoInit?.typeId ? convertTypeDataToElement1(item, index) : <Form.Item
                                                    style={{ marginBottom: 10 }}
                                                    label={<div style={{ fontWeight: 700, wordBreak: "break-word" }}>
                                                        {index + 1}.{item.dataKey || ""}
                                                        {/* {!dataInfoResponse?.data?.campaignId ? (item.isEdited ?
                                                        <ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> :
                                                        <CheckCircleOutlined title="Chưa chỉnh sửa" color="success" style={{ marginLeft: 5, color: "#52c41a" }} />
                                                    ) : <></>} */}
                                                    </div>}
                                                >
                                                    <Input.TextArea onFocus={(e) => e.currentTarget.select()} onChange={(e) => changeDataInfo(e, item.dataKeyAscii)} value={item.dataValue} placeholder="Nhập dữ liệu" />
                                                </Form.Item>}
                                            </Col>)
                                        })}
                                    </Row>
                                </div>
                            </Form>
                            <div className='search-box-button' style={{ marginTop: 15 }}>
                                <Link to='/data/info_init_history' state={state}><Button htmlType='button'>
                                    Quay lại
                                </Button></Link>
                                {keyMaps?.length && (dataResponse?.data?.dataInfoInitNew?.modifiedBy === userState.username || checkPermission("data/info_init")) ? <>
                                    {editing ?
                                        <>
                                            <Button onClick={() => setEditing(false)}>Hủy bỏ</Button>
                                            <Button type='primary' onClick={onFinish}>{checkChangeData() ? "Cập nhật dữ liệu" : "Xác nhận"}</Button>
                                        </> :
                                        <Button type='primary' onClick={() => setEditing(true)}>Chỉnh sửa</Button>}
                                    {/* <Button type='primary' onClick={nextDataInfo}>Kiểm tra dữ liệu khác</Button> */}
                                </> : <></>}
                            </div>
                        </div>
                    </SplitPane>
                </div>
                <div className='detail-data-info'>
                    <Collapse defaultActiveKey={['1']} style={{ width: "100%" }}>
                        <Panel header={displayHeaderHistory()} key={1}>
                            <div>
                                <Form
                                    name="searchBox"
                                    autoComplete="off"
                                    form={form}
                                    colon={false}
                                    style={{ marginTop: 0 }}
                                    layout="vertical"
                                    labelWrap
                                    labelAlign="left"
                                >
                                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                        {/* .filter((x: any) => (x.isEdit && historyHaveEdit && x.isDisplay)) */}
                                        {keyMapOlds?.filter((x: any) => (!historyHaveEdit || x.beforeEdited) && x.isDisplay).map((keyMap: any, index: number) => {
                                            return (<Col span={8} key={dataResponse?.data?.id + keyMap.dataKeyAscii}>
                                                {dataResponse?.data?.filePdf?.id || dataResponse?.data?.dataInfoInit?.typeId ? convertTypeDataToElement2(keyMap, index) : <Form.Item
                                                    style={{ marginBottom: 10 }}
                                                    label={<div>
                                                        {keyMap.dataKey || ""}
                                                        {keyMap.isEdited ?
                                                            <ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> : <></>}
                                                    </div>}
                                                >
                                                    <Input.TextArea value={keyMap.dataValue} placeholder="Nhập dữ liệu" />
                                                </Form.Item>}
                                            </Col>)
                                        })}
                                    </Row>
                                </Form>
                            </div>
                        </Panel>
                    </Collapse>
                </div>
            </div>
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn cập nhật dữ liệu không?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={onSubmitCheckData}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={500}
                    okBtnDanger={true} />
                : <></>}
            {confirmNextextVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn đồng ý huỷ kết quả chỉnh sửa dữ liệu này để kiểm tra dữ liệu khác?"]}
                    handleCancel={() => setConfirmNextextVisible(!confirmNextextVisible)}
                    handleOk={nextDataInfo}
                    type={1}
                    visible={confirmNextextVisible}
                    widthConfig={424}
                    okBtnDanger={true} />
                : <></>}
        </Spin >
    )
}

export default DetailDataInfoInitHistory;
