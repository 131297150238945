import { FormInstance, Pagination, Row, Spin, Table } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLazyGetBoxDataQuery, useLazyGetCountBoxDataQuery } from "../redux/boxDataApi";
import { DEFAULT_FIELD_CONFIG } from './../../../assets/ts/constant';
interface DataListTable {
    key: string,
    index: number,
    fileName?: any,
    fileName158?: any,
    createBy?: string,
    contentType?: string,
    createdDate: string,
    isChecked: string,
    numberCheck: number,
    bookNumber: number,
    timeComplete?: string,
    status: string,
    provinceCode?: string,
    districtCode?: string,
    communeCode?: string
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const BoxListData = ({ form, searchParam }: Props) => {
    const [triggerFilePdf, responseFilePdf] = useLazyGetBoxDataQuery();
    const [triggerCountBoxData, responseCountBoxData] = useLazyGetCountBoxDataQuery();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        if (responseFilePdf?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseFilePdf?.data?.content?.forEach((item: any, index: number) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    districtCode: item?.filePdf[0]?.districtCode || "",
                    communeCode: item?.filePdf[0]?.communeCode || "",
                    fileName: <Link to={`/data/box-data/detail/${item.id}`}>{item?.filePdf[0]?.fileName || ""}</Link>,
                    fileName158: <Link to={`/data/box-data/detail/${item.id}`}>{customFileFileName158(item, item?.filePdf?.[0]?.fileName?.split(".")?.[0] || '') || ""}</Link>,
                    bookNumber: item?.filePdf[0]?.bookNumber || 0,
                    numberCheck: item?.filePdf[0]?.numberCheck || 0,
                    isChecked: item?.filePdf[0]?.isChecked ? "Đã nhập xong" : item.numberCheck ? "Chưa nhập xong" : "Chưa nhập",
                    createBy: item?.filePdf[0]?.createdBy || "",
                    createdDate: item?.filePdf[0]?.createdDate ? moment(new Date(item?.filePdf[0]?.createdDate)).format("HH:mm DD/MM/YYYY") : "",
                    timeComplete: item?.filePdf[0]?.timeComplete ? moment(item?.filePdf[0]?.timeComplete).format("HH:mm DD/MM/YYYY") : "",
                    status: item.status === 1 ? "Có lỗi" : "",
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseFilePdf]);

    const customFileFileName158 = (dataInfoInit: any, type: string) => {
        return dataInfoInit?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})?.[DEFAULT_FIELD_CONFIG[type]?.tenfilepdf];
    }

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "File pdf",
            dataIndex: "fileName",
            key: "fileName",
        },
        {
            title: "Ngày đăng kí",
            dataIndex: "createdDate",
            key: "createdDate",
        },
        {
            title: "Quyển số",
            dataIndex: "bookNumber",
            key: "bookNumber",
        },
        {
            title: "Số",
            dataIndex: "numberCheck",
            key: "numberCheck",
        },

        {
            title: "File pdf 158",
            dataIndex: "fileName158",
            key: "fileName158",
        },
    ];

    const getData = () => {
        triggerFilePdf({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerCountBoxData({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        })
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerFilePdf({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        });
        triggerCountBoxData({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                {/* <Col span={6}>
                </Col> */}
                <div>Tổng số: <strong>{responseCountBoxData?.data}</strong> bản ghi</div>

                <div className='search-box-button'>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseFilePdf?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseCountBoxData?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountBoxData?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>
        </div>
    )
}

export default BoxListData;