import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CommonListQuery } from "../../../redux/api/apiTypes";
import { IReportDashboardParams, IReportDashboardResponse } from "./apiTypes";

export const dataInitHomeApi = createApi({
    reducerPath: "dataInitHomeApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set("authorization", `Bearer ${token}`);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getReportDashboard: builder.query<
            any,
            { searchParam?: IReportDashboardParams } & Partial<CommonListQuery>
        >({
            query: (body) => ({
                url: `/reports/dashboard?search=${
                    body.searchParam
                        ? encodeURIComponent(JSON.stringify(body.searchParam))
                        : ""
                }`,
                method: "GET",
            }),
            transformResponse: (response: { data: IReportDashboardResponse }, meta, arg) => {
                return response.data;
            },
        }),
    }),
});

export const { useLazyGetReportDashboardQuery } = dataInitHomeApi;

export default dataInitHomeApi;
