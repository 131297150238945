import moment from "moment";
import { Button, Checkbox, Col, Collapse, Form, Input, notification, Pagination, Row, Spin, Tag } from "antd";
import { useCheckExitFilePdfMutation, useLazyGetDataInfoByIdQuery, useLazyGetHistoryQuery, useLazyGetNextDataInfoByIdQuery, useLazyGetSortIdQuery, useSaveSortMutation, useUpdateDataMutation } from "../redux/dataCheckApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { DataField } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import { CheckCircleOutlined, ClockCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import { ReactSortable } from "react-sortablejs";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PDFPageProxy } from 'react-pdf';
const { Panel } = Collapse;

const useWidth = (target: any) => {
	const [width, setWidth] = useState(300);

	useLayoutEffect(() => {
		setWidth(target.current.getBoundingClientRect().width)
	}, [target]);

	useResizeObserver(target, (entry: any) => setWidth(entry.contentRect.width));
	return width;
};

const DetailDataInfo = () => {
	const [form] = Form.useForm();
	const param = useParams();
	const { state } = useLocation();
	const navigate = useNavigate();
	const [scale, setScale] = useState(1);
	const [rotate, setRotate] = useState(0);
	const [numPages, setNumPages] = useState(0);
	const [orderNumber, setOrderNumber] = useState(1);
	const [filePdfExit, setFilePdfExit] = useState();
	const [timeFilePdfExit, setTimeFilePdfExit] = useState();
	const [displayFlex, setDisplayFlex] = useState(true);
	const [keyMaps, setKeyMaps] = useState<DataField[]>([]);
	const [updateData, { isLoading }] = useUpdateDataMutation();
	const [confirmVisible, setConfirmVisible] = useState(false);
	const [triggerDataInfo, dataInfoResponse] = useLazyGetDataInfoByIdQuery();
	const [triggerHistory, historyResponse] = useLazyGetHistoryQuery();
	const [triggerNextDataInfo, nextDataInfoResponse] = useLazyGetNextDataInfoByIdQuery();
	const [triggerCurrentSort, currentSortResponse] = useLazyGetSortIdQuery();
	const [triggerSaveSort] = useSaveSortMutation();
	const [checkExitFilePdf] = useCheckExitFilePdfMutation();
	const [confirmNextextVisible, setConfirmNextextVisible] = useState(false);
	const [page, setPage] = useState(0);
	const pageSizeRef = useRef(10);
	const wrapperDiv = useRef(null);
	const width = useWidth(wrapperDiv);

	const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    useEffect(() => {
        if (clientY===0 || !canvas.current||!isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
    }, [clientY, isRendered]);
    useEffect(()=>{
        if(prevScale.current===scale){
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY)=>prevClientY*(scale / prevScale.current));
            setIsRendered(false);
        }
    },[scale])
    const  onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if(!isDrag){
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top)*scaleY);
        }
    }

	const file = useMemo(() => {
		return {
			url: process.env.REACT_APP_API_URL + `/api/v1/tool/data-info/dowload-file/${param.id}`,
			httpHeaders: {
				Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
				"Content-Type": "application/json;charset=UTF-8",
			},
		}
	}, [param?.id]);
	const [initialPos, setInitialPos] = useState(0);
	const [initialSize, setInitialSize] = useState(0);
	const [historyHaveEdit, setHistoryHaveEdit] = useState<string[]>([]);

	const initial = (e: any) => {
		let resizable = document.getElementById('block-view-pdf');
		setInitialPos(e.clientX);
		setInitialSize(resizable?.offsetWidth || 0);
	}

	const resize = (e: any) => {
		let resizable = document.getElementById('block-view-pdf');
		if (resizable) {
			resizable.style.width = `${initialSize + e.clientX - initialPos}px`;
		}
	}

	useEffect(() => {
		if (param?.id) {
			triggerDataInfo(param.id);
			triggerHistory({
				page,
				size: pageSizeRef.current,
				searchParam: {
					dataInfoId: param.id
				},
			});

		}
	}, [param?.id]);

	useEffect(() => {
		if (dataInfoResponse?.data?.keyMaps?.length) {
			let dataKeys = dataInfoResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
			triggerCurrentSort(dataInfoResponse?.data?.dataFile?.id).unwrap()
				.then(res => {
					setKeyMaps((res?.keyMaps || dataInfoResponse?.data?.keyMaps).map((item: any, index: number) => {
						return {
							...item,
							dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
							dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
							dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
							isEdited: false,
						}
					}));
				}).catch(err => {
					setKeyMaps(dataInfoResponse?.data?.keyMaps.map((item: any, index: number) => {
						return {
							...item,
							dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
							dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
							dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
							isEdited: false,
						}
					}));
				});
		}
	}, [dataInfoResponse]);

	const onFinish = () => {
		toggleConfirmModal();
	}

	const onSubmitCheckData = () => {
		updateData({
			keyMaps,
			id: param.id,
			isCheck: true,
		}).unwrap()
			.then(res => {
				navigate("/data/list", { replace: true, state });
			}).catch(err => {
				notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
			})
	}

	const changeLayout = (newDisplayFlex: boolean, order: number) => {
		setDisplayFlex(newDisplayFlex);
		setOrderNumber(order);
	}
	const onDocumentLoadSuccess = ({ numPages }: any) => {
		setNumPages(numPages);
	}

	const changeData = (e: any, index: number) => {
		let oldKeyMaps = dataInfoResponse?.data?.keyMaps ? [...dataInfoResponse?.data?.keyMaps] : [];
		let currentKeyMaps = [...keyMaps];
		currentKeyMaps[index] = {
			...currentKeyMaps[index],
			dataValue: e.target.value || "",
			isEdited: e.target.value !== oldKeyMaps[index].dataValue,
		}
		setKeyMaps(currentKeyMaps);
	}

	const changeDataDefault = (e: any, index: number) => {
		let currentKeyMaps = [...keyMaps];
		currentKeyMaps[index] = {
			...currentKeyMaps[index],
			isDefault: e.target.checked,
		}
		setKeyMaps(currentKeyMaps);
	}

	const changeDataNotDisplay = (e: any, index: number) => {
		let currentKeyMaps = [...keyMaps];
		currentKeyMaps[index] = {
			...currentKeyMaps[index],
			notDisplay: e.target.checked,
		}
		setKeyMaps(currentKeyMaps);
	}

	const checkChangeData = () => {
		return keyMaps.findIndex(x => x.isEdited);
	}

	const nextDataInfo = () => {
		if (confirmNextextVisible || !checkChangeData()) {
			if (param?.id) {
				triggerNextDataInfo(param.id).unwrap()
					.then(res => {
						navigate(`/data/detail/${res.id}`, { replace: true, state });
					})
			}
		} else {
			setConfirmNextextVisible(!confirmNextextVisible)
		}
	}

	const toggleConfirmModal = () => {
		setConfirmVisible(!confirmVisible);
	}

	const displayHeaderHistory = (item: any, index: number) => {
		if (item.createBy) {
			return <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
				<div>
					<span>
						{item.createBy} đã <Tag color="#2db7f5">Kiểm tra dữ liệu</Tag>
					</span>
					{/* <span>trong chiến dịch <Tag color="#2db7f5">{item?.dataCampaign?.campaignName}</Tag></span> */}
					<span>
						{!index && !page ? <Tag color="#87d068">NOW</Tag> : ""}
					</span>
					<span onClick={e => e.stopPropagation()}>
						<Checkbox
							checked={historyHaveEdit.indexOf(item.id) !== -1}
							onChange={(e) => changeHistoryHaveEdit(e, item)}
						>Chỉ xem trường đã sửa</Checkbox>
					</span>
				</div>
				<div><ClockCircleOutlined /> {moment(item.createdDate).format("HH:mm DD/MM/YYYY")}</div>
			</div>
		}
		return <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
			<div>{item.createBy} đã <Tag color="#2db7f5">Thêm mới dữ liệu</Tag></div>
			<div><ClockCircleOutlined /> {moment(item.createdDate).format("HH:mm DD/MM/YYYY")}</div>
		</div>
	}

	const changeHistoryHaveEdit = (e: any, item: any) => {
		e.stopPropagation();
		let historys = [...historyHaveEdit];
		let indexOfItem = historyHaveEdit.indexOf(item.id);
		if (indexOfItem !== -1) {
			historys.splice(indexOfItem, 1);
		} else {
			historys.push(item.id);
		}
		setHistoryHaveEdit(historys);
	}

	const changePageHistory = (currentPage: number, pageSize: number) => {
		const pageSizeChange = pageSizeRef.current !== pageSize;
		let newPage = currentPage - 1;
		if (pageSizeChange) {
			newPage = 0;
		}
		setPage(newPage);
		pageSizeRef.current = pageSize;
		if (param?.id) {
			triggerHistory({
				page: newPage,
				size: pageSizeRef.current,
				searchParam: {
					dataInfoId: param.id
				},
			});
		}
	}

	const saveSort = () => {
		triggerSaveSort({ fileId: dataInfoResponse?.data?.dataFile?.id, keyMaps }).unwrap()
			.then(res => {
				notification.success({ message: "Lưu sắp xếp thành công" });
				toggleConfirmModal();
			});
	}

	const onCheckExitFilePdf = (e: any, record: any) => {
		checkExitFilePdf(record.id).unwrap()
			.then((res: any) => {
				setFilePdfExit(res.filePdfExit);
				setTimeFilePdfExit(res.timeFilePdfExit);
			}).catch((err: any) => {
				notification.error({ message: "Có lỗi xảy ra!" });
			});
	}

	return (
		<Spin tip="Đang tải..." spinning={dataInfoResponse.isFetching}>
			<div className="content-box" >
				<div className="header-block" style={{ display: "flex", justifyContent: "flex-end" }}>
					<Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
					<Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
					{/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
					<Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
				</div>
				<div className='detail-data-info' style={{ display: displayFlex ? "flex" : "block" }}>
					<div
						style={displayFlex ? {
							order: orderNumber,
							width: displayFlex ? "50%" : "100%",
							resize: "horizontal",
							overflow: "auto"
						} : {
							borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
							order: orderNumber,
							width: displayFlex ? "50%" : "100%",
						}}
						id="block-view-pdf"
						ref={wrapperDiv}
					>
						<div className="header-block">
							<label className='search-title'>File PDF: {(dataInfoResponse?.data?.filePdfPath || "").split("/")[(dataInfoResponse?.data?.filePdfPath || "").split("/").length - 1]}</label>
							<ZoomOut
								onClick={() => setScale((prev:number)=>{ prevScale.current = prev; return scale === 1 ? 1 : scale - 1})}
								style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
							<ZoomIn
								onClick={() => setScale((prev:number)=>{ prevScale.current = prev; return scale + 1})}
								style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
							<Rotate
								onClick={() => setRotate(rotate - 90)}
								style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
							{dataInfoResponse?.data ? <div>
								{dataInfoResponse?.data?.filePdfExit || filePdfExit ?
									dataInfoResponse?.data?.filePdfExit === 1 || filePdfExit === 1 ? `Tồn tại (${dataInfoResponse?.data?.timeFilePdfExit || moment(timeFilePdfExit).format("HH:mm DD/MM/YYYY")})` :
										`Không tồn tại (${dataInfoResponse?.data?.timeFilePdfExit || moment(timeFilePdfExit).format("HH:mm DD/MM/YYYY")})`
									: "Chưa kiểm tra tồn tại"}
								<span
									onClick={(e) => onCheckExitFilePdf(e, dataInfoResponse?.data)}
									style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }}
								>Kiểm tra</span>
							</div> : <></>}
						</div>
						<div style={{ overflowY: "auto", height: "calc(100vh - 160px)" }} >
							{/* <Document
								// file={{
								//     url: process.env.REACT_APP_API_URL + `/api/v1/tool/data-info/dowload-file/${param.id}`,
								//     httpHeaders: {
								//         Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
								//         "Content-Type": "application/json;charset=UTF-8",
								//     },
								// }}
								file={file}
								// file={process.env.REACT_APP_API_URL + `/public/v1/common/dowload-file/${param.id}`}
								onLoadSuccess={onDocumentLoadSuccess}>
								{Array.from(new Array(numPages), (el, index) => (
									<Page width={width} rotate={rotate} scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
								))}
							</Document> */}
							<TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev:number) => { prevScale.current = prev; return prev + 1});
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev:number) => { prevScale.current = prev; return prev - 1 || 1});
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}>
                                            {Array.from(new Array(numPages), (el, index) => (
                                                <Page
                                                    width={width}
                                                    rotate={rotate}
                                                    scale={scale}
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    canvasRef={canvas}
                                                    onRenderSuccess={onRenderSuccess}
                                                    onClick={onClickPage}
                                                />
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
						</div>
						{/* <div>
                            <p>
                                Trang {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                            </p>
                            <button style={{ marginLeft: 0 }} type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                Quay lại
                            </button>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                Tiếp
                            </button>
                        </div> */}
					</div>
					{displayFlex ? <div
						id='Draggable'
						draggable='true'
						onDragStart={initial}
						onDrag={resize}
					></div> : <></>}
					<div
						style={displayFlex ? {
							order: orderNumber === 1 ? 3 : 1,
							width: displayFlex ? `calc(100% - ${width}px)` : "auto",
							resize: "horizontal",
							overflow: "auto"
						} : {
							borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
							order: orderNumber === 1 ? 3 : 1,
							width: displayFlex ? `calc(100% - ${width}px)` : "auto",
						}}
					>
						<Form
							name="searchBox"
							onFinish={onFinish}
							autoComplete="off"
							form={form}
							colon={false}
							style={{ marginTop: 0 }}
							layout="vertical"
							labelWrap
							labelAlign="left"
						>
							<label className='search-title'>Dữ liệu excel: {dataInfoResponse?.data?.dataFile?.fileAttachDocument?.fileName}</label>
							<div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
								<Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
									<ReactSortable
										list={keyMaps}
										setList={setKeyMaps}
										style={{ width: "100%" }}
									>
										{keyMaps.length && keyMaps.map((item: any, index) => {
											return (<Col span={24} key={item.dataKeyAscii}>
												<Form.Item
													style={{ marginBottom: 10 }}
													label={<div >
														<div style={{ display: "flex" }}>
															<Checkbox checked={!!item.isDefault} onChange={(e) => changeDataDefault(e, index)}>Hiển thị ở danh sách</Checkbox>
															<Checkbox checked={!!item.notDisplay} onChange={(e) => changeDataNotDisplay(e, index)}>Không hiển thị kiểm tra</Checkbox>
														</div>
														<div style={{ fontWeight: 700 }}>{item.dataKey || ""}</div>
													</div>}
												>
													<Input.TextArea disabled onFocus={(e) => e.currentTarget.select()} onChange={(e) => changeData(e, index)} value={item.dataValue} />
												</Form.Item>
											</Col>)
										})}
									</ReactSortable>
								</Row>
							</div>
						</Form>
						<div className='search-box-button' style={{ marginTop: 15 }}>
							<Link to='/data/list' state={state}><Button htmlType='button'>
								Quay lại
							</Button></Link>
							<Button type='primary' onClick={toggleConfirmModal}>Lưu cấu hình</Button>
							{/* <Button type='primary' htmlType='submit'>
                                    {checkChangeData() ? "Cập nhật dữ liệu" : "Xác nhận"}
                                </Button>
                                <Button onClick={nextDataInfo}>Kiểm tra dữ liệu khác</Button> */}
						</div>
					</div>
				</div>
				<div>
					<div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
						<label style={{ lineHeight: "40px", marginBottom: 2 }} className='search-title'>Lịch sử chỉnh sửa</label>
						<Pagination
							style={{ marginTop: 4 }}
							total={historyResponse?.data?.totalElements || 0}
							defaultPageSize={pageSizeRef.current}
							defaultCurrent={1}
							locale={{ items_per_page: ' dòng' }}
							pageSizeOptions={[10, 20, 50, 100]}
							onChange={changePageHistory}
							showSizeChanger
							current={page + 1}
						/>
					</div>
					<div
					// style={{
					//     overflow: "hidden auto",
					//     height: "24vh"
					// }}
					>
						<Collapse defaultActiveKey={['1']}>
							{historyResponse.data?.content.length ? historyResponse.data?.content.map((item, index) => {
								return <Panel header={displayHeaderHistory(item, index)} key={item.id}>
									<div>
										<Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
											{item?.dataInfo?.keyMaps?.filter((x: any) => (x.isEdited && historyHaveEdit.indexOf(item.id) !== -1) || historyHaveEdit.indexOf(item.id) === -1)
												.map((keyMap: any) => {
													return (<Col span={24} key={keyMap.dataKeyAscii}>
														<Form.Item
															style={{ marginBottom: 10 }}
															label={<div>
																{keyMap.dataKey || ""}
																{!dataInfoResponse?.data?.campaignId ? (keyMap.isEdited ?
																	<ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> :
																	<CheckCircleOutlined title="Chưa chỉnh sửa" color="success" style={{ marginLeft: 5, color: "#52c41a" }} />
																) : <></>}
															</div>}
														>
															<Input.TextArea disabled value={keyMap.dataValue} />
														</Form.Item>
													</Col>)
												})}
										</Row>
									</div>
								</Panel>
							}) : <></>}
						</Collapse>
					</div>
				</div>
			</div>
			{confirmVisible ?
				<ConfirmModal
					cancelText="Huỷ bỏ"
					okText="Đồng ý"
					classBtnOk="ant-button-dangerous"
					contents={["Bạn có chắc chắn muốn cập nhật dữ liệu không?"]}
					handleCancel={toggleConfirmModal}
					handleOk={onSubmitCheckData}
					type={1}
					visible={confirmVisible}
					widthConfig={424}
					okBtnDanger={true} />
				: <></>}
			{confirmVisible ?
				<ConfirmModal
					cancelText="Huỷ bỏ"
					okText="Đồng ý"
					classBtnOk="ant-button-dangerous"
					contents={["Bạn có chắc chắn muốn lưu thứ tự sắp xếp không?"]}
					handleCancel={toggleConfirmModal}
					handleOk={saveSort}
					type={1}
					visible={confirmVisible}
					widthConfig={424}
					okBtnDanger={true} />
				: <></>}
			{confirmNextextVisible ?
				<ConfirmModal
					cancelText="Huỷ bỏ"
					okText="Đồng ý"
					classBtnOk="ant-button-dangerous"
					contents={["Bạn đồng ý huỷ kết quả chỉnh sửa dữ liệu này để kiểm tra dữ liệu khác?"]}
					handleCancel={() => setConfirmNextextVisible(!confirmNextextVisible)}
					handleOk={nextDataInfo}
					type={1}
					visible={confirmNextextVisible}
					widthConfig={424}
					okBtnDanger={true} />
				: <></>
			}
		</Spin >
	)
}

export default DetailDataInfo;
