import { createContext, useState } from "react";

export const DataInfoContext = createContext({} as any);

const DataInfoProvider = ({ children }: any) => {
  const [dataResponse, setDataResponse] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [isShowTableData, setIsShowTableData] = useState(false);
  const [isShowTitle, setIsShowTitle] = useState(null);

  const updateDataResponse = (response: any) => {
    setDataResponse(response);
  };

  const updateTableData = (data: any) => {
    setTableData(data);
  };

  const toggleShowTableData = () => {
    setIsShowTableData(!isShowTableData);
  };

  const toggleShowTitle = (title: any) => {
    setIsShowTitle(title);
  };

  return (
    <DataInfoContext.Provider value={{ dataResponse, updateDataResponse, tableData, updateTableData, isShowTableData,
      toggleShowTableData, isShowTitle, toggleShowTitle }}>
      {children}
    </DataInfoContext.Provider>
  );
};

export default DataInfoProvider;