import React, { useEffect } from 'react'
import { Modal, Button, Form, Input } from 'antd'
import { ReactComponent as Warning } from '../../../assets/logos/warning.svg';

type ErrorProps = {
    type: number,
    widthConfig: number,
    visible: boolean;
    cancelText: string,
    okText?: string,
    note?: string,
    okSuccess?: string,
    classBtnOk: string,
    contents: string[],
    handleOk: (message?: string) => void,
    handleSuccess?: () => void,
    handleCancel: () => void,
    okBtnDanger?: boolean,
    isMessage?: boolean,
    isLoading?: any
}


const ConfirmModal = ({
    type,
    widthConfig,
    visible,
    cancelText,
    okText,
    note,
    okSuccess,
    classBtnOk,
    contents,
    handleOk,
    handleSuccess,
    handleCancel,
    okBtnDanger,
    isMessage,
    isLoading
}: ErrorProps) => {
    const [form] = Form.useForm();

    const submit = () => {
        if (isMessage) {
            form.validateFields().then(res => {
                console.log(form.getFieldsValue());
                handleOk(form?.getFieldsValue()?.message);
            })
        } else {
            handleOk();
        }
    }
    return (
        <>
            <Modal
                width={widthConfig || 424}
                title={null}
                visible={visible}
                onCancel={handleCancel}
                centered
                footer={[
                    <React.Fragment key="footer">
                        <Button htmlType='button'
                            className='white-red-border-button'
                            onClick={() => handleCancel()}
                        >
                            {cancelText}
                        </Button>
                        {okText ? <Button
                            disabled={isLoading}
                            danger={okBtnDanger}
                            htmlType='button'
                            className={classBtnOk}
                            style={{ marginLeft: 20 }}
                            onClick={() => submit()}
                            type="primary"
                        >
                            {okText}
                        </Button> : <></>}
                        {okSuccess && handleSuccess ? <Button
                            style={{ marginLeft: 20, backgroundColor: "#49cc90", border: "#49cc90" }}
                            htmlType='button'
                            className={classBtnOk}
                            onClick={() => handleSuccess()}
                            type="primary"
                        >
                            {okSuccess}
                        </Button> : <></>}
                    </React.Fragment>
                ]}
                className='error-1'
            >
                {type === 1 ? <Warning /> : <></>}
                <label>Xác nhận</label>
                {contents && contents.length ? contents.map((item, index) => <div style={{ textAlign: "center" }} key={index}>{item}</div>) : <></>}
                {note ? <div style={{ textAlign: "center", color: "#ff4d4f" }}>{note}</div> : <></>}
                {isMessage ? <div style={{ textAlign: "center", color: "#ff4d4f", width: "100%", marginTop: "10px" }}>
                    <Form
                        name="searchBox"
                        autoComplete="off"
                        form={form}
                        labelWrap
                        labelCol={{ span: 3 }}
                        wrapperCol={{ span: 21 }}
                        labelAlign="left"
                        requiredMark={false}
                        colon={false}
                    >

                        <Form.Item
                            label="Lý do"
                            name="message"
                            rules={[
                                { required: true, whitespace: true, message: "Trường dữ liệu bắt buộc." }
                            ]}>
                            <Input.TextArea placeholder="Nhập lý do" style={{ minWidth: '100%' }} />
                        </Form.Item>
                    </Form>
                </div> : <></>}
            </Modal> </>
    )
}

export default ConfirmModal