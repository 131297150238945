import { Col, Row, Table, Pagination, FormInstance, Button, notification, Modal } from "antd";
import { ColumnsType } from "antd/es/table";
import { format } from "date-fns";
import { useEffect, useState, useRef } from "react";
import { exportFileUsingPOST } from "../../../common/assets/CommonFunctions";
import { useExportDataMutation, useLazyGetTienDoKiemTraReportQuery, useLazyGetTienDoKiemTraTotalQuery } from "../redux/tienDoKiemTraReportApi";
import { useLazyGetSyncReportQuery } from "../../../../redux/api/commonApi";
import ConfirmModal from "../../../common/components/ConfirmModal";
import { DownOutlined, DownloadOutlined } from "@ant-design/icons";

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetTienDoKiemTraReportQuery();
    const [triggerReportTotal, responseReportTotal] = useLazyGetTienDoKiemTraTotalQuery();
    const [triggerSyncReport, responseSyncReport] = useLazyGetSyncReportQuery();
    const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<any[]>();
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(() => {
        console.log(searchParam);
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.content) {
            let newTableData: any[] = [];
            responseReport.data.content.forEach((item: any, index: any) => {
                newTableData.push({
                    key: index + 1,
                    ...item
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    function roundToTwoDecimal(value: any) {
        // Chuyển đổi giá trị thành số thập phân
        const floatValue = parseFloat(value);

        // Kiểm tra xem giá trị có phần thập phân không
        if (!isNaN(floatValue) && floatValue % 1 !== 0) {
            return `${floatValue.toFixed(2)}%`; // Làm tròn đến 2 chữ số thập phân
        } else {
            return `${value}%`; // Giữ nguyên giá trị ban đầu
        }
    }

    const columns: ColumnsType<any> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Tên chiến dịch",
            dataIndex: "campaign_name",
            key: "campaign_name",
        },
        {
            title: "Mô tả chiến dịch",
            dataIndex: "description",
            key: "description",
        },
        // {
        //     title: "Phường xã",
        //     dataIndex: "commune_name",
        //     key: "commune_name",
        // },
        {
            title: "Ngày tạo chiến dịch",
            dataIndex: "create_date_str",
            key: "create_date_str",
        },
        {
            title: "Tài khoản tạo chiến dịch",
            dataIndex: "create_by",
            key: "create_by",
        },
        // {
        //     title: "Loại hộ tịch",
        //     dataIndex: "type_id",
        //     key: "type_id",
        // },
        {
            title: "Số lượng THHT trong chiến dịch",
            dataIndex: "total_pdf",
            key: "total_pdf",
            align: "center",
        },
        {
            title: "Trạng thái chiến dịch",
            dataIndex: "status_str",
            key: "status_str",
        },
        {
            title: "Ngày kiểm tra",
            dataIndex: "max_modified_date_str",
            key: "max_modified_date_str",
        },
        {
            title: "Số lượng THHT đã kiểm tra",
            dataIndex: "total_check_true",
            key: "total_check_true",
            align: "center",
        },
        {
            title: "Tỉ lệ hoàn thành kiểm tra",
            dataIndex: "percent_complete",
            key: "percent_complete",
            render: (value) => {
                return roundToTwoDecimal(value);
            },
            align: "center",
        },
    ];

    const getData = () => {
        console.log(searchParam);
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportTotal({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportTotal({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    };

    const onSyncClick = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        // Thực hiện đồng bộ ở đây
        notification.success({ message: "Đồng bộ bắt đầu, Vui lòng chờ!" });
        setLoadings(true);
        triggerSyncReport({ rpName: "tien-do-kiem-tra" })
            .unwrap()
            .then((res) => {
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
                setTimeout(() => {
                    notification.success({ message: "Đồng bộ thành công!" });
                }, 500);
                getData();
                setLoadings(false);
            })
            .catch((err) => {
                setLoadings(false);
            });
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false); // Ẩn modal nếu người dùng hủy bỏ
    };

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                {/* <Col span={6}></Col> */}
                <div>Tổng số: <strong>{responseReportTotal?.data}</strong> bản ghi</div>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        // type="primary"
                        icon={<DownloadOutlined/>}
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                    <Button htmlType="button" type="primary" onClick={onSyncClick}>Đồng bộ</Button>
                </div>
            </Row>
            <ConfirmModal
                cancelText="Huỷ bỏ"
                okText="Xác nhận"
                classBtnOk="ant-button-dangerous"
                contents={[
                    "Đồng bộ sẽ mất thời gian khoảng 5 phút.",
                    "Bạn có chắc chắn muốn đồng bộ không?"
                ]}
                handleCancel={handleCancel}
                handleOk={handleOk}
                type={1}
                visible={isModalVisible}
                widthConfig={500}
                okBtnDanger={true}
            />
            <Table<any>
                loading={loadings || responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseReportTotal?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
        </div>
    )
}

export default ReportList;
