import { Button, Dropdown, Form, Menu, Modal, notification, Select, Table } from "antd"
import React, { useEffect, useState } from "react"
import { ColumnsType } from "antd/es/table";
import { TYPE_DATA } from "../../../assets/ts/constant"
import CreateFieldItem from "./CreateFieldItem";
import { ReactComponent as Edit } from '../../../assets/logos/pen-edit.svg';
import { ReactComponent as Delete } from '../../../assets/logos/trashcan.svg';
import {
    useEditFieldConfigMutation,
    useLazyGetFieldConfigByIdQuery,
    useCreateFieldConfigMutation
} from "../redux/fieldConfigApi";
import { MenuOutlined, SettingOutlined } from "@ant-design/icons";
import SortTable from "./SortTable";
import { DataListTable } from "../redux/apiTypes";
import { SortableHandle } from "react-sortable-hoc";

type Props = {
    visible: boolean,
    toggleModal: () => void,
    createFieldConfigSuccess: () => void,
    currentFieldConfig: any
}

const CreateFieldConfig = ({ visible, toggleModal, currentFieldConfig, createFieldConfigSuccess }: Props) => {
    const [form] = Form.useForm();
    const [tableData, setTableData] = useState<DataListTable[]>([])
    const [triggerEditFieldConfig] = useEditFieldConfigMutation();
    const [triggerFieldConfig, responseFieldConfig] = useLazyGetFieldConfigByIdQuery();
    const [triggerCreateFieldConfig, { isLoading }] = useCreateFieldConfigMutation();
    const [createItemVisible, setCreateItemVisible] = useState(false);
    const [currentItem, setCurrentItem] = useState<DataListTable>();
    const DragHandle = SortableHandle(() => <MenuOutlined style={{ cursor: 'grab', color: '#999' }} />);

    const columns: ColumnsType<DataListTable> = [
        {
            title: <MenuOutlined/>,
            dataIndex: 'sort',
            width: 50,
            className: 'drag-visible',
            render: () => <DragHandle />,
        },
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => index + 1,
            width: 70
        },
        {
            title: "Xử lý",
            dataIndex: "action",
            key: "action",
            align: "center",
            width: 80,
            render: ((text, record, index) => (
                <Dropdown overlay={getContent(text, record, index)}>
                    <SettingOutlined />
                </Dropdown>
            )),
        },
        {
            title: "Tên trường",
            dataIndex: "dataKey",
            key: "dataKey",
            ellipsis: true,
            width: 150,
        },
        {
            title: "Mã trường",
            dataIndex: "dataKeyAscii",
            key: "dataKeyAscii",
            ellipsis: true,
            width: 150,
        },
        {
            title: "Gợi ý",
            dataIndex: "placeholder",
            key: "placeholder",
            ellipsis: true,
            width: 150,
        },
        {
            title: "Loại dữ liệu",
            dataIndex: "type",
            key: "type",
            width: 150,
        },
        {
            title: "Bắt buộc",
            dataIndex: "isDefault",
            key: "isDefault",
            width: 100,
            render: (value, record, index) => record.isDefault ? "Có" : "Không",
        },
        {
            title: "Hiển thị nhập liệu",
            dataIndex: "isDisplay",
            key: "isDisplay",
            width: 150,
            render: (value, record, index) => record.isDisplay ? "Có" : "Không",
        },
        {
            title: "Hiển thị chỉnh sửa",
            dataIndex: "isEdit",
            key: "isEdit",
            width: 150,
            render: (value, record, index) => record.isEdit ? "Có" : "Không",
        },
        {
            title: "Dữ liệu mặc định",
            dataIndex: "data",
            key: "data",
            width: 150,
            ellipsis: true,
            render: (value, record, index) => record?.data?.length ? record?.data?.map(item => item.name).join(", ") : "Khôg",
        },
        {
            title: "Định dạng",
            dataIndex: "format",
            key: "format",
            width: 150,
            ellipsis: true
        },
        {
            title: "Độ dài tối thiểu",
            dataIndex: "minLength",
            key: "minLength",
            width: 140,
        },
        {
            title: "Độ dài tối đa",
            dataIndex: "maxLength",
            key: "maxLength",
            width: 130,
        },

    ];

    useEffect(() => {
        if (currentFieldConfig) {
            triggerFieldConfig(currentFieldConfig.key).unwrap().then(res => {
                form.setFieldsValue({
                    type: res.type,
                });
                setTableData((res?.fieldKeyMap || []).map((item, index) => {
                    return {
                        key: index,
                        index: index,
                        dataKey: item.dataKey,
                        dataKeyAscii: item.dataKeyAscii,
                        placeholder: item.placeholder,
                        isDefault: item.isDefault,
                        order: item.order,
                        format: item.format,
                        type: item.type,
                        minLength: item.minLength,
                        maxLength: item.maxLength,
                        isDisplay: item.isDisplay,
                        isEdit: item.isEdit,
                        data: item.data,
                    }
                }))
            }).catch((err: any) => {
                notification.error({ message: "Có lỗi xảy ra!" });
            });
        }
    }, [triggerFieldConfig, currentFieldConfig]);

    const getContent = (text: string, record: DataListTable, index: number) => {
        return (
            <Menu>
                <Menu.Item onClick={(e) => openDetailModal(e, record)} key="edit">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Edit style={{ width: 24, height: 24, marginRight: 10 }} />Xem chi tiết
                    </div>
                </Menu.Item>
                <Menu.Item onClick={(e) => onDeleteItem(e, record)} key="delete">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Delete style={{ width: 24, height: 24, marginRight: 10 }} />Xóa
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    const openDetailModal = (e: any, record: DataListTable) => {
        setCurrentItem({ ...record });
        toggleItemModal();
    }

    const onDeleteItem = (e: any, record: DataListTable) => {
        setTableData(tableData.filter((item, i) => i !== record.order).map((x, y) => {
            return {
                ...x,
                order: y,
                index: y
            }
        }));
    }

    const toggleItemModal = () => {
        setCreateItemVisible(!createItemVisible);
    }

    const addNewItem = () => {
        form.validateFields().then(res => {
            toggleItemModal();
        });
    }

    const createFieldItemSuccess = (item: any) => {
        if (currentItem) {
            setTableData(tableData.map(x => {
                if (x.key === item.key) {
                    return item;
                }
                return x;
            }))
        } else {
            setTableData([...tableData, { ...item, order: tableData.length, index: tableData.length }]);
        }
    }

    const onFinish = () => {
        form.validateFields().then(res => {
            let values = form.getFieldsValue();
            if (tableData?.length) {
                if (!currentFieldConfig) {
                    triggerCreateFieldConfig({ ...values, fieldKeyMap: tableData }).unwrap().then(res => {
                        notification.success({ message: "Tạo cấu hình thành công!" });
                        createFieldConfigSuccess();
                    }).catch(err => {
                        notification.error({ message: err.data?.message || "Có lỗi xảy ra!" });
                    })
                } else {
                    triggerEditFieldConfig({ ...values, fieldKeyMap: tableData, id: currentFieldConfig.key }).unwrap().then(res => {
                        notification.success({ message: "Chỉnh sửa cấu hình thành công!" });
                        createFieldConfigSuccess();
                    }).catch(err => {
                        notification.error({ message: err.data?.message || "Có lỗi xảy ra!" });
                    })
                }
            } else {
                notification.error({ message: "Bạn chưa cấu hình trường dữ liệu!" });
            }
        })

    }

    return (
        <Modal
            visible={visible}
            title={currentFieldConfig ? "Chỉnh sửa cấu hình" : "Thêm mới cấu hình"}
            onCancel={() => toggleModal()}
            width={950}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={toggleModal}>
                        Đóng
                    </Button>
                    <Button loading={isLoading} htmlType='button' type='primary' onClick={onFinish}>
                        {currentFieldConfig ? "Lưu" : "Thêm cấu hình"}
                    </Button>
                </React.Fragment>
            ]}>
            <Form
                name="searchBox"
                autoComplete="off"
                form={form}
                labelWrap
                labelCol={{ span: 5 }}
                wrapperCol={{ span: 19 }}
                labelAlign="left"
                requiredMark={false}
                colon={false}
            >
                <Form.Item
                    label="Loại dữ liệu"
                    name="type"
                    rules={[
                        { required: true, message: "Trường dữ liệu bắt buộc." }
                    ]}>
                    <Select
                        allowClear
                        placeholder="Chọn loại dữ liệu"
                        optionFilterProp='label'
                        options={Object.keys(TYPE_DATA).map(item => ({
                            label: `${TYPE_DATA[item]} (${item})`,
                            value: item
                        }))}
                        showSearch
                        showArrow
                    >
                    </Select>
                </Form.Item>
            </Form>
            <div>
                <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 10 }}>
                    <div>
                        Danh sách trường thông tin
                    </div>
                    <div>
                        <Button onClick={addNewItem} htmlType='button' type='primary'>
                            Thêm trường dữ liệu
                        </Button>
                    </div>
                </div>
                {/* <Table<DataListTable>
                    dataSource={tableData}
                    columns={columns}
                    tableLayout="auto"
                    // pagination={false}
                    scroll={{ x: "max-content" }}
                /> */}

                <SortTable dataSource={tableData} columns={columns} setDataSource={setTableData} />

                {createItemVisible ? <CreateFieldItem
                    currentItem={currentItem}
                    visible={createItemVisible}
                    toggleModal={toggleItemModal}
                    createFieldItemSuccess={createFieldItemSuccess}
                /> : <></>}
            </div>
        </Modal>
    )
}

export default CreateFieldConfig