import { useEffect, useState } from "react";
import { differenceInDays } from "date-fns";
import { DatePicker } from "../../../../custom-components";
import {
    CURRENT_MONTH,
    CURRENT_YEAR,
    ESearchDateType,
    MAX_SEARCH_DAY,
    TODAY,
} from "../../utils/constants";
import { Button, Row } from "antd";

const { RangePicker } = DatePicker;

type RangeValue = [Date | null, Date | null] | null;

const FORMAT_DATE = 'DD/MM/YYYY';

export interface ISearchDate {
    ranges: RangeValue;
    type: ESearchDateType;
}

interface Props {
    handleSearch: (val: ISearchDate) => void;
}

const rangePresets: Record<string, [Date, Date]> = {
    this_month: [new Date(CURRENT_YEAR, CURRENT_MONTH, 1), TODAY],
    last_month: [
        new Date(CURRENT_YEAR, CURRENT_MONTH - 1, 1),
        new Date(CURRENT_YEAR, CURRENT_MONTH, 0),
    ],
    this_year: [new Date(CURRENT_YEAR, 0, 1), TODAY],
    last_year: [
        new Date(CURRENT_YEAR - 1, 0, 1),
        new Date(CURRENT_YEAR - 1, 11, 31),
    ],
};

export const CustomSearchDate = ({ handleSearch }: Props) => {
    const [searchValuesRangeDay, setSearchValuesRangeDay] =
        useState<RangeValue>([new Date(CURRENT_YEAR, 0, 1),  new Date()]);
    const [isSearchWithDate, setIsSearchWithDate] = useState<boolean>(true);
    const [isPopupOpen, setIsPopupOpen] = useState(false);

    const disabledDate = (current: Date) => {
        if (!searchValuesRangeDay) {
            return false;
        }

        const tooLate =
            searchValuesRangeDay &&
            differenceInDays(current, searchValuesRangeDay[0] as Date) >=
                MAX_SEARCH_DAY;
        const tooEarly =
            searchValuesRangeDay &&
            differenceInDays(searchValuesRangeDay[1] as Date, current) >=
                MAX_SEARCH_DAY;

        return !!tooEarly || !!tooLate;
    };

    const onChangeCalenderDatePicker = (value: RangeValue) => {
        setSearchValuesRangeDay(value);
        const [fromDate, toDate] = value || [];
        if (fromDate && toDate) {
            handleSearch({
                ranges: value,
                type:
                    differenceInDays(toDate, fromDate) <= 31
                        ? ESearchDateType.DAY
                        : ESearchDateType.MONTH,
            });
        } else {
            handleSearch({
                ranges: null,
                type:ESearchDateType.MONTH,
            });
        }
    };

    const onChangerDatePicker = (value: RangeValue) => {
        setSearchValuesRangeDay(value);
    };

    const onChangeSelectedOtherYear = (value: Date | null, year: string) => {
        if (year) {
            const dstartOfYear = new Date(Number(year), 0, 1);
            const dEndOfYear = new Date(Number(year), 11, 31);
            const rangesDateOfYear: RangeValue = [dstartOfYear, dEndOfYear];

            setSearchValuesRangeDay(rangesDateOfYear);

            handleSearch({
                ranges: rangesDateOfYear,
                type: ESearchDateType.MONTH,
            });
        } else {
            handleSearch({
                ranges: null,
                type:ESearchDateType.MONTH,
            });
        }
    };

    const handleClickDateOpition = (value: RangeValue) => {
        setSearchValuesRangeDay(value);
        const [fromDate, toDate] = value || [];
        setIsPopupOpen(false);
        if (fromDate && toDate) {
            handleSearch({
                ranges: value,
                type:
                    differenceInDays(toDate, fromDate) <= 31
                        ? ESearchDateType.DAY
                        : ESearchDateType.MONTH,
            });
        }
    };

    const handleClickOtherYear = (val: boolean) => {
        setIsSearchWithDate(val);
        setSearchValuesRangeDay(null);
        handleSearch({
            ranges: null,
            type:ESearchDateType.MONTH,
        });
    };

    const handleOpenChange = (status: boolean) => {
        setIsPopupOpen(status);
        if (!searchValuesRangeDay && !isPopupOpen) {
            setIsPopupOpen(true);
        }
    };

    useEffect(() => {
        const [fromDate, toDate] = searchValuesRangeDay || [];
        if (isPopupOpen && searchValuesRangeDay && fromDate && toDate) {
            setIsPopupOpen(false);
        }
    }, [searchValuesRangeDay]);

    return (
        <>
            {isSearchWithDate ? (
                <RangePicker
                    style={{ width: 250, height: 30 }}
                    format={FORMAT_DATE}
                    disabledDate={disabledDate}
                    value={searchValuesRangeDay}
                    onCalendarChange={onChangeCalenderDatePicker}
                    onChange={onChangerDatePicker}
                    placeholder={["Từ", "Đến"]}
                    onBlur={() => {}}
                    onOpenChange={handleOpenChange}
                    open={isPopupOpen}
                    renderExtraFooter={() => (
                        <Row className="picker-footer">
                            <Button
                                onClick={() => {
                                    handleClickDateOpition(
                                        rangePresets["this_month"]
                                    );
                                }}
                            >
                                Tháng này
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClickDateOpition(
                                        rangePresets["last_month"]
                                    );
                                }}
                            >
                                Tháng trước
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClickDateOpition(
                                        rangePresets["this_year"]
                                    );
                                }}
                            >
                                Năm nay
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClickDateOpition(
                                        rangePresets["last_year"]
                                    );
                                }}
                            >
                                Năm trước
                            </Button>
                            <Button
                                onClick={() => {
                                    handleClickOtherYear(false);
                                }}
                            >
                                Chọn năm khác
                            </Button>
                        </Row>
                    )}
                />
            ) : (
                <DatePicker
                    style={{ width: 130, height: 30 }}
                    picker="year"
                    placeholder="Chọn  năm"
                    onChange={onChangeSelectedOtherYear}
                    open={isPopupOpen}
                    onOpenChange={handleOpenChange}
                    renderExtraFooter={() => (
                        <Row className="picker-footer">
                            <Button
                                onClick={() => {
                                    handleClickOtherYear(true);
                                }}
                            >
                                Chọn theo ngày
                            </Button>
                        </Row>
                    )}
                />
            )}
        </>
    );
};
