import { Button, Col, Dropdown, FormInstance, Menu, notification, Pagination, Row, Spin, Table, Tag } from 'antd'
import { ColumnsType } from 'antd/es/table';
import moment from 'moment';
import { useEffect, useRef, useState } from 'react';
import { DataRoleResponse } from '../redux/apiTypes';
import { useDeleteRoleMutation, useEditRoleMutation, useLazyGetDetailRoleQuery, useLazyGetRoleQuery } from '../redux/permissionsApi';

import { ReactComponent as Manipulation } from "../../../assets/logos/action.svg";
import { ReactComponent as RemoveDetail } from '../../../assets/logos/delete.svg';
import { ReactComponent as AddIcon } from "../../../assets/logos/add_icon.svg";
import { ReactComponent as Lock } from '../../../assets/logos/lock-dots.svg';
import { ReactComponent as Unlock } from '../../../assets/logos/unlock.svg';

import ConfirmModalCustom from '../../common/components/ConfirmModalCustom';
import AddRoleModalv1 from './AddRoleModalv1';
import { DataNode } from 'antd/lib/tree';
import { PERMISSION_KEYS } from '../ts/Contants';
import { checkPermission } from '../../common/assets/CommonFunctions';

export interface DataListTable {
    key: string;
    status: number;
    roleName: string;
    authoritieLst: DataNode[] | [];
    modifiedBy: string;
    modifiedDate: string;
    description: string;
    used: boolean,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const RoleList = ({ form, searchParam }: Props) => {
    const [tableData, setTableData] = useState<DataListTable[]>([]);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [currentRole, setCurrentRole] = useState<DataListTable>();
    const [addRole, setAddRole] = useState(false);
    const [deleteRoleVisible, setDeleteRoleVisible] = useState(false);
    const [activateRoleVisible, setActivateRoleVisible] = useState<boolean>(false);


    const [roleTrigger, roleResponse] = useLazyGetRoleQuery();
    const [deleteRole] = useDeleteRoleMutation();
    const [editTrigger] = useEditRoleMutation();
    const [detailRoleTrigger, detailRoleResponse] = useLazyGetDetailRoleQuery();


    const canDelete = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.XOA)
    const canAddNew = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.THEM_MOI)
    const canEdit = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NHOM_QUYEN.SUA)


    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);


    useEffect(() => {
        if (roleResponse?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            // totalPages.current = RoleResponse?.data?.totalPages;
            roleResponse.data.content.forEach((item: DataRoleResponse) => {
                newTableData.push({
                    key: item.id,
                    status: item.status,
                    roleName: item.roleName,
                    authoritieLst: item.authoritieLst,
                    modifiedBy: item.modifiedBy,
                    description: item.description,
                    used: item.used,
                    modifiedDate: item.modifiedDate ? moment(new Date(item.modifiedDate)).format("HH:mm DD/MM/YYYY") : "",
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
            // totalPages.current = 1;
        }
    }, [roleResponse]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, item, index) => (page - 1) * pageSizeRef.current + index + 1,
        },
        {
            title: "Thao Tác",
            key: "action",
            align: "center",
            render: (record) => {
                return (
                    canDelete && <Dropdown placement="bottomLeft" overlay={dropdownOverlay(record)} >
                        <Manipulation onClick={(e) => e.stopPropagation()} />
                    </Dropdown>
                );
            },
        },
        {
            title: "Nhóm quyền",
            dataIndex: "roleName",
            key: "roleName",
            align: "left",
        },
        {
            title: "Trạng Thái",
            key: "status",
            dataIndex: "status",
            render: (tag) => (
                <Tag style={{ border: 'none' }} className={`tag-status${!tag ? "_active" : "_err"}`}>
                    {!tag ? "Hoạt động" : "Không hoạt động"}
                </Tag>
            ),
            align: "center",
        },
        {
            title: "Mô tả",
            dataIndex: "description",
            key: "description",
            align: "left",
        },
        {
            title: "Ngày cập nhật",
            key: "modifiedDate",
            dataIndex: "modifiedDate",
            align: "left",
        },
        // {
        //     title: "Người cập nhật",
        //     key: "modifiedBy",
        //     dataIndex: "modifiedBy",
        //     align: "left",
        // },
    ];

    const dropdownOverlay = (record: any) => {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Menu
                    // items={menuItems}
                    className="menu-actions"
                    style={{ padding: "8px", width: "140px" }}
                >
                    {record.used ? <Menu.Item key="remove-detail" onClick={() => deleteRoleToggle(record)}><RemoveDetail />Xóa</Menu.Item> : <></>}
                    {
                        record.status ? (
                            <Menu.Item key="unlock">
                                <div
                                    style={{ display: 'flex', alignItems: 'center' }}
                                    onClick={() => activateRoleToggle(record)}
                                >
                                    <Unlock style={{ width: 24, height: 24, marginRight: 10 }} />
                                    Kích hoạt
                                </div>
                            </Menu.Item>
                        )
                            : (
                                <Menu.Item key="lock">
                                    <div
                                        style={{ display: 'flex', alignItems: 'center' }}
                                        onClick={() => activateRoleToggle(record)}
                                    >
                                        <Lock style={{ width: 24, height: 24, marginRight: 10 }} />Khóa
                                    </div>
                                </Menu.Item>
                            )
                    }
                </Menu>
            </div>
        );
    };

    // get table data
    const getData = () => {
        roleTrigger({
            searchParam: searchParam ? {
                ...searchParam
            } : "",
            page: 0,
            size: pageSizeRef.current
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        roleTrigger({
            searchParam: searchParam ? {
                ...searchParam
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        });
    }

    // Thêm mới
    const toggleAddRole = (record?: DataListTable) => {
        setAddRole(!addRole);
        if (record) setCurrentRole(record)
    }
    const onAddSuccessful = () => {
        toggleAddRole();
        setCurrentRole(undefined);
        setPage(1);
        roleTrigger({ size: pageSizeRef.current, page: 0, })
    }

    // Xóa 
    const deleteRoleToggle = (record: DataListTable) => {
        setDeleteRoleVisible(!deleteRoleVisible)
        setCurrentRole(record)
    }

    const onDelete = () => {
        if (currentRole) {
            if (!loadingBtnSubmit) {
                setLoadingBtnSubmit(true);
                deleteRole([currentRole.key]).unwrap().then(res => {
                    notification.success({
                        message: 'Xóa Nhóm quyền thành công'
                    })
                    setDeleteRoleVisible(false);
                    setCurrentRole(undefined);
                    setPage(1);
                    form.resetFields();
                    setLoadingBtnSubmit(false);
                    roleTrigger({ size: pageSizeRef.current, page: 0 });
                }).catch(err => {
                    notification.error({
                        message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                    });
                    setLoadingBtnSubmit(false);
                })
            }
        }
    }

    const activateRoleToggle = (record: any) => {
        setActivateRoleVisible(!activateRoleVisible)
        setCurrentRole(record)
    }

    const onActivate = () => {
        if (currentRole) {
            detailRoleTrigger(currentRole.key).unwrap()
                .then((res) => {
                    setLoadingBtnSubmit(true);
                    editTrigger({
                        id: currentRole.key,
                        roleName: res.roleName?.trim() || "",
                        description: res.description?.trim() || "",
                        authoritieLst: res.authoritieLst || [],
                        status: currentRole.status ? 0 : 1,
                    }).unwrap()
                        .then(res => {
                            notification.success({
                                message: `${currentRole.status ? 'Kích hoạt' : 'Khoá'} Nhóm quyền thành công.`
                            })
                            setActivateRoleVisible(false);
                            setCurrentRole(undefined);
                            setPage(1);
                            form.resetFields();
                            setLoadingBtnSubmit(false);
                            roleTrigger({ size: pageSizeRef.current, page: 0 });
                        }).catch(err => {
                            notification.error({
                                message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                            });
                            setActivateRoleVisible(false);
                            setLoadingBtnSubmit(false);
                        })
                })
        }
    }

    // reset All state
    const resetState = () => {
        setCurrentRole(undefined);
        setDeleteRoleVisible(false);
        setAddRole(false);
        setActivateRoleVisible(false);
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    {/* {canAddNew ? <Button
                        htmlType="button"
                        onClick={() => navigate('/setting/role/add')}
                        className="secondary-button"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <AddIcon className='add-icon' style={{ marginRight: 8 }} />
                        Thêm mới
                    </Button> : <></>
                    } */}
                    {canAddNew ? <Button
                        htmlType="button"
                        onClick={() => setAddRole(!addRole)}
                        className="secondary-button"
                        style={{ display: 'flex', alignItems: 'center' }}
                    >
                        <AddIcon className='add-icon' style={{ marginRight: 8 }} />
                        Thêm mới
                    </Button>
                        : <></>}
                </div>
            </Row>
            <Table<DataListTable>
                loading={roleResponse?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
                onRow={(record) => {
                    // return {
                    //     onClick: event => navigate(`/setting/role/detail/${record.key}`),
                    // }
                    return {
                        onClick: event => toggleAddRole(record)
                    }
                }}
            />

            <Spin spinning={roleResponse?.isFetching} tip="Đang tải...">
                <Pagination
                    total={roleResponse?.data?.totalElements || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[1, 10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>


            {addRole && (
                <AddRoleModalv1
                    visible={addRole}
                    onCancel={resetState}
                    currentRole={currentRole}
                    onAddSuccessful={onAddSuccessful}
                    canEdit={canEdit}
                />
            )}

            {/* modal xóa Phường/Xã */}
            {deleteRoleVisible && (
                <ConfirmModalCustom
                    onCancel={resetState}
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    showCancel={true}
                    showOk={true}
                    type={"error"}
                    danger={true}
                    title="Xóa Nhóm quyền"
                    content="Bạn có chắc chắn muốn xóa Nhóm quyền này hay không?"
                    visible={deleteRoleVisible}
                    onOk={onDelete}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}

            {activateRoleVisible && (
                <ConfirmModalCustom
                    onCancel={resetState}
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    showCancel={true}
                    showOk={true}
                    type={currentRole?.status === 0 ? 'warning' : 'success'}
                    title={currentRole?.status === 0 ? "Khóa Nhóm quyền" : "Kích hoạt Nhóm quyền"}
                    content={currentRole?.status === 0 ?
                        "Bạn có chắc chắn muốn khóa Nhóm quyền này hay không?" :
                        "Bạn có chắc chắn muốn kích hoạt Nhóm quyền này hay không?"
                    }
                    visible={activateRoleVisible}
                    onOk={onActivate}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}

        </div>
    )
}

export default RoleList