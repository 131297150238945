import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { MiddlewareAPI, Middleware } from "@reduxjs/toolkit";
import { logout, setUser } from "../../modules/login/redux/UserSlice";

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware =
    (api: MiddlewareAPI) => (next) => (action) => {
        // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
        if (isRejectedWithValue(action)) {
            if (
                action?.payload?.status === 401 ||
                action?.payload?.data?.status === 401
            ) {
                api.dispatch(logout());
                api.dispatch(setUser({}));
            }
        }

        return next(action);
    };
