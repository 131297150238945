import { Button, Col, Form, Input, Modal, notification, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { ReactComponent as EditIcon } from "../../../../assets/logos/edit_icon.svg";
import { ReactComponent as UpdateIcon } from "../../../../assets/logos/update_icon.svg";
import { ReactComponent as AddIcon } from "../../../../assets/logos/add_icon.svg";
// import "../assets/scss/styles.scss";
// import { useAddProvinceMutation, useEditProvinceMutation } from "../redux/ProvinceApi";
import { DataListTable } from "./ProvinceList";
import { useAddProvinceMutation, useEditProvinceMutation } from "../redux/provinceApi";
import { useAppSelector } from "../../../../redux/Hooks";
// import { useAppSelector } from "../../../redux/Hooks";
// import { PERMISSION_KEYS } from '../../../modules/permissions/assets/ts/Constants'
// import { usePrompt } from "../../../prompts";

interface AddProvinceProps {
    visible: boolean;
    onCancel: () => void;
    currentProvince: DataListTable | undefined;
    onAddSuccessful: () => void;
    canEdit: boolean;
}

const AddProvinceModal = ({ visible, onCancel, currentProvince, onAddSuccessful, canEdit }: AddProvinceProps) => {
    const [form] = Form.useForm();
    const [editing, setEditing] = useState(false);
    const [addTrigger] = useAddProvinceMutation();
    const [editTrigger] = useEditProvinceMutation();
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);

    useEffect(() => {
        if (currentProvince) {
            form.setFieldsValue({
                idCity: currentProvince.provinceCode || "",
                city: currentProvince.provinceName || "",
                status: currentProvince.status !== undefined ? currentProvince.status : undefined,
            })
        }
        else {
            form.setFieldsValue({
                status: 0,
            })
        }
    }, [currentProvince, form]);

    const onFinish = (values: any) => {
        if (!loadingBtnSubmit) {
            setLoadingBtnSubmit(true);
            if (currentProvince) {
                editTrigger({
                    id: currentProvince.key,
                    provinceCode: values.idCity?.trim() || "",
                    provinceName: values.city?.trim() || "",
                    status: values.status,
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Cập nhật Tỉnh/Thành phố thành công.'
                        });
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
            else {
                addTrigger({
                    provinceCode: values.idCity?.trim() || "",
                    provinceName: values.city?.trim() || "",
                    status: values.status,
                }).unwrap()
                    .then((res: any) => {
                        notification.success({
                            message: 'Thêm mới Tỉnh/Thành phố thành công.'
                        })
                        setLoadingBtnSubmit(false);
                        onAddSuccessful();
                    }
                    ).catch((err: any) => {
                        notification.error({
                            message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                        });
                        setLoadingBtnSubmit(false);
                    })
            }
        }
    };

    return (
        <>
            <Modal
                style={{ borderRadius: "8px", overflow: "hidden", minWidth: "360px" }}
                onCancel={onCancel}
                visible={visible}
                footer={false}
                title={currentProvince ? "Thông tin Tỉnh/Thành phố" : "Thêm mới Tỉnh/Thành phố"}
                width={"40%"}
            >
                <Form
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    labelAlign="left"
                    form={form}
                    onFinish={onFinish}
                >
                    <Row gutter={20}>
                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Mã Tỉnh/Thành phố"
                                name="idCity"
                                rules={[
                                    { required: true, message: "Vui lòng nhập Mã Tỉnh/Thành phố." },
                                    {
                                        pattern: /^[^\sÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂưăạảấầẩẫậắằẳẵặẹẻẽềềểỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ]+$/,
                                        message: 'Mã Tỉnh/Thành phố không chứa dấu cách và ký tự có dấu.',
                                    }
                                ]}
                            >
                                <Input
                                    className="propNameInput"
                                    placeholder="Nhập mã Tỉnh/Thành phố "
                                    allowClear
                                    disabled={!editing && currentProvince ? true : false}
                                    maxLength={10}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Tên Tỉnh/Thành phố"
                                name="city"
                                rules={[
                                    { required: true, message: "Vui lòng nhập Tên Tỉnh/Thành phố." },
                                    { whitespace: true, message: "Vui lòng nhập Tên Tỉnh/Thành phố." }
                                ]}
                            >
                                <Input
                                    className="propNameInput"
                                    placeholder="Nhập tên Tỉnh/Thành phố"
                                    allowClear
                                    maxLength={50}
                                    disabled={!editing && currentProvince ? true : false}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24} xs={24} xl={24} xxl={24}>
                            <Form.Item
                                label="Trạng thái"
                                name="status"
                                rules={[
                                    { required: true, message: "Vui lòng chọn Trạng thái." }
                                ]}
                            >
                                <Select
                                    optionFilterProp="label"
                                    allowClear
                                    placeholder="--Lựa chọn--"
                                    disabled={!editing && currentProvince ? true : false}
                                >
                                    <Select.Option value={0}>Hoạt động</Select.Option>
                                    <Select.Option value={1}>Không hoạt động</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>


                    {(!editing && currentProvince) && (
                        <div className="modal-action-buttons" style={{ display: 'flex', justifyContent: 'space-between' }}>
                            {canEdit ?
                                <Button
                                    key="update"
                                    style={{ height: "36px", width: "36px", padding: 0 }}
                                    onClick={() => {
                                        setEditing(!editing);
                                    }}
                                >
                                    <EditIcon />
                                </Button> : <></>
                            }
                            <Button key="close" style={{ height: "36px", width: "120px" }} onClick={onCancel}>
                                Đóng
                            </Button>
                        </div>
                    )}


                    {editing && (
                        <div className="modal-action-buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button key="edit" style={{ height: "36px", width: "120px", marginRight: 10 }} onClick={onCancel}>
                                Đóng
                            </Button>
                            <Button key="back" type="primary" style={{ height: "36px", width: "120p", display: "flex", alignItems: "center" }} htmlType="submit">
                                <UpdateIcon style={{ marginRight: 4 }} />
                                Cập nhật
                            </Button>
                        </div>
                    )}

                    {!currentProvince && (
                        <div className="modal-action-buttons" style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button key="update" style={{ height: "36px", width: "120px", marginRight: 10 }} onClick={onCancel}>
                                Đóng
                            </Button>
                            <Button key="back" type="primary" style={{ height: "36px", width: "120px", display: "flex", alignItems: "center" }} htmlType="submit">
                                <AddIcon style={{ marginRight: 4 }} />
                                Thêm mới
                            </Button>
                        </div>
                    )}
                </Form>
            </Modal>
        </>
    );
};

export default AddProvinceModal;
