import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import {DataRoleResponse} from "../../permissions/redux/apiTypes";
import {User} from "../../../redux/api/apiTypes";

export const groupUserApi = createApi({
    reducerPath: "groupUserApi",
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/group-user",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({

        // get Page
        getPage: builder.query<any, { searchParam?: any } & Partial<any>>({
            query: (body) => ({
                url: `getPage`,
                method: 'GET',
                params: {
                    search: JSON.stringify({...body.searchParam}),
                    pageNumber: body.page || 0,
                    pageSize: body.size || 10,
                    size: body.size || 10,
                    sortBy: body.sortBy || "DESC",
                    sortByProperty: body.sortByProperty || "modifiedDate",
                }
            }),
            transformResponse: (response : {data: any}, meta, arg) => {
                return response.data;
            }
        }),

        getUser: builder.query<any[], any>({
            query: () => `/get-users`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),

        getRole: builder.query<any[], any>({
            query: () => `/get-roles`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),

        // chi tiet
        getDetail: builder.query<any, any>({
            query: (id) => ({
                url: `/${id}`,
                method: 'GET',
            }),
            transformResponse: (response : {data: any }, meta, arg) => {
                return response.data;
            }
        }),

        // thêm mới
        add: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: `add`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { body: any }, meta, arg
            ) => {
                return response.body;
            },
        }),

        // chỉnh sửa
        edit: builder.mutation<any, Partial<any>>({
            query: (body) => ({
                url: `edit`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: any, message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),

        // xoá
        delete: builder.mutation<any[], string[]>({
            query: (body) => ({
                url: `delete`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: any[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
        
    })
});

export const {
    useLazyGetPageQuery,
    useLazyGetDetailQuery,
    useAddMutation,
    useEditMutation,
    useDeleteMutation,
    useLazyGetUserQuery,
    useLazyGetRoleQuery

} = groupUserApi;
export default groupUserApi;
