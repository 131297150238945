import { Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { LIST_COLOR_DISPLAY_THHT } from "../../../utils/constants";

export interface ILegendData {
    color: string;
    name: string;
    [key: string]: any;
}

interface IPropsLegendChart {
    data: ILegendData[];
    onChange: (e: CheckboxChangeEvent, name: string) => void;
}

const LegendChartCheckbox = (props: IPropsLegendChart) => {
    const { data, onChange } = props;
    const sortData = data?.sort(
        (a: ILegendData, b: ILegendData) =>
            LIST_COLOR_DISPLAY_THHT.indexOf(a.name) -
            LIST_COLOR_DISPLAY_THHT.indexOf(b.name)
    );
    return (
        <div className="legend-chart">
            {sortData.map(
                (
                    item: {
                        color: string;
                        name: string;
                    },
                    i: number
                ) => {
                    const style = {
                        [`--legend-background-color`]: item.color,
                        [`--legend-border-color`]: item.color,
                    } as React.CSSProperties;
                    return (
                        <Checkbox
                            key={i}
                            className="color"
                            style={style}
                            onChange={(e) => onChange(e, item.name)}
                        >
                            {item.name}
                        </Checkbox>
                    );
                }
            )}
        </div>
    );
};

export default LegendChartCheckbox;
