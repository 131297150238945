import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import CampaignList from "./components/CampaignList";
import { useLazyGetUserCheckDataQuery } from "../../redux/api/commonApi";
import { ReactComponent as ZoomOut } from '../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../assets/logos/zoom-in-line.svg';

const RegisterInfo = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();

    useEffect(() => {
        triggerUserCheck({});
    }, [triggerUserCheck])

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    const resetFormData = () => {
        form.resetFields();
    }

    const [isSearch, setIsSearch] = useState(false);

    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Chiến dịch</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            {/* <div className='title-label' style={{ marginBottom: 20 }}>Danh sách chiến dịch</div> */}

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>
                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                            <Col span={8} xs={24} md={12} lg={12}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Tên chiến dịch"
                                    name="campaignName"
                                >
                                    <Input placeholder="Nhập tên chiến dịch" style={{ minWidth: '100%' }} />
                                </Form.Item>
                            </Col>
                            {/* <Col span={8} xs={24} md={12} lg={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Trạng thái"
                                name="isCheck"
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn trạng thái"
                                    optionFilterProp='label'
                                    options={([{ id: 1, name: "Đã kiểm tra" }, { id: 2, name: "Chưa kiểm tra" }]).map(item => ({
                                        label: item.name,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col> */}
                            <Col span={8} xs={24} md={12} lg={12}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Người thực hiện"
                                    name="userIds"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn người thực hiện"
                                        options={(responseUserCheck?.data || []).map(item => ({
                                            label: `${item.fullName} (${item.username})`,
                                            value: item.id
                                        }))}
                                        showSearch
                                        mode="multiple"
                                        maxTagCount='responsive'
                                        optionFilterProp="label"
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Mô tả"
                                    name="description"
                                >
                                    <Input placeholder="Nhập mô tả" style={{ minWidth: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={12}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Nguồn dữ liệu"
                                    name="type"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn nguồn dữ liệu"
                                        optionFilterProp='label'
                                        options={([{ id: 1, name: "Dữ liệu từ file excel" }, { id: 2, name: "Dữ liệu nhập liệu" }]).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className='search-box-button'>
                            <Button htmlType='button' onClick={() => resetFormData()}>
                                Xóa điều kiện
                            </Button>
                            <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                        </div>
                    </> : <></>}

                </Form>
            </div>
            <CampaignList form={form} searchParam={searchParam} />
        </>
    )
}

export default RegisterInfo;
