import { FormInstance, Pagination, Spin, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useRef, useState } from "react";
import { useGetAccessHistoryLstMutation } from "../redux/AccessHistoryApi";

type Props = {
    form: FormInstance<any>;
    searchParam: any;
};

const AcessHistoryList = ({ form, searchParam }: Props) => {
    const [tableData, setTableData] = useState<any[]>([]);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [accessHistoryTrigger, accessHistoryData] = useGetAccessHistoryLstMutation();
    useEffect(() => {
        getData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        if (accessHistoryData?.data?.content?.length) {
            let newTableData: any[] = [];
            // totalPages.current = RoleResponse?.data?.totalPages;
            accessHistoryData.data.content.forEach((item: any) => {
                newTableData.push({
                    ...item,
                });
            });
            setTableData(newTableData);
        } else {
            setTableData([]);
            // totalPages.current = 1;
        }
    }, [accessHistoryData]);

    const columns: ColumnsType<any> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, item, index) =>
                (page - 1) * pageSizeRef.current + index + 1,
        },
        {
            title: "Người dùng",
            dataIndex: "username",
            key: "username",
            align: "left",
        },
        {
            title: "Thông tin trình duyệt",
            dataIndex: "userAgent",
            key: "userAgent",
            align: "left",
            width: "35%",
        },
        {
            title: "IP truy cập",
            dataIndex: "ip",
            key: "ip",
            align: "left",
            render: (ip) => (
                <Tag className="tag-status_active" style={{ border: "none" }}>
                    {ip}
                </Tag>
            ),
        },
        {
            title: "Hành động",
            key: "type",
            dataIndex: "type",
            render: (type) => (
                <span style={{ border: "none" }}>
                    {type === 0 ? "Đăng nhập" : "Đăng xuất"}
                </span>
            ),
            align: "center",
        },
        {
            title: "Thời gian",
            key: "modifiedDateStr",
            dataIndex: "modifiedDateStr",
            align: "left",
            render: (date) => {
                const [day, time] = (date || '').split(' ');
                return (
                  <span>{time + ' ' + day}</span>
                ) 
            }
        },
    ];

    // get table data
    const getData = () => {
        accessHistoryTrigger({
            ...searchParam,
            page: 0,
            size: pageSizeRef.current,
        });
        setPage(1);
    };

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        accessHistoryTrigger({
            ...searchParam,
            page: newPage - 1,
            size: pageSizeRef.current,
        });
    };

    return (
        <div className="content-box">
            <Table<any>
                loading={accessHistoryData?.isLoading}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={accessHistoryData?.isLoading} tip="Đang tải...">
                <Pagination
                    total={accessHistoryData?.data?.totalElements || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: " dòng" }}
                    pageSizeOptions={[1, 10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                    showTotal={(total, range) => (
                        <span style={{ right: 280, position: "absolute" }}>
                            <>
                                Tổng số:{" "}
                                <b>
                                    {new Intl.NumberFormat().format(total || 0)}
                                </b>{" "}
                                bản ghi
                            </>
                        </span>
                    )}
                />
            </Spin>
        </div>
    );
};

export default AcessHistoryList;
