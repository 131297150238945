import { Button, Col, Form, Input, notification, Row, Spin } from "antd";
import { useCheckExitFilePdfMutation, useLazyGetRandomDataQuery, useLazyGetSortIdQuery, useUpdateDataByCampaignMutation } from "../redux/dataCheckApi";
import { useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FieldConfig } from "../redux/apiTypes";
import { ReactComponent as Top } from '../../../assets/logos/layout-top-line.svg';
import { ReactComponent as Left } from '../../../assets/logos/layout-left-line.svg';
import { ReactComponent as Right } from '../../../assets/logos/layout-right-line.svg';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import ConfirmModal from "../../common/components/ConfirmModal";
import useResizeObserver from '@react-hook/resize-observer';
import moment from "moment";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { PDFPageProxy } from 'react-pdf';

const useWidth = (target: any) => {
    const [width, setWidth] = useState(300);
    useLayoutEffect(() => {
        setWidth(target.current.getBoundingClientRect().width)
    }, [target]);
    useResizeObserver(target, (entry: any) => {
        setWidth(entry.contentRect.width)
    });
    return width;
};

const CheckDataInfo = () => {
    const [form] = Form.useForm();
    const param = useParams();
    const { state } = useLocation();
    const navigate = useNavigate();
    const [triggerDataInfo, dataInfoResponse] = useLazyGetRandomDataQuery();
    const [updateData, { isLoading }] = useUpdateDataByCampaignMutation();
    const [displayFlex, setDisplayFlex] = useState(true);
    const [orderNumber, setOrderNumber] = useState(1);
    const [filePdfExit, setFilePdfExit] = useState();
    const [timeFilePdfExit, setTimeFilePdfExit] = useState();
    const [keyMaps, setKeyMaps] = useState<FieldConfig[]>([]);
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [confirmVisible, setConfirmVisible] = useState(false);
    const [confirmNextextVisible, setConfirmNextextVisible] = useState(false);
    const [triggerCurrentSort, currentSortResponse] = useLazyGetSortIdQuery();
    const wrapperDiv = useRef(null);
    const width = useWidth(wrapperDiv);
    const [checkExitFilePdf] = useCheckExitFilePdfMutation();
    const file = useMemo(() => {
        return {
            url: process.env.REACT_APP_API_URL + `/api/v1/tool/data-info/dowload-file/${dataInfoResponse?.data?.id}`,
            httpHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        }
    }, [dataInfoResponse?.data?.id]);
    const [initialPos, setInitialPos] = useState(0);
    const [initialSize, setInitialSize] = useState(0);

    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    useEffect(() => {
        if (clientY===0 || !canvas.current||!isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
    }, [clientY, isRendered]);
    useEffect(()=>{
        if(prevScale.current===scale){
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY)=>prevClientY*(scale / prevScale.current));
            setIsRendered(false);
        }
    },[scale])
    const  onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if(!isDrag){
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top)*scaleY);
        }
    }

    const initial = (e: any) => {
        let resizable = document.getElementById('block-view-pdf');
        setInitialPos(e.clientX);
        setInitialSize(resizable?.offsetWidth || 0);

    }

    const resize = (e: any) => {
        let resizable = document.getElementById('block-view-pdf');
        if (resizable) {
            resizable.style.width = `${initialSize + e.clientX - initialPos}px`;
        }
    }

    useEffect(() => {
        triggerDataInfo({});
    }, [triggerDataInfo]);

    useEffect(() => {
        if (dataInfoResponse?.data?.keyMaps?.length) {
            let dataKeys = dataInfoResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
            triggerCurrentSort(dataInfoResponse?.data?.dataFile?.id).unwrap()
                .then(res => {
                    setKeyMaps((res?.keyMaps || dataInfoResponse?.data?.keyMaps).map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                        }
                    }));
                }).catch(err => {
                    setKeyMaps(dataInfoResponse?.data?.keyMaps.map((item: any, index: number) => {
                        return {
                            ...item,
                            dataKey: dataKeys[item.dataKeyAscii]?.dataKey,
                            dataKeyAscii: dataKeys[item.dataKeyAscii]?.dataKeyAscii,
                            dataValue: dataKeys[item.dataKeyAscii]?.dataValue,
                            isEdited: false,
                        }
                    }));
                });
        } else {
            setKeyMaps([]);
        }
    }, [dataInfoResponse]);

    const onFinish = () => {
        toggleConfirmModal();
    }

    const onSubmitCheckData = () => {
        updateData({
            keyMaps,
            id: dataInfoResponse?.data?.id,
            isCheck: true,
        }).unwrap()
            .then(res => {
                notification.success({ message: "Cập nhật kết quả thành công" });
                triggerDataInfo({}).unwrap()
                    .then(res => {
                    });
                toggleConfirmModal();
            }).catch(err => {
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            })
    }

    const changeLayout = (newDisplayFlex: boolean, order: number) => {
        setDisplayFlex(newDisplayFlex);
        setOrderNumber(order);
    }

    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }

    const onDocumentLoadError = () => {
        if (dataInfoResponse?.data && dataInfoResponse?.data.id) {
            onCheckExitFilePdf(null, dataInfoResponse?.data);
        }
    }
    const changePage = (offset: number) => {
        setPageNumber(prevPageNumber => prevPageNumber + offset);
    }

    const previousPage = () => {
        changePage(-1);
    }

    const nextPage = () => {
        changePage(1);
    }

    const changeData = (e: any, key: string) => {
        let oldKeyMaps = dataInfoResponse?.data?.keyMaps ? [...dataInfoResponse?.data?.keyMaps] : [];
        let dataKeys = dataInfoResponse?.data?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        let currentKeyMaps = [...keyMaps];
        // currentKeyMaps[index] = {
        //     ...currentKeyMaps[index],
        //     dataValue: e.target.value || "",
        //     isEdited: e.target.value !== dataKeys[key].dataValue,
        // }
        currentKeyMaps = currentKeyMaps.map(item => {
            if (item.dataKeyAscii === key) {
                return {
                    ...item,
                    dataValue: e.target.value || "",
                    isEdited: e.target.value !== dataKeys[key].dataValue,
                }
            }
            return item;
        })
        setKeyMaps(currentKeyMaps);
    }

    const checkChangeData = () => {
        return keyMaps.find(x => x.isEdited);
    }

    const nextDataInfo = () => {
        if (confirmNextextVisible || !checkChangeData()) {
            triggerDataInfo({});
            if (confirmNextextVisible) {
                setConfirmNextextVisible(!confirmNextextVisible);
            }
        } else {
            setConfirmNextextVisible(!confirmNextextVisible);
        }
    }

    const toggleConfirmModal = () => {
        setConfirmVisible(!confirmVisible);
    }

    const onCheckExitFilePdf = (e: any, record: any) => {
        checkExitFilePdf(record.id).unwrap()
            .then(res => {
                setFilePdfExit(res.filePdfExit);
                setTimeFilePdfExit(res.timeFilePdfExit);
            }).catch(err => {
                notification.error({ message: "Có lỗi xảy ra!" });
            });
    }

    return (
        <Spin tip="Đang tải..." spinning={dataInfoResponse.isFetching}>
            <div className="content-box" >
                <div className="header-block" style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Top onClick={() => changeLayout(false, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    <Right onClick={() => changeLayout(true, 3)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    {/* <Bottom onClick={() => changeLayout(false, 2)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} /> */}
                    <Left onClick={() => changeLayout(true, 1)} style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                </div>
                <div className='detail-data-info' style={{ display: displayFlex ? "flex" : "block" }}>
                    <div
                        style={displayFlex ? {
                            order: orderNumber,
                            width: displayFlex ? "50%" : "100%",
                            resize: "horizontal",
                            overflow: "auto"
                        } : {
                            borderBottom: orderNumber === 1 ? "dashed 2px #E5E5E5" : "",
                            order: orderNumber,
                            width: displayFlex ? "50%" : "100%",
                        }}
                        id="block-view-pdf"
                        ref={wrapperDiv}
                    >
                        <div className="header-block">
                            <label className='search-title'>File PDF: {(dataInfoResponse?.data?.filePdfPath || "").split("/")[(dataInfoResponse?.data?.filePdfPath || "").split("/").length - 1]}</label>
                            <ZoomOut
                                onClick={() => setScale((prev:number)=>{ prevScale.current = prev; return scale === 1 ? 1 : scale - 1})}
                                style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                            <ZoomIn
                                onClick={() => setScale((prev:number)=>{ prevScale.current = prev; return scale + 1})}
                                style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                            <Rotate
                                onClick={() => setRotate(rotate - 90)}
                                style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                            {dataInfoResponse?.data ? <div>
                                {dataInfoResponse?.data?.filePdfExit || filePdfExit ?
                                    dataInfoResponse?.data?.filePdfExit === 1 || filePdfExit === 1 ? `Tồn tại (${dataInfoResponse?.data?.timeFilePdfExit || moment(timeFilePdfExit).format("HH:mm DD/MM/YYYY")})` :
                                        `Không tồn tại (${dataInfoResponse?.data?.timeFilePdfExit || moment(timeFilePdfExit).format("HH:mm DD/MM/YYYY")})`
                                    : "Chưa kiểm tra tồn tại"}
                                <span
                                    onClick={(e) => onCheckExitFilePdf(e, dataInfoResponse?.data)}
                                    style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }}
                                >Kiểm tra</span>
                            </div> : <></>}
                        </div>
                        <div style={{ overflowY: "auto", height: "calc(100vh - 160px)" }} >
                            {/* <Document
                                // file={{
                                //     url: process.env.REACT_APP_API_URL + `/api/v1/tool/data-info/dowload-file/${param.id}`,
                                //     httpHeaders: {
                                //         Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                                //         "Content-Type": "application/json;charset=UTF-8",
                                //     },
                                // }}
                                file={file}
                                // file={process.env.REACT_APP_API_URL + `/public/v1/common/dowload-file/${param.id}`}
                                onLoadSuccess={onDocumentLoadSuccess}
                                onLoadError={onDocumentLoadError}
                            >
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page width={width} rotate={rotate} scale={scale} key={`page_${index + 1}`} pageNumber={index + 1} />
                                ))}
                            </Document> */}
                            <TransformWrapper
                                    maxScale={3}
                                    initialScale={1}
                                    disablePadding={true}
                                    ref={transformComponentRef}
                                    onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                                        if (ref.state.scale >= 3) {
                                            setScale((prev:number) => { prevScale.current = prev; return prev + 1});
                                        } else if (ref.state.scale <= 1) {
                                            setScale((prev:number) => { prevScale.current = prev; return prev - 1 || 1});
                                        }
                                    }}
                                >
                                    <TransformComponent>
                                        <Document
                                            file={file}
                                            onLoadSuccess={onDocumentLoadSuccess}
                                            onLoadError={onDocumentLoadError}>
                                            {Array.from(new Array(numPages), (el, index) => (
                                                <Page
                                                    width={width}
                                                    rotate={rotate}
                                                    scale={scale}
                                                    key={`page_${index + 1}`}
                                                    pageNumber={index + 1}
                                                    canvasRef={canvas}
                                                    onRenderSuccess={onRenderSuccess}
                                                    onClick={onClickPage}
                                                />
                                            ))}
                                        </Document>
                                    </TransformComponent>
                                </TransformWrapper>
                        </div>
                        {/* <div>
                            <p>
                                Trang {pageNumber || (numPages ? 1 : "--")} / {numPages || "--"}
                            </p>
                            <button style={{ marginLeft: 0 }} type="button" disabled={pageNumber <= 1} onClick={previousPage}>
                                Quay lại
                            </button>
                            <button
                                type="button"
                                disabled={pageNumber >= numPages}
                                onClick={nextPage}
                            >
                                Tiếp
                            </button>
                        </div> */}
                    </div>
                    {displayFlex ? <div
                        id='Draggable'
                        draggable='true'
                        onDragStart={initial}
                        onDrag={resize}
                    ></div> : <></>}
                    <div
                        style={displayFlex ? {
                            order: orderNumber === 1 ? 3 : 1,
                            width: displayFlex ? `calc(100% - ${width}px)` : "auto",
                            resize: "horizontal",
                            overflow: "auto"
                        } : {
                            order: orderNumber === 1 ? 3 : 1,
                            width: displayFlex ? `calc(100% - ${width}px)` : "auto",
                            borderBottom: orderNumber !== 1 ? "dashed 2px #E5E5E5" : "",
                        }}
                    >
                        <Form
                            name="searchBox"
                            onFinish={onFinish}
                            autoComplete="off"
                            form={form}
                            colon={false}
                            style={{ marginTop: 0 }}
                            layout="vertical"
                            labelWrap
                            labelAlign="left"
                        >
                            <label className='search-title'>Dữ liệu excel: {dataInfoResponse?.data?.dataFile?.fileAttachDocument?.fileName}</label>
                            <div style={{ overflowY: "auto", overflowX: "hidden", height: "calc(100vh - 215px)", paddingRight: 10 }}>
                                <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                                    {keyMaps.length ? keyMaps.filter(x => x.isDisplay).map((item: any, index) => {
                                        return (<Col span={24} key={item.dataKeyAscii + index}>
                                            <Form.Item
                                                style={{ marginBottom: 10 }}
                                                label={<div style={{ fontWeight: 700 }}>
                                                    {item.dataKey || ""}
                                                    {/* {!dataInfoResponse?.data?.campaignId ? (item.isEdited ?
                                                        <ExclamationCircleOutlined title="Đã chỉnh sửa" style={{ marginLeft: 5, color: "#faad14" }} /> :
                                                        <CheckCircleOutlined title="Chưa chỉnh sửa" color="success" style={{ marginLeft: 5, color: "#52c41a" }} />
                                                    ) : <></>} */}
                                                </div>}
                                            >
                                                <Input.TextArea onFocus={(e) => e.currentTarget.select()} onChange={(e) => changeData(e, item.dataKeyAscii)} value={item.dataValue} placeholder="Nhập dữ liệu" />
                                            </Form.Item>
                                        </Col>)
                                    }) : <div style={{ marginLeft: 8, fontWeight: 400 }} className='search-title'>Không còn dữ liệu</div>}
                                </Row>
                            </div>
                            {keyMaps && keyMaps.length ? <div className='search-box-button' style={{ marginTop: 15 }}>
                                {/* <Link to='/data/list' state={state}><Button htmlType='button'>
                                    Quay lại
                                </Button></Link> */}
                                <Button onClick={nextDataInfo}>Kiểm tra dữ liệu khác</Button>
                                <Button type='primary' htmlType='submit'>
                                    {checkChangeData() ? "Cập nhật dữ liệu" : "Xác nhận"}
                                </Button>
                            </div> : <></>}
                        </Form>
                    </div>
                </div>
            </div>
            {confirmVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn có chắc chắn muốn cập nhật dữ liệu không?"]}
                    handleCancel={toggleConfirmModal}
                    handleOk={onSubmitCheckData}
                    type={1}
                    visible={confirmVisible}
                    widthConfig={424}
                    okBtnDanger={true} />
                : <></>}
            {confirmNextextVisible ?
                <ConfirmModal
                    cancelText="Huỷ bỏ"
                    okText="Đồng ý"
                    classBtnOk="ant-button-dangerous"
                    contents={["Bạn đồng ý huỷ kết quả chỉnh sửa dữ liệu này để kiểm tra dữ liệu khác?"]}
                    handleCancel={() => setConfirmNextextVisible(!confirmNextextVisible)}
                    handleOk={nextDataInfo}
                    type={1}
                    visible={confirmNextextVisible}
                    widthConfig={424}
                    okBtnDanger={true} />
                : <></>}
        </Spin >
    )
}

export default CheckDataInfo;
