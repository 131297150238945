import { Col, Row, Table, Pagination, FormInstance, Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useLazyGetFieldConfigCountQuery, useLazyGetFieldConfigQuery } from "../redux/fieldConfigApi";
import moment from "moment";
import CreateFieldConfig from "./CreateFieldConfig";
import ReactJson from 'react-json-view'

interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    createdDate?: string,
    type: string,
    date: string,
    description?: any,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const FieldConfigList = ({ form, searchParam }: Props) => {
    const [triggerFieldConfig, responseDataInfo] = useLazyGetFieldConfigQuery();
    const [triggerFieldConfigCount, responseDataInfoCount] = useLazyGetFieldConfigCountQuery();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const [currentFieldConfig, setCurrentFieldConfig] = useState<DataListTable>();
    const pageSizeRef = useRef(10);
    const [createFieldConfigVisible, setCreateFieldConfigVisible] = useState(false);

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        if (responseDataInfo?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseDataInfo.data.content.forEach((item, index) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    createBy: item.createBy,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    type: item.type || "",
                    date: item.year || "",
                    description: <div style={{ maxHeight: 200, overflow: "auto" }}>
                        {/*  onEdit={() => console.log("test")} */}
                        <ReactJson name={false} collapsed={1} src={item?.fieldKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {})} />
                    </div>,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseDataInfo]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Loại dữ liệu",
            dataIndex: "type",
            key: "type",
            render: (value, record, index) => <span style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }} onClick={(e) => viewDetailFieldConfig(record)}>{record.type}</span>,
        },
        {
            title: "Mẫu",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Nội dung",
            dataIndex: "description",
            key: "description",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];

    const getData = () => {
        triggerFieldConfig({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerFieldConfigCount({
            searchParam,
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerFieldConfig({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerFieldConfigCount({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
        })
    }

    const toggleModal = () => {
        if (createFieldConfigVisible) {
            setCurrentFieldConfig(undefined);
        }
        setCreateFieldConfigVisible(!createFieldConfigVisible);
    }

    const createFieldConfigSuccess = () => {
        toggleModal();
        getData();
    }

    const viewDetailFieldConfig = (current: DataListTable) => {
        setCurrentFieldConfig(current);
        toggleModal();
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    <Button
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Thêm mới</Button>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseDataInfo?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Pagination
                total={responseDataInfoCount?.data || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />
            {createFieldConfigVisible ?
                <CreateFieldConfig
                    currentFieldConfig={currentFieldConfig}
                    visible={createFieldConfigVisible}
                    toggleModal={toggleModal}
                    createFieldConfigSuccess={createFieldConfigSuccess}
                /> : <></>}
        </div>
    )
}

export default FieldConfigList;