import { Breadcrumb, Button, Checkbox, Col, Form, Input, InputNumber, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import RegisterInfoList from "./components/RegisterInfoList";
import { useLocation } from "react-router-dom";
import { Commune, District } from "../../redux/api/apiTypes";
import { useLazyGetAllCampaignQuery, useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../redux/api/commonApi";
import { TYPE_DATA } from "../../assets/ts/constant";
import { useLazyGetFileExcelByPageQuery, useLazyGetFileExcelQuery } from "./redux/dataCheckApi";
import LazyLoadSelect from "../common/components/LazyLoadSelect";
import { DefaultOptionType } from "antd/lib/select";
import { DataFile } from "./redux/apiTypes";
import { ReactComponent as ZoomOut } from '../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../assets/logos/zoom-in-line.svg';
const RegisterInfo = () => {
    const [form] = Form.useForm();
    const { state } = useLocation();
    const [triggerCampaign, responseCampaign] = useLazyGetAllCampaignQuery();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [fileExcelTrigger, responseFileExcel] = useLazyGetFileExcelByPageQuery();
    const [fileExcelAllTrigger, responseFileAllExcel] = useLazyGetFileExcelQuery();
    const [searchParam, setSearchParam] = useState({});
    const [dataCampaignId, setDataCampaignId] = useState();
    const [fileExcelData, setFileExcelData] = useState<DefaultOptionType[]>([])

    useEffect(() => {
        if (responseFileExcel && responseFileExcel.data && responseFileExcel.data.content && responseFileExcel.data.content.length) {
            let newFileExcelData = responseFileExcel.data.content.map((item: any) => {
                return {
                    label: item.fileAttachDocument.fileName,
                    value: item.fileAttachDocument.fileName
                }
            })
            setFileExcelData(newFileExcelData);
        } else {
            setFileExcelData([]);
        }
    }, [responseFileExcel]);

    useEffect(() => {
        // fileExcelTrigger({});
        fileExcelAllTrigger({});
        triggerProvince({});
        triggerCampaign({});
        if (state && typeof state === "object") {
            let newSearchParam: any = { ...state };
            setSearchParam(newSearchParam);
            form.setFieldsValue(newSearchParam)
            if (newSearchParam?.dataCampaignId) {
                setDataCampaignId(newSearchParam.dataCampaignId);
            }
        } else {
            setSearchParam({});
            form.setFieldsValue({})
        }
    }, [triggerCampaign, fileExcelTrigger, triggerProvince, form, state])

    const onFinish = (values: any) => {
        setSearchParam(values);
    }

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] === "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceId) {
                districtTrigger(changedValues.provinceId).unwrap()
                    .then(res => setDistricts(res));
            }
            form.setFieldsValue({
                districtId: undefined,
                communeId: undefined,
            })

        } else if (Object.keys(changedValues)[0] === "districtId") {
            setCommunes([]);
            if (changedValues.districtId) {
                communeTrigger(changedValues.districtId).unwrap()
                    .then(res => setCommunes(res));
            }
            form.setFieldsValue({
                communeId: undefined
            })
        } else if (Object.keys(changedValues)[0] === "dataCampaignId") {
            setDataCampaignId(changedValues.dataCampaignId);
        } else if (Object.keys(changedValues)[0] === "dataNotCampaign") {
            form.setFieldsValue({
                dataNotCampaign: changedValues.dataNotCampaign ? true : undefined
            })
        }
    }

    const resetFormData = () => {
        form.resetFields();
        setDataCampaignId(undefined);
    }

    async function fetchFileExcelList(page: number, keyword?: string): Promise<DefaultOptionType[]> {
        return fileExcelTrigger({ page, searchParam: { fileName: keyword }, size: 10, isCollapse: 1 }).unwrap().then(res => {
            let newOptions: DefaultOptionType[] = [];
            res.content.forEach((item: any) => {
                newOptions.push({
                    label: item.fileAttachDocument.fileName,
                    value: item.fileAttachDocument.fileName
                })
            })
            return newOptions;
        })
    }

    const filterFileExcel = (files: any) => {
        let listExcel: DataFile[] = [];
        if (files && files.length) {
            files.map((item: any) => {
                if (!listExcel.find(x => x.fileAttachDocument.fileName === item.fileAttachDocument.fileName)) {
                    listExcel = [...listExcel, item];
                }
            })
        }
        return listExcel;
    }
    const [isSearch, setIsSearch] = useState(false);
    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>Dữ liệu hộ tịch</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            <div className='title-label' style={{ marginBottom: 20 }}>Danh sách dữ liệu hộ tịch</div>

            <div className='content-box' style={{ marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChanged}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>

                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Loại dữ liệu"
                                    name="typeId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại dữ liệu"
                                        optionFilterProp='label'
                                        options={Object.keys(TYPE_DATA).map(item => ({
                                            label: TYPE_DATA[item],
                                            value: item
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Số lượt kiểm tra"
                                    name="numberCheck"
                                >
                                    <InputNumber placeholder="Nhập số lượt kiểm tra" min={0} style={{ minWidth: '100%' }} precision={0} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="File đăng tải (excel)"
                                    name="fileName"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn file excel tương ứng"
                                        optionFilterProp='label'
                                        options={(filterFileExcel(responseFileAllExcel?.data) || []).map((item: any) => ({
                                            label: item.fileAttachDocument.fileName,
                                            value: item.fileAttachDocument.fileName
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                    {/* <LazyLoadSelect
                                    loadData={(page, keyword) => fileExcelTrigger({ page, searchParam: { fileName: keyword }, size: 10, isCollapse: 1 })}
                                    placeholder="--Tất cả--"
                                    allowClear
                                    showSearch
                                    optionFilterProp="label"
                                    fetchOptions={fetchFileExcelList}
                                    options={fileExcelData}
                                    setOptions={setFileExcelData}
                                /> */}
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Tỉnh/Thành phố"
                                    name="provinceId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn tỉnh thành"
                                        optionFilterProp='label'
                                        options={(responseProvince?.data || []).map(item => ({
                                            label: item.provinceName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Quận/Huyện"
                                    name="districtId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn quận huyện"
                                        optionFilterProp='label'
                                        options={(districts || []).map(item => ({
                                            label: item.districtName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Phường/Xã"
                                    name="communeId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn phường xã"
                                        options={(communes || []).map(item => ({
                                            label: item.communeName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Dữ liệu tự do"
                                    name="dataNotCampaign"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Dữ liệu đang không phân công kiểm tra</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Trạng thái file pdf"
                                    name="filePdfExit"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn trạng thái file pdf"
                                        optionFilterProp='label'
                                        options={([{ id: 1, name: "Tồn tại" }, { id: 2, name: "Không tồn tại" }, { id: 3, name: "Chưa kiểm tra" }]).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Chiến dịch"
                                    name="dataCampaignId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn chiến dịch"
                                        optionFilterProp='label'
                                        options={(responseCampaign?.data || []).map(item => ({
                                            label: item.campaignName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            {dataCampaignId ? <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Trạng thái"
                                    name="isCheck"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn trạng thái"
                                        optionFilterProp='label'
                                        options={([{ id: 1, name: "Đã kiểm tra" }, { id: 2, name: "Chưa kiểm tra" }]).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col> : <></>}
                        </Row>
                        <div className='search-box-button'>
                            <Button htmlType='button' onClick={() => resetFormData()}>
                                Xóa điều kiện
                            </Button>
                            <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                        </div>
                    </> : <></>}

                </Form>
            </div>
            <RegisterInfoList form={form} searchParam={searchParam} triggerCampaign={() => triggerCampaign({})} />
        </>
    )
}

export default RegisterInfo;
