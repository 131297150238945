import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign, CommonListQuery } from '../../../redux/api/apiTypes';
import { DataInfoInitParams } from './apiTypes';

export const dataSyncListApi = createApi({
    reducerPath: 'dataInitApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getDataInfoInit: builder.query<any, { searchParam?: DataInfoInitParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init-compare/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
                params: {
                    page: body.page || 0,
                    size: body.size || 10
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getCountDataInfoInit: builder.query<number, { searchParam?: DataInfoInitParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init-compare/getPage-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),

    })
});

export const {
    useLazyGetDataInfoInitQuery,
    useLazyGetCountDataInfoInitQuery,
} = dataSyncListApi;
export default dataSyncListApi;
