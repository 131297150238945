import { Button, Col, Dropdown, Form, FormInstance, Input, Menu, Modal, notification, Pagination, Row, Table, Tag } from "antd";
import { ColumnsType } from "antd/es/table";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Action } from '../../../assets/logos/action.svg';
import { ReactComponent as Delete } from '../../../assets/logos/delete.svg';
import { ReactComponent as Lock } from '../../../assets/logos/lock-dots.svg';
import { ReactComponent as Unlock } from '../../../assets/logos/unlock.svg';
import { useChangeUserPasswordMutation, useDeleteUserMutation, useEditUserMutation, useLazyGetByIdQuery, useLazyGetUserQuery } from "../redux/userApi";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { endOfDay, startOfDay } from "date-fns";
import ConfirmModalCustom from "../../common/components/ConfirmModalCustom";
import { checkPermission } from "../../common/assets/CommonFunctions";

interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    createdDate?: string,
    username: string,
    fullName: string,
    email: string,
    phoneNumber: string,
    address: string,
    password: string,
    status: number,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}


const pattern = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
const patternMessage = "Nhập mật khẩu tối thiểu 8 ký tự bao gồm chữ hoa, chữ thường, số và ký tự đặc biệt.";

const UserList = ({ form, searchParam }: Props) => {
    const [changePassordForm] = Form.useForm();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const [currentUser, setCurrentUser] = useState<DataListTable>();
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();
    const [createUserVisible, setCreateUserVisible] = useState(false);
    const [deleteUserVisible, setDeleteUserVisible] = useState(false)
    const [idUser, setIdUser] = useState();
    const [activateUserVisible, setActivateUserVisible] = useState<boolean>(false);
    const [loadingBtnSubmit, setLoadingBtnSubmit] = useState<boolean>(false);
    const [changePasswordVisible, setChangePasswordVisible] = useState<boolean>(false);
    const [oldPassWord, setOldPassWord] = useState("");
    const [password, setPassword] = useState(0);
    const [rePassWordCount, setRePassWordCount] = useState(0);

    const [editUser] = useEditUserMutation();
    const [detailUserTrigger] = useLazyGetByIdQuery();
    const [triggerDeleteUser] = useDeleteUserMutation();
    const [triggerUser, responseUser] = useLazyGetUserQuery();
    const [changePass] = useChangeUserPasswordMutation();

    const canDelete = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.XOA)
    const canAddNew = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.THEM_MOI)
    const canEdit = checkPermission(PERMISSION_KEYS.QUAN_TRI_HE_THONG.NGUOI_DUNG.SUA)

    useEffect(() => {
        getData();
    }, [searchParam]);

    useEffect(() => {
        if (responseUser?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseUser.data.content.forEach((item, index) => {
                newTableData.push({
                    key: item.id || "",
                    index: index + 1,
                    createBy: item.createBy,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    username: item.username || "",
                    fullName: item.fullName || "",
                    email: item.email || "",
                    phoneNumber: item.phoneNumber || "",
                    address: item.address || "",
                    password: item.password || "",
                    status: item.status || 0,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseUser?.data?.content]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: 'Xử lý',
            key: 'action',
            align: "center",
            render: ((text, record) => (
                <Dropdown overlay={getContent(text, record)}>
                    <Action onClick={(e) => e.stopPropagation()} />
                </Dropdown>
            )
            ),
        },
        {
            title: "Tên đăng nhập",
            dataIndex: "username",
            key: "username",
        },
        {
            title: "Họ tên",
            dataIndex: "fullName",
            key: "fullName",
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
        },
        {
            title: "SĐT",
            dataIndex: "phoneNumber",
            key: "phoneNumber",
        },
        {
            title: "Trạng thái",
            dataIndex: "status",
            key: "status",
            render: (tag) => (
                <Tag style={{ border: 'none' }} className={`tag-status${!tag ? "_active" : "_err"}`}>
                    {!tag ? "Hoạt động" : "Không hoạt động"}
                </Tag>
            ),
            align: "center",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];
    const deleteUserToggle = (record: any) => {
        setIdUser(record.key)
        setDeleteUserVisible(!deleteUserVisible)
        // triggerDeleteUser([record.key]).unwrap().then(res => {
        //     notification.success({
        //         message: 'Xóa người dùng thành công.'
        //     })
        // })
        // getData()
        // onSubmitCheckData(record)
    }

    const getContent = (text: string, record: any) => {
        return (
            <div onClick={(e) => e.stopPropagation()}>
                <Menu
                    className="menu-actions"
                    style={{ padding: "8px", width: "140px" }}
                >
                    {canDelete && <Menu.Item key="delete" onClick={() => deleteUserToggle(record)}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Delete style={{ width: 24, height: 24, marginRight: 10 }} />Xóa
                        </div>
                    </Menu.Item>}
                    {record.status ? (
                        <Menu.Item key="unlock">
                            <div
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => activateUserToggle(record)}
                            >
                                <Unlock style={{ width: 24, height: 24, marginRight: 10 }} />
                                Kích hoạt
                            </div>
                        </Menu.Item>
                    ) : (
                        <Menu.Item key="lock">
                            <div
                                style={{ display: 'flex', alignItems: 'center' }}
                                onClick={() => activateUserToggle(record)}
                            >
                                <Lock style={{ width: 24, height: 24, marginRight: 10 }} />Khóa
                            </div>
                        </Menu.Item>
                    )}
                    {canEdit ? <Menu.Item key="change-password" onClick={() => changeUserPasswordToggle(record)}>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            Đổi mật khẩu
                        </div>
                    </Menu.Item> : <></>}
                </Menu >
            </div >
        )
    }

    const getData = () => {
        const submitValues = { ...searchParam }
        if (searchParam.startWorkDate) {
            submitValues.startWorkDateFrom = Math.floor(startOfDay(searchParam.startWorkDate[0]).getTime() / 1000);
            submitValues.startWorkDateTo = Math.floor(endOfDay(searchParam.startWorkDate[1]).getTime() / 1000);
            submitValues.startWorkDate = undefined
        }

        triggerUser({
            searchParam: submitValues,
            page: 0,
            size: pageSizeRef.current
        });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerUser({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
    }

    // const toggleModal = () => {
    //     if (createUserVisible) {
    //         setCurrentUser(undefined);
    //     }
    //     setCreateUserVisible(!createUserVisible);
    // }

    // const createUserSuccess = () => {
    //     toggleModal();
    //     getData();
    // }

    // const viewUsers = (e: any, record: DataListTable) => {
    //     e.stopPropagation();
    //     navigate(`/data/list`, { replace: true, state: { dataUserId: record.key } });
    // }

    // const viewDetailUser = (current: DataListTable) => {
    //     setCurrentUser(current);
    //     navigate('/setting/user/add', { state: { currentUser: current } })
    // }

    // kich hoat, khoa
    const activateUserToggle = (record: any) => {
        setActivateUserVisible(!activateUserVisible)
        setCurrentUser(record)
    }

    const onActivate = () => {
        if (currentUser) {
            detailUserTrigger(currentUser.key).unwrap()
                .then((res) => {
                    setLoadingBtnSubmit(true);
                    editUser({
                        id: currentUser.key,

                        fullName: res.fullName?.trim() || "",
                        phoneNumber: res.phoneNumber?.trim() || "",
                        email: res.email?.trim() || "",
                        address: res.address?.trim() || "",
                        birthday: res?.birthday || "",
                        gender: res.gender,
                        educationLevel: res.educationLevel?.trim() || "",
                        startWorkDate: res?.startWorkDate || "",
                        workType: res.workType || 0,
                        warningCount: res.warningCount,
                        rewardCount: res.rewardCount,

                        username: res.username?.trim() || "",
                        password: res.password?.trim() || "",
                        roles: res.roles,

                        status: currentUser.status ? 0 : 1,
                    }).unwrap()
                        .then(res => {
                            notification.success({
                                message: `${currentUser.status ? 'Kích hoạt' : 'Khoá'} Người dùng thành công.`
                            })
                            setActivateUserVisible(false);
                            setCurrentUser(undefined);
                            setPage(1);
                            form.resetFields();
                            setLoadingBtnSubmit(false);
                            triggerUser({ size: pageSizeRef.current, page: 0 });
                        }).catch(err => {
                            notification.error({
                                message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                            });
                            setActivateUserVisible(false);
                            setLoadingBtnSubmit(false);
                        })
                })
        }
    }


    // xoa
    const onSubmitCheckData = () => {
        triggerDeleteUser([idUser]).unwrap().then(() => {
            notification.success({
                message: 'Xóa tài khoản người dùng thành công.'
            })
            setLoadingBtnSubmit(false);
            getData()
        })
            .catch(err => {
                notification.error({
                    message: err.data?.message || "Đã có lỗi xảy ra. Vui lòng thử lại sau!"
                });
                setLoadingBtnSubmit(false);
            })
        setDeleteUserVisible(false)
    }

    // reset All state
    const resetState = () => {
        setCurrentUser(undefined);
        setDeleteUserVisible(false);
        setActivateUserVisible(false);
        setChangePasswordVisible(false);
    }


    // check re-password
    const changeUserPasswordToggle = (record: any) => {
        setCurrentUser(record);
        setChangePasswordVisible(!changePasswordVisible);
    }

    const onValuesChange = (values: any) => {
        if (Object.keys(values)?.[0] === "oldPassWord") {
            setOldPassWord(values.oldPassWord);
        }
        if (Object.keys(values)?.[0] === "password") {
            setPassword(values.password?.length || 0);
        }
        if (Object.keys(values)?.[0] === "rePassWord") {
            setRePassWordCount(values.rePassWord?.length || 0);
        }
    }

    const onFinishChangePassword = (values: any) => {
        if (currentUser) {
            changePass({ ...values, userId: currentUser.key }).unwrap()
                .then(res => {
                    changePassordForm.resetFields();
                    notification.success({
                        message: "Thay đổi mật khẩu thành công!",
                    })
                    resetState();
                }).catch(err => {
                    notification.error({
                        message: err.data?.message || "Thay đổi mật khẩu thất bại!"
                    })
                })
        }
    }


    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                </Col>
                <div className='search-box-button'>
                    {canAddNew && <Button
                        htmlType="button"
                        type="primary"
                        onClick={() => navigate('/setting/user/add')}
                    >Thêm mới</Button>
                    }
                </div>
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => navigate(`/setting/user/detail/${record.key}`),
                    };
                }}
                loading={responseUser?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />
            {/* <Spin spinning={responseCountDataInfoInit?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountDataInfoInit?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin> */}
            <Pagination
                total={responseUser?.data?.totalElements || 0}
                defaultPageSize={pageSizeRef.current}
                defaultCurrent={1}
                locale={{ items_per_page: ' dòng' }}
                pageSizeOptions={[10, 20, 50, 100]}
                onChange={changePage}
                showSizeChanger
                current={page}
            />

            {deleteUserVisible ?
                <ConfirmModalCustom
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    title="Xóa Người dùng"
                    content="Bạn có chắc chắn muốn xóa Người dùng này hay không?"
                    onCancel={() => setDeleteUserVisible(false)}
                    onOk={onSubmitCheckData}
                    type='error'
                    visible={deleteUserVisible}
                    showCancel={true}
                    showOk={true}
                    loadingBtnOk={loadingBtnSubmit}
                /> : <></>}

            {activateUserVisible && (
                <ConfirmModalCustom
                    onCancel={resetState}
                    cancelText="Huỷ bỏ"
                    okText="Xác nhận"
                    showCancel={true}
                    showOk={true}
                    type={currentUser?.status === 0 ? 'warning' : 'success'}
                    title={currentUser?.status === 0 ? "Khóa Người dùng" : "Kích hoạt Người dùng"}
                    content={currentUser?.status === 0 ?
                        "Bạn có chắc chắn muốn khóa Người dùng này hay không?" :
                        "Bạn có chắc chắn muốn kích hoạt Người dùng này hay không?"
                    }
                    visible={activateUserVisible}
                    onOk={onActivate}
                    loadingBtnOk={loadingBtnSubmit}
                />
            )}

            {changePasswordVisible ? (
                <Modal
                    visible={changePasswordVisible}
                    onOk={() => changePassordForm.submit()}
                    onCancel={resetState}
                    style={{
                        minWidth: 424,
                    }}
                    bodyStyle={{
                        maxHeight: 500,
                        overflow: 'auto'
                    }}
                    title='Đổi mật khẩu'
                    width={'40%'}
                    footer={false}
                >
                    <Form
                        name="changePassword"
                        onFinish={onFinishChangePassword}
                        //   onFinishFailed={onFinishFailed}
                        autoComplete="off"
                        layout="horizontal"
                        form={changePassordForm}
                        labelWrap
                        labelAlign='left'
                        // className="custom-form"
                        labelCol={{ span: 7 }}
                        wrapperCol={{ span: 17 }}
                        onValuesChange={onValuesChange}
                    >
                        {/* <Row gutter={16} align='middle' justify="center">
                            <Col span={24}>
                                <Row justify="end"><span>{oldPassWord?.length || 0} / 20</span></Row>
                                <Form.Item
                                    label="Mật khẩu hiển tại"
                                    name="oldPassWord"
                                    rules={[
                                        { required: true, message: "Mật khẩu hiện tại là bắt buộc, vui lòng nhập đầy đủ." }
                                    ]}
                                >
                                    <Input.Password maxLength={20} />
                                </Form.Item>
                            </Col>
                        </Row> */}

                        <Row gutter={16} align='middle' justify="center">
                            <Col span={24}>
                                <Row justify="end"><span>{password} / 20</span></Row>
                                <Form.Item
                                    label="Mật khẩu mới"
                                    name="password"
                                    rules={[
                                        { required: true, message: "Mật khẩu mới là bắt buộc, vui lòng nhập đầy đủ." },
                                        { pattern, message: patternMessage },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (value === oldPassWord) {
                                                    return Promise.reject(new Error("Mật khẩu mới phải khác mật khẩu cũ gần nhất."));
                                                }
                                                return Promise.resolve();
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password maxLength={20} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16} align='middle' justify="center">
                            <Col span={24}>
                                <Row justify="end"><span>{rePassWordCount} / 20</span></Row>
                                <Form.Item
                                    label="Nhập lại mật khẩu mới"
                                    name="rePassWord"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Vui lòng nhập lại mật khẩu mới!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || getFieldValue('password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('Mật khẩu không trùng với mật khẩu mới!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password maxLength={20} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row align="middle" justify="end">
                            <Col>
                                <Button htmlType='submit' type='primary'>Đổi mật khẩu</Button>
                            </Col>
                            <Col>
                                <Button htmlType='submit' type='default'>Đặt mật khẩu về mặc định</Button>
                            </Col>
                            <Col>
                                <Button htmlType='submit' type='default' onClick={resetState} danger>Huỷ</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            )
                : <></>}
        </div>

    )
}

export default UserList;