import { Row, Menu, Table, Pagination, FormInstance, notification, Button, Col, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import { useAppSelector } from "../../../redux/Hooks";
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
import { useLazyGetCountDataInfoInitQuery, useLazyGetDataInfoInitQuery } from "../redux/dataSyncListApi";
interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    createdDate?: string,
    dataInfoInitId: any,
    so: any,
    ngayDangKy?: any,
    excelName?: string,
    numberRead?: any,
    status?: any,
    statusStr?: string,
    modifiedBy: string
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
    stateOld: any,
    triggerCampaign: () => void,
}

const DataSyncList = ({ form, searchParam, stateOld, triggerCampaign }: Props) => {
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [provinceObj, setProvinceObj] = useState();
    const [districtObj, setDistrictObj] = useState();
    const [communeObj, setCommuneObj] = useState();
    const userState = useAppSelector(state => state.user.user);
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [communeTrigger, responseCommune] = useLazyGetAllCommuneNoAuthQuery();
    const [districtTrigger, responseDistrict] = useLazyGetAllDistrictNoAuthQuery();
    const [triggerDataInfoInit, responseDataInfoInit] = useLazyGetDataInfoInitQuery();
    const [triggerCountDataInfoInit, responseCountDataInfoInit] = useLazyGetCountDataInfoInitQuery();

    useEffect(() => {
        triggerProvince({});
        districtTrigger({});
        communeTrigger({});
    }, [triggerProvince, districtTrigger, communeTrigger]);

    useEffect(() => {
        getData(false);
    }, [searchParam]);

    useEffect(() => {
        if (responseProvince) {
            setProvinceObj(responseProvince?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.provinceName }), {}))
        }
        if (responseDistrict) {
            setDistrictObj(responseDistrict?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.districtName }), {}))
        }
        if (responseCommune) {
            setCommuneObj(responseCommune?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.communeName }), {}))
        }
    }, [responseProvince, responseDistrict, responseCommune]);

    useEffect(() => {
        if (responseDataInfoInit?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseDataInfoInit.data.content.forEach((item: any, index: any) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    createBy: item.createBy,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    dataInfoInitId: item.dataInfoInitId,
                    so: item.so,
                    ngayDangKy: item.ngayDangKy,
                    excelName: item.excelName,
                    numberRead: item.numberRead,
                    status: item.numberRead,
                    statusStr: item.statusStr,
                    modifiedBy: item.modifiedBy
                })
            });
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseDataInfoInit]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Tên file excel",
            dataIndex: "excelName",
            key: "excelName",
        },
        {
            title: "Số",
            dataIndex: "so",
            key: "so",
        },
        {
            title: "Ngày đăng ký",
            dataIndex: "ngayDangKy",
            key: "address",
        },
        {
            title: "Người chỉnh sửa",
            dataIndex: "modifiedBy",
            key: "modifiedBy",
        },
        {
            title: "Trạng thái",
            dataIndex: "statusStr",
            key: "statusStr",
        },
    ];

    const getData = (getCurrentPage: boolean) => {
        triggerDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
            size: stateOld?.size ? stateOld.size : pageSizeRef.current
        });
        triggerCountDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
            size: stateOld?.size ? stateOld.size : pageSizeRef.current
        })

        if (stateOld?.page || stateOld?.size) {
            if (stateOld?.page) setPage(stateOld?.page);
            if (stateOld?.size) pageSizeRef.current = stateOld?.size;
        } else if (!getCurrentPage) {
            setPage(1);
        }
    }

    const openDetailModal = (e: any, item: DataListTable) => {
        navigate(`/data/info_init_detail/${item.key}`, { replace: true, state: { searchParam, page, size: pageSizeRef.current } });
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerCountDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        navigate(".", { replace: true, state: { searchParam, page: newPage, size: pageSize } });
    }

    // const checkPermission = (path: string) => {
    //     if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
    //     return false;
    // }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                {/* <Col span={6}>
                </Col> */}
                <div>Tổng số: <strong>{responseCountDataInfoInit?.data}</strong> bản ghi</div>

                <div className='search-box-button'>
                </div>
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => openDetailModal(event, record), // click row
                        onDoubleClick: event => openDetailModal(event, record), // double click row
                    };
                }}
                loading={responseDataInfoInit?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseCountDataInfoInit?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountDataInfoInit?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>
        </div>
    )
}

export default DataSyncList;
