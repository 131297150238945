import { Button, Checkbox, Form, Input, InputNumber, Modal, Select, Table } from "antd"
import React, { useState, useRef } from "react"
import type { DraggableData, DraggableEvent } from 'react-draggable';
import Draggable from 'react-draggable';

type Props = {
    visible: boolean,
    toggleModal: () => void,
    columns: any,
    tableData: any
}

const ListDataInfoInitModal = ({ visible, toggleModal, columns, tableData }: Props) => {
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [disabled, setDisabled] = useState(false);
    const draggleRef = useRef<HTMLDivElement>(null);
    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    return (
        <Modal
            className="drag-drop-modal"
            visible={visible}
            mask={false}
            title={<div
                style={{
                    width: '100%',
                    cursor: 'move',
                }}
                onMouseOver={() => {
                    if (disabled) {
                        setDisabled(false);
                    }
                }}
                onMouseOut={() => {
                    setDisabled(true);
                }}
                // fix eslintjsx-a11y/mouse-events-have-key-events
                // https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/mouse-events-have-key-events.md
                onFocus={() => { }}
                onBlur={() => { }}
            // end
            >
                Danh sách THHT đã nhập
            </div>}
            onCancel={() => toggleModal()}
            width={800}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={toggleModal}>
                        Đóng
                    </Button>
                </React.Fragment>
            ]}
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}>
            <Table<any>
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content", y: 400 }}
            />
        </Modal>
    )
}

export default ListDataInfoInitModal;