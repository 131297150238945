import { Breadcrumb, Button, Checkbox, Col, Form, Input, Row, Select } from "antd";
import { ReactComponent as Home } from '../../assets/logos/home.svg';
import { useEffect, useState } from "react";
import FilePdfList from "./components/FilePdfList";
import {
    useLazyGetAllCampaignQuery,
    useLazyGetCommuneQuery,
    useLazyGetDistrictQuery,
    useLazyGetProvinceQuery,
    useLazyGetUserCheckDataQuery
} from "../../redux/api/commonApi";
import { endOfMonth, endOfYear, startOfMonth, startOfYear, subMonths, subYears } from "date-fns";
import { DatePicker } from "../../custom-components";
import { Commune, District } from "../../redux/api/apiTypes";
import { useLazyGetAllProvinceNoAuthQuery } from "../category/province/redux/provinceApi";
import { ReactComponent as ZoomOut } from '../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../assets/logos/zoom-in-line.svg';

const { RangePicker } = DatePicker;

const RegisterInfo = () => {
    const [form] = Form.useForm();
    const [searchParam, setSearchParam] = useState({});
    const [isCheck, setIsCheck] = useState();
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [triggerCampaign, responseCampaign] = useLazyGetAllCampaignQuery();

    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [provinceObject, setProvinceObject] = useState();
    const [districtObject, setDistrictObject] = useState();
    const [communeObject, setCommuneObject] = useState();

    useEffect(() => {
        triggerProvince({});
        triggerUserCheck({});
    }, [triggerUserCheck, triggerProvince])

    const onFinish = (values: any) => {
        console.log(values);
        setSearchParam(values);
    }

    const resetFormData = () => {
        form.resetFields();
    }

    const onValuesChange = (values: any) => {
        if (Object.keys(values)[0] === "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (values.provinceId) {
                districtTrigger(values.provinceId).unwrap()
                    .then((res: any) => {
                        setDistricts(res);
                        setDistrictObject(res?.reduce((a: any, v: any) => ({ ...a, [v.districtCode]: v.id }), {}));
                    });
            }
            form.setFieldsValue({
                districtCode: undefined,
                communeCode: undefined,
            })

        } else if (Object.keys(values)[0] === "districtCode") {
            setCommunes([]);
            if (values.districtCode) {
                communeTrigger(districtObject?.[values.districtCode]).unwrap()
                    .then((res: any) => {
                        setCommunes(res);
                        setCommuneObject(res?.reduce((a: any, v: any) => ({ ...a, [v.communeCode]: v.id }), {}));
                    });
            }
            form.setFieldsValue({
                communeCode: undefined
            })
        }
        if (Object.keys(values)?.[0] === "isCheck") {
            setIsCheck(values?.isCheck);
            if (values.isCheck === 1) {
                form.setFieldsValue({ numberCheck: 0 });
            } else {
                form.setFieldsValue({ numberCheck: undefined });
            }
        }
        if (Object.keys(values)?.[0] === "numberCheck") {
            setIsCheck(undefined);
            if ((values.numberCheck === 0 && isCheck !== 1) || (values.numberCheck !== 0 && isCheck === 1)) {
                form.setFieldsValue({ isCheck: undefined });
                setIsCheck(undefined);
            }
        }
    }
    const [isSearch, setIsSearch] = useState(false);

    return (
        <>
            {/*<Breadcrumb separator="" style={{ marginBottom: 20, marginLeft: 20, marginTop: 20 }}>*/}
            {/*    <Breadcrumb.Item href="/home">*/}
            {/*        <Home style={{ width: 14, height: 14, marginRight: 20 }} /> Trang chủ*/}
            {/*    </Breadcrumb.Item>*/}
            {/*    <Breadcrumb.Separator> {'>'} </Breadcrumb.Separator>*/}
            {/*    <Breadcrumb.Item href="">*/}
            {/*        <span>File pdf</span>*/}
            {/*    </Breadcrumb.Item>*/}
            {/*</Breadcrumb>*/}

            {/* <div className='title-label' style={{ marginBottom: 20 }}>Danh sách file pdf</div> */}

            <div className='content-box' style={{ margin: "0px 20px", padding: "20px", marginBottom: 30 }}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    layout="vertical"
                    form={form}
                    labelWrap
                    labelAlign="left"
                    colon={false}
                    onValuesChange={onValuesChange}
                >
                    <div style={{ cursor: 'pointer' }} onClick={() => setIsSearch(!isSearch)}>
                        {isSearch ? <ZoomOut style={{ width: 20, paddingTop: 8 }} /> : <ZoomIn style={{ width: 20, paddingTop: 8 }} />}
                        <label className='search-title' style={{ cursor: 'pointer' }}>TÌM KIẾM</label>
                    </div>
                    {isSearch ? <>
                        <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }} >
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Thời gian đăng tải"
                                    name="uploadDates"
                                >
                                    <RangePicker
                                        format="DD/MM/Y"
                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                        ranges={{
                                            'Hôm nay': [new Date(), new Date()],
                                            'Tháng này': [startOfMonth(new Date()), new Date()],
                                            'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                            '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                            '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                            'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                            'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Thời gian hoàn thành"
                                    name="completeDates"
                                >
                                    <RangePicker
                                        format="DD/MM/Y"
                                        placeholder={["Bắt đầu", "Kết thúc"]}
                                        ranges={{
                                            'Hôm nay': [new Date(), new Date()],
                                            'Tháng này': [startOfMonth(new Date()), new Date()],
                                            'Tháng trước': [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))],
                                            '3 tháng gần nhất': [startOfMonth(subMonths(new Date(), 2)), new Date()],
                                            '6 tháng gần nhất': [startOfMonth(subMonths(new Date(), 5)), new Date()],
                                            'Năm nay': [startOfYear(subMonths(new Date(), 5)), new Date()],
                                            'Năm trước': [startOfYear(subYears(new Date(), 1)), endOfYear(subYears(new Date(), 1))],
                                        }}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Tên file"
                                    name="fileName"
                                >
                                    <Input placeholder="Nhập tên file" style={{ minWidth: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={8} style={{ display: "flex" }}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Năm"
                                    name="fromYear"
                                >
                                    <Input placeholder="Từ" style={{ minWidth: '50%' }} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: "30px 10px 10px", }}
                                    name="toYear"
                                >
                                    <Input placeholder="Đến" style={{ minWidth: '50%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Tỉnh/Thành phố"
                                    name="provinceId"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn tỉnh thành"
                                        optionFilterProp='label'
                                        options={(responseProvince?.data || []).map((item: any) => ({
                                            label: item.provinceName,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Quận/Huyện"
                                    name="districtCode"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn quận huyện"
                                        optionFilterProp='label'
                                        options={(districts || []).map(item => ({
                                            label: item.districtName,
                                            value: item.districtCode
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Phường/Xã"
                                    name="communeCode"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn phường xã"
                                        options={(communes || []).map(item => ({
                                            label: item.communeName,
                                            value: item.communeCode
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Trạng thái"
                                    name="isCheck"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn trạng thái"
                                        optionFilterProp='label'
                                        options={([
                                            { id: 1, name: "Chưa nhập" },
                                            { id: 2, name: "Đã nhập" },
                                            { id: 3, name: "Đã nhập xong" },
                                        ]).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Nhập bổ sung"
                                    name="isLack"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn trạng thái nhập bổ sung"
                                        optionFilterProp='label'
                                        options={([
                                            { id: 1, name: "Chưa nhập bổ sung" },
                                            { id: 2, name: "Đã nhập bổ sung" },
                                        ]).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8} style={{ display: "flex" }}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Số THHT"
                                    name="numberCheckFrom"
                                >
                                    <Input disabled={isCheck === 1} placeholder="Từ" style={{ minWidth: '50%' }} />
                                </Form.Item>
                                <Form.Item
                                    style={{ margin: "30px 10px 10px", }}
                                    name="numberCheckTo"
                                >
                                    <Input disabled={isCheck === 1} placeholder="Đến" style={{ minWidth: '50%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Người tạo"
                                    name="createdBy"
                                >
                                    <Input placeholder="Nhập tài khoản người tạo" style={{ minWidth: '100%' }} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Dữ liệu báo lỗi"
                                    name="status"
                                    valuePropName="checked"
                                >
                                    <Checkbox>Dữ liệu báo lỗi</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={8} xs={24} md={12} lg={8}>
                                <Form.Item
                                    style={{ marginBottom: 10 }}
                                    label="Kích thước sổ"
                                    name="paperSize"
                                >
                                    <Select
                                        allowClear
                                        placeholder="Chọn loại kích thước sổ"
                                        optionFilterProp='label'
                                        options={([
                                            { id: "All", name: "All" },
                                            { id: "A4", name: "A4" },
                                            { id: "A3", name: "A3" },
                                            { id: "0", name: "Chưa phân loại" },
                                        ]).map(item => ({
                                            label: item.name,
                                            value: item.id
                                        }))}
                                        showSearch
                                        showArrow
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <div className='search-box-button'>
                            <Button htmlType='button' onClick={() => { resetFormData(); setIsCheck(undefined) }}>
                                Xóa điều kiện
                            </Button>
                            <Button htmlType='submit' type='primary'>Tìm kiếm</Button>
                        </div>
                    </> : <></>}
                </Form>
            </div>
            <FilePdfList form={form} searchParam={searchParam} triggerCampaign={() => triggerCampaign({})} />
        </>
    )
}

export default RegisterInfo;
