import React, { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { isTokenExpired } from "../modules/common/assets/CommonFunctions";
import { useLazyGetUserInfoQuery } from "../redux/api/AccountApi";
import { useAppDispatch, useAppSelector } from "../redux/Hooks";
import { setUser } from '../modules/login/redux/UserSlice';

function PrivateRoute({ children, hasPermissions }: { children: JSX.Element, hasPermissions?: boolean }) {
    const isLogin = !isTokenExpired(localStorage.getItem("accessToken") || "")
    const user = useAppSelector((state) => state.user);
    const dispatch = useAppDispatch();
    const getProfile = useRef(false);

    let location = useLocation();

    const [trigger] = useLazyGetUserInfoQuery();

    useEffect(() => {
        // if (!Object.keys(user.user)?.length && isLogin) {
        if (isLogin && !getProfile.current && user.user && Object.keys(user.user).length) {
            getProfile.current = true;
            // trigger({}).unwrap().then(res => {
            //     dispatch(setUser(res))
            // })
            setUser(user.user);
        } else if (!isLogin) {
            dispatch(setUser({}))
        }
    }, [user.user, isLogin, trigger, dispatch])

    if (!isLogin) {
        return <Navigate to="/login" replace state={{ from: location }} />
    }
    if (isLogin && hasPermissions === false && user.user?.username !== "admin") {
        return <Navigate to="/" replace state={{ from: location }} />
    }

    return children;
}

export default PrivateRoute;
