import { Col, Row, Table, Pagination, FormInstance, Button, Spin, notification } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect, useState, useRef } from "react";
import { useLazyGetImportReportQuery, useLazyGetImportReportCountQuery, useExportDataMutation } from "../redux/importReportApi";

interface DataListTable {
    key: number,
    index: number,
    date: string,
    account: string,
    type: string,
    count: number,
    total_input_field: number,
    total_edited_field: number,
    total_checked_field: number,
    total_un_checked_field: number,
    check_complete: boolean,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
}

const ReportList = ({ form, searchParam }: Props) => {
    const [triggerReport, responseReport] = useLazyGetImportReportQuery();
    const [triggerReportCount, responseReportCount] = useLazyGetImportReportCountQuery();
    const [triggerExport] = useExportDataMutation();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const [loading, setLoading] = useState(false);
    
    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "Ngày",
            dataIndex: "date",
            key: "date",
        },
        {
            title: "Tài khoản",
            dataIndex: "account",
            key: "account",
        },
        {
            title: "Loại",
            dataIndex: "type",
            key: "type",
        },
        {
            title: "Tổng số THHT",
            dataIndex: "count",
            key: "count",
        },
        {
            title: "Số trường nhập liệu",
            dataIndex: "total_input_field",
            key: "total_input_field",
        },
        {
            title: "Số lượng chưa check",
            dataIndex: "total_un_checked_field",
            key: "total_un_checked_field",
        },
        {
            title: "Chưa check xong",
            dataIndex: "check_complete",
            key: "check_complete",
            render: (value, record, index) => value ? "Đã check xong" : "Chưa check"
        },
        {
            title: "Số THHT đã kiểm tra",
            dataIndex: "total_checked_field",
            key: "total_checked_field",
        },
        {
            title: "Số trường thông tin đã sửa",
            dataIndex: "total_edited_field",
            key: "total_edited_field",
        },
    ];

    useEffect(() => {
        getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchParam]);

    useEffect(() => {
        if (responseReport?.data?.length) {
            let newTableData: DataListTable[] = [];
            responseReport.data.forEach((item, index) => {
                newTableData.push({
                    key: index + 1,
                    index: index + 1,
                    date: item.date,
                    account: item.account,
                    type: item.type,
                    count: item.count,
                    total_input_field: item.total_input_field,
                    total_edited_field: item.total_edited_field,
                    total_checked_field: item.total_checked_field,
                    total_un_checked_field: item.total_un_checked_field,
                    check_complete: item.check_complete,
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseReport]);

    const getData = () => {
        triggerReport({
            searchParam,
            page: 0,
            size: pageSizeRef.current
        });
        triggerReportCount({ searchParam });
        setPage(1);
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReport({
            searchParam: searchParam ? {
                ...searchParam,
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerReportCount({ searchParam });
    }

    const onExportExcelClick = () => {
        setLoading(true);
        triggerExport({ searchParam })
            .unwrap()
            .then((res) => {
                setLoading(false);
            })
            .catch((err) => {
                setLoading(false);
                notification.error({ message: err.data?.message || "Có lỗi xảy ra!" })
            });
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}></Col>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                        onClick={onExportExcelClick}
                        loading={loading}
                    >Xuất excel</Button>
                </div>
            </Row>
            <Table<DataListTable>
                loading={responseReport?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseReportCount?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseReportCount?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>
        </div>
    )
}

export default ReportList;