import React, { useEffect, useMemo, useState, useRef } from "react"
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack5';
import { ReactComponent as ZoomOut } from '../../../assets/logos/zoom-out-line.svg';
import { ReactComponent as ZoomIn } from '../../../assets/logos/zoom-in-line.svg';
import { ReactComponent as Rotate } from '../../../assets/logos/rotate.svg';
import { useLazyGetFilePdfByNameQuery } from "../../data_init/redux/dataInitApi";
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import { Button, Modal } from "antd";
import { PDFPageProxy } from "react-pdf";
import { ReactZoomPanPinchRef, TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";

type Props = {
    visible: boolean,
    toggleModal: () => void,
    filePdf: any,
    indexCompare?: number
}

const ViewFilePdf = ({ visible, toggleModal, filePdf, indexCompare }: Props) => {
    const [scale, setScale] = useState(1);
    const [rotate, setRotate] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [triggerFilePdf, filePdfResponse] = useLazyGetFilePdfByNameQuery();

    const transformComponentRef = useRef<ReactZoomPanPinchRef>(null!);
    const canvas = useRef<any>();
    const [clientY, setClientY] = useState<number>(0);
    const [isRendered, setIsRendered] = useState<boolean>(false);
    const [isDrag, setIsDrag] = useState<boolean>(false);
    const prevScale = useRef<number>(1);
    useEffect(() => {
        if (clientY===0 || !canvas.current||!isRendered) {
            return;
        }
        const context = canvas.current.getContext('2d');
        const { width } = canvas.current;
        const rect = canvas.current.getBoundingClientRect();
        context.save();
        context.beginPath();
        context.moveTo(0, clientY);
        context.lineTo(width, clientY);
        context.strokeStyle = 'red';
        context.stroke();
        context.restore();
    }, [clientY, isRendered]);
    useEffect(()=>{
        if(prevScale.current===scale){
            return;
        } else {
            transformComponentRef.current.resetTransform(0);
            transformComponentRef.current.centerView(1, 0);
            setClientY((prevClientY)=>prevClientY*(scale / prevScale.current));
            setIsRendered(false);
        }
    },[scale])
    const  onRenderSuccess = (page: PDFPageProxy) => {
        setIsRendered(true);
    }
    const onClickPage = (event: React.MouseEvent, page: PDFPageProxy) => {
        if(!isDrag){
            const rect = canvas.current.getBoundingClientRect();
            const scaleY = canvas.current.height / rect.height;
            setClientY((event.clientY - rect.top)*scaleY);
        }
    }

    const file = useMemo(() => {
        return {
            url: process.env.REACT_APP_API_URL + `/api/v1/file/download-pdf/${filePdfResponse?.data?.[0]?.id}`,
            httpHeaders: {
                Authorization: 'Bearer ' + localStorage.getItem("accessToken"),
                "Content-Type": "application/json;charset=UTF-8",
            },
        }
    }, [filePdfResponse?.data?.[0]?.id]);
    useEffect(() => {
        getFilePdf();
    }, [triggerFilePdf])

    const getFilePdf = () => {
        // fileName:  KS.2005.01.2005-04-14.001.pdf
        // regex tìm kiếm các text chứa chữ A ở đầu và chữ B ở cuối có định dạng:    /^A.*B$/
        let currentFilePdf = (filePdf?.filePDFName || "").split(".");
        if (currentFilePdf.length) {
            //let indexFile = "00000".substr(0, (currentFilePdf[3] || "").length - ((parseInt(currentFilePdf[3] || '', 10) + (indexCompare || 0) + "").length)) + (parseInt(currentFilePdf[3] || '', 10) + (indexCompare || 0));
            let so: any = parseInt(currentFilePdf[4]);
            so = so + indexCompare;
            if (so <= 0) {
                so = 1;
            }
            if (so < 10) {
                so = '00' + so;
            } else if (so < 99) {
                so = '0' + so;
            }
            triggerFilePdf({
                districtCode: filePdf?.pathFile?.split("/")?.[0] || undefined,
                communeCode: filePdf?.pathFile?.split("/")?.[1] || undefined,
                fileName: ['^' + currentFilePdf[0], currentFilePdf[1], currentFilePdf[2], '.*', so].join('.')
            })
        }
    }
    const onDocumentLoadSuccess = ({ numPages }: any) => {
        setNumPages(numPages);
    }
    return (
        <Modal
            className="custom-modal"
            visible={visible}
            title={<div>
                <div className="header-block">
                    <label className='search-title'>
                        {indexCompare && indexCompare > 0 ? "File pdf kế sau: " : "File pdf liền trước: "}
                        {filePdfResponse?.data?.[0]?.fileName || ''}
                    </label>
                    <ZoomOut
                        onClick={() => setScale((prev:number)=>{ prevScale.current = prev; return scale === 1 ? 1 : scale - 1})}
                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    <ZoomIn
                        onClick={() => setScale((prev:number)=>{ prevScale.current = prev; return scale + 1})}
                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                    <Rotate
                        onClick={() => setRotate(rotate - 90)}
                        style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                </div>
            </div>}
            onCancel={() => toggleModal()}
            width={850}
            style={{ top: "10px" }}
            maskClosable={false}
            footer={[
                <React.Fragment key="footer">
                    <Button htmlType='button' onClick={toggleModal}>
                        Đóng
                    </Button>
                </React.Fragment>
            ]}>
            <div
                style={{
                    width: "100%",
                }}
                id="block-view-pdf"
            >
                <div style={{ overflowY: "auto", height: "calc(100vh - 160px)" }} >
                    <TransformWrapper
                        maxScale={3}
                        initialScale={1}
                        disablePadding={true}
                        ref={transformComponentRef}
                        onZoomStop={(ref: ReactZoomPanPinchRef, event: any) => {
                            if (ref.state.scale >= 3) {
                                setScale((prev:number) => { prevScale.current = prev; return prev + 1});
                            } else if (ref.state.scale <= 1) {
                                setScale((prev:number) => { prevScale.current = prev; return prev - 1 || 1});
                            }
                        }}
                    >
                        <TransformComponent>
                            <Document
                                file={file}
                                onLoadSuccess={onDocumentLoadSuccess}>
                                {Array.from(new Array(numPages), (el, index) => (
                                    <Page
                                        width={800}
                                        height={800}
                                        rotate={rotate}
                                        scale={scale}
                                        key={`page_${index + 1}`}
                                        pageNumber={index + 1}
                                        canvasRef={canvas}
                                        onRenderSuccess={onRenderSuccess}
                                        onClick={onClickPage}
                                    />
                                ))}
                            </Document>
                        </TransformComponent>
                    </TransformWrapper>
                </div>
            </div>
        </Modal>
    )
}

export default ViewFilePdf
