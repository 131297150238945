import { Row, Menu, Table, Pagination, FormInstance, notification, Button, Col, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { ReactComponent as Edit } from '../../../assets/logos/pen-edit.svg';
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLazyGetCountDataInfoInitQuery, useLazyGetDataInfoInitQuery } from "../redux/dataInitApi";
import moment from "moment";
import { DEFAULT_FIELD_CONFIG, TYPE_DATA } from "../../../assets/ts/constant";
import { useLazyGetCommuneAllQuery, useLazyGetCommuneQuery, useLazyGetDistrictAllQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery, useLazyGetUserCheckDataQuery } from "../../../redux/api/commonApi";
import { FieldConfig } from "../redux/apiTypes";
import CreateCampaign from "./CreateCampaign";
import { useAppSelector } from "../../../redux/Hooks";
import { PERMISSION_KEYS } from "../../permissions/ts/Contants";
import { checkPermission } from "../../common/assets/CommonFunctions"
import { useLazyGetAllProvinceNoAuthQuery } from "../../category/province/redux/provinceApi";
import { useLazyGetAllCommuneNoAuthQuery } from "../../category/commune/redux/communeApi";
import { useLazyGetAllDistrictNoAuthQuery } from "../../category/district/redux/districtApi";
interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    type?: string,
    createdDate?: string,
    fileName: string,
    fileName158: string,
    address?: string,
    excelName?: string,
    rowNumber?: string,
    message?: string,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
    stateOld: any,
    triggerCampaign: () => void,
}

const RegisterInfoList = ({ form, searchParam, stateOld, triggerCampaign }: Props) => {
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [provinceObj, setProvinceObj] = useState();
    const [districtObj, setDistrictObj] = useState();
    const [communeObj, setCommuneObj] = useState();
    const userState = useAppSelector(state => state.user.user);
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [triggerProvince, responseProvince] = useLazyGetAllProvinceNoAuthQuery();
    const [communeTrigger, responseCommune] = useLazyGetAllCommuneNoAuthQuery();
    const [districtTrigger, responseDistrict] = useLazyGetAllDistrictNoAuthQuery();
    const [triggerDataInfoInit, responseDataInfoInit] = useLazyGetDataInfoInitQuery();
    const [triggerCountDataInfoInit, responseCountDataInfoInit] = useLazyGetCountDataInfoInitQuery();
    const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
    const [triggerReportTotal, responseReportTotal] = useLazyGetCountDataInfoInitQuery();
    const [triggerUserCheck, responseUserCheck] = useLazyGetUserCheckDataQuery();


    const canAddCampaign = checkPermission(PERMISSION_KEYS.CHIEN_DICH_KIEM_TRA.THEM_MOI)

    useEffect(() => {
        triggerProvince({});
        districtTrigger({});
        communeTrigger({});
        triggerUserCheck({});
    }, [triggerProvince, districtTrigger, communeTrigger, triggerUserCheck]);

    useEffect(() => {
        getData(false);
    }, [searchParam]);

    useEffect(() => {
        if (responseProvince) {
            setProvinceObj(responseProvince?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.provinceName }), {}))
        }
        if (responseDistrict) {
            setDistrictObj(responseDistrict?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.districtName }), {}))
        }
        if (responseCommune) {
            setCommuneObj(responseCommune?.data?.reduce((a: any, v: any) => ({ ...a, [v.id]: v.communeName }), {}))
        }
    }, [responseProvince, responseDistrict, responseCommune]);

    useEffect(() => {
        if (responseDataInfoInit?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            // if(userState === 'admin'){

            // }
            responseDataInfoInit.data.content.forEach((item, index) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    type: item?.filePdf?.[0]?.fileName?.split(".")?.[0] ? TYPE_DATA[item?.filePdf?.[0]?.fileName?.split(".")?.[0]] : '',
                    createBy: item.createBy,
                    message: item.message,
                    rowNumber: item.rowNumber,
                    excelName: item.excelName,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    fileName: item?.filePdf?.[0]?.fileName || "",
                    fileName158: customFileFileName158(item, item?.filePdf?.[0]?.fileName?.split(".")?.[0] || ''),
                    address: customContent(item, item?.filePdf?.[0]?.fileName?.split(".")?.[0] || ''),
                })
            })
            // const newData = responseDataInfoInit?.data?.content.filter((item) => item?.modifiedBy === userState?.username)
            // console.log("abc",newData);
            
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseDataInfoInit]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        {
            title: "File pdf",
            dataIndex: "fileName",
            key: "fileName",
            ellipsis: true,
            width: 200,
        },
        {
            title: "File pdf 158",
            dataIndex: "fileName158",
            key: "fileName158",
            ellipsis: true,
            width: 200,
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Tên file excel",
            dataIndex: "excelName",
            key: "excelName",
        },
        {
            title: "Dòng",
            dataIndex: "rowNumber",
            key: "rowNumber",
        },
        {
            title: "Ghi chú",
            dataIndex: "message",
            key: "message",
        },
        {
            title: "Người tạo",
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: "Ngày tạo",
            dataIndex: "createdDate",
            key: "createdDate",
        },
    ];

    const getData = (getCurrentPage: boolean) => {
        triggerDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
            size: stateOld?.size ? stateOld.size : pageSizeRef.current
        });
        triggerCountDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: stateOld?.page ? stateOld.page - 1 : (getCurrentPage ? page - 1 : 0),
            size: stateOld?.size ? stateOld.size : pageSizeRef.current
        })

        triggerReportTotal({ searchParam });

        if (stateOld?.page || stateOld?.size) {
            if (stateOld?.page) setPage(stateOld?.page);
            if (stateOld?.size) pageSizeRef.current = stateOld?.size;
        } else if (!getCurrentPage) {
            setPage(1);
        }
    }

    const customFileFileName158 = (dataInfoInit: any, type: string) => {
        return dataInfoInit?.dataKeyMap?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v.dataValue }), {})?.[DEFAULT_FIELD_CONFIG[type]?.tenfilepdf];
    }

    const customContent = (dataInfoInit: any, type: string) => {
        let address = dataInfoInit?.dataKeyMap?.filter((item: any) => {
            if ((item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.tinhThanh ||
                item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.quanHuyen ||
                item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.phuongXa) && item.dataValue) return true;
            return false;
        }) || [];
        return address.map((item: FieldConfig) => {
            if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.tinhThanh && item.dataValue) return provinceObj?.[item.dataValue];
            if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.quanHuyen && item.dataValue) return districtObj?.[item.dataValue];
            if (item.dataKeyAscii === DEFAULT_FIELD_CONFIG[type]?.phuongXa && item.dataValue) return communeObj?.[item.dataValue];
        }).join(", ");
    }

    const openDetailModal = (e: any, item: DataListTable) => {
        navigate(`/data/info_init_detail/${item.key}`, { replace: true, state: { searchParam, page, size: pageSizeRef.current } });
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerReportTotal({ searchParam });

        triggerDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerCountDataInfoInit({
            searchParam: searchParam ? {
                ...searchParam,
                createDates: undefined,
                updateDates: undefined,
                typeId: searchParam.typeId ? searchParam.typeId : undefined,
                fileName: searchParam.fileName ? searchParam.fileName : undefined,
                status: searchParam.status ? (searchParam.status ? 1 : 0) : undefined,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined,
                fromCreateDate: searchParam?.createDates && searchParam.createDates[0] ? moment(searchParam.createDates[0]).format("YYYY-MM-DD") : undefined,
                toCreateDate: searchParam?.createDates && searchParam.createDates[1] ? moment(searchParam.createDates[1]).format("YYYY-MM-DD") : undefined,
                fromUpdateDate: searchParam?.updateDates && searchParam.updateDates[0] ? moment(searchParam.updateDates[0]).format("YYYY-MM-DD") : undefined,
                toUpdateDate: searchParam?.updateDates && searchParam.updateDates[1] ? moment(searchParam.updateDates[1]).format("YYYY-MM-DD") : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        navigate(".", { replace: true, state: { searchParam, page: newPage, size: pageSize } });
    }

    const toggleModal = () => {
        setCreateCampaignVisible(!createCampaignVisible);
    }

    const createCampaignSuccess = () => {
        toggleModal();
        getData(false);
        triggerCampaign();
    }

    // const checkPermission = (path: string) => {
    //     if (userState.username === "admin" || (userState?.authorities || []).indexOf("ROLE_SYSTEM_USER") !== -1) return true;
    //     return false;
    // }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                {/* <Col span={6}>
                </Col> */}
                <div>Tổng số: <strong>{responseReportTotal?.data}</strong> bản ghi</div>
                <div className='search-box-button'>
                    <Button htmlType="button"
                        type="primary"
                    >Xuất excel</Button>
                    {(canAddCampaign) ? <Button
                        disabled={!responseDataInfoInit?.data?.totalElements}
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Tạo chiến dịch</Button> : <></>}
                </div>
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => openDetailModal(event, record), // click row
                        onDoubleClick: event => openDetailModal(event, record), // double click row
                    };
                }}
                loading={responseDataInfoInit?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseCountDataInfoInit?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountDataInfoInit?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>

            {createCampaignVisible ?
                <CreateCampaign
                    searchParam={searchParam}
                    visible={createCampaignVisible}
                    toggleModal={toggleModal}
                    createCampaignSuccess={createCampaignSuccess}
                />
                : <></>}
        </div>
    )
}

export default RegisterInfoList;
