import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign, CommonListQuery, } from '../../../redux/api/apiTypes';
import { CampaginParams, CampaignResponse } from './apiTypes';

export const campaignApi = createApi({
    reducerPath: 'campaignApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getCampaign: builder.query<CampaignResponse, { searchParam?: CampaginParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/common/data-campaign/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: CampaignResponse }, meta, arg) => {
                return response.data;
            },
        }),
        getCampaignTotal: builder.query<number, { searchParam?: CampaginParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/common/data-campaign-count/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        getCampaignById: builder.query<Campaign, string>({
            query: (campaignId) => ({
                url: `/common/data-campaign/${campaignId}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: Campaign }, meta, arg) => {
                return response.data;
            },
        }),
        editCampaign: builder.mutation<Campaign, Campaign>({
            query: (body) => ({
                url: `/common/data-campaign/edit`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: Campaign }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useEditCampaignMutation,
    useLazyGetCampaignQuery,
    useLazyGetCampaignByIdQuery,
    useLazyGetCampaignTotalQuery
} = campaignApi;
export default campaignApi;