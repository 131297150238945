import { MenuOutlined } from '@ant-design/icons';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext } from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    useSortable,
    verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Table } from 'antd';
import React, { useState } from 'react';

interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
}

const Row = ({ children, ...props }: RowProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        setActivatorNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({
        id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
        ...props.style,
        transform: CSS.Transform.toString(transform && { ...transform, scaleY: 1 }),
        transition,
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
        <tr {...props} ref={setNodeRef} style={style} {...attributes}>
            {React.Children.map(children, (child) => {
                if ((child as React.ReactElement).key === 'sort') {
                    return React.cloneElement(child as React.ReactElement, {
                        children: (
                            <MenuOutlined
                                ref={setActivatorNodeRef}
                                style={{ touchAction: 'none', cursor: 'move' }}
                                {...listeners}
                            />
                        ),
                    });
                }
                return child;
            })}
        </tr>
    );
};

type Props = {
    columns: any,
    tableData: any,
    currentDataActive?: any,
    toggleEditDataModal: (data?: any) => void,
    setCurrentDataInitActive: (current: any) => void,
    setTableData: (newData: any) => void,
}

const DragAndDropTable = ({ columns, tableData, toggleEditDataModal, setCurrentDataInitActive, setTableData, currentDataActive }: Props) => {
    const onDragEnd = ({ active, over }: DragEndEvent) => {
        if (active.id !== over?.id) {
            setTableData((previous: any) => {
                const activeIndex = previous.findIndex((i: any) => i.key === active.id);
                const overIndex = previous.findIndex((i: any) => i.key === over?.id);
                return arrayMove(previous, activeIndex, overIndex);
            });
        }
    };
    const onRowClick=(record:any)=>{
        toggleEditDataModal();
        setCurrentDataInitActive(record);
    }
    return (
        <DndContext onDragEnd={onDragEnd}>
            <SortableContext
                // rowKey array
                items={tableData.map((i: any) => i.key)}
                strategy={verticalListSortingStrategy}
            >
                <Table
                    components={{
                        body: {
                            row: Row,
                        },
                    }}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick: event => {
                                if(!currentDataActive){
                                    onRowClick(record)
                            }else if(currentDataActive&&currentDataActive.id!==record.id){
                                    toggleEditDataModal(record);
                                }
                            }, // click row
                        };
                    }}
                    rowKey="key"
                    columns={[{
                        key: 'sort',
                        fixed: "left"
                    }, ...columns]}
                    dataSource={tableData}
                    tableLayout="auto"
                    pagination={false}
                    scroll={{ x: "max-content", y: 500 }}
                />
            </SortableContext>
        </DndContext>
    );
};

export default DragAndDropTable;
