import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { User, UpdateAccountByAdminParam, LockAccountParam, } from './apiTypes';

export const accountApi = createApi({
    reducerPath: 'accountApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/common",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getUserInfo: builder.query<User, any>({
            query: () => '/profile',
            transformResponse: (response: { data: User }, meta, arg) => {
                return response.data;
            },
        }),
        getUserInfoById: builder.query<User, number>({
            query: (id) => `${id}`,
            transformResponse: (response: { data: User }, meta, arg) => {
                return response.data;
            },
        }),
        updateUserByAdmin: builder.mutation<User, UpdateAccountByAdminParam>({
            query: (body) => ({
                url: `update-profile-by-admin`,
                method: 'PUT',
                body
            }),
            transformResponse: (response: { body: User }, meta, arg) => {
                return response.body;
            },
        }),
        deleteUserAccount: builder.mutation<User, number>({
            query: (id) => ({
                url: `${id}`,
                method: 'DELETE',
                body: id
            }),
            transformResponse: (response: { body: User }, meta, arg) => {
                return response.body;
            },
        }),
        resetUserPassword: builder.mutation<User, number>({
            query: (id) => ({
                url: `reset-password/${id}`,
                method: 'PUT',
                body: id
            }),
            transformResponse: (response: { body: User }, meta, arg) => {
                return response.body;
            },
        }),
        lockUnlockUserAccount: builder.mutation<any, LockAccountParam>({
            query: (body) => ({
                url: `lock`,
                method: 'PUT',
                body: body
            }),
            transformResponse: (response: { body: any }, meta, arg) => {
                return response.body;
            },
        }),
    })
})

export const { useLazyGetUserInfoQuery, useLazyGetUserInfoByIdQuery, useUpdateUserByAdminMutation,
    useDeleteUserAccountMutation, useResetUserPasswordMutation,
    useLockUnlockUserAccountMutation } = accountApi;
export default accountApi;