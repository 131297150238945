import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { CommonListQuery } from '../../../redux/api/apiTypes';
import { DataFilePdf, DataInfoInitParams, HistoryParams, HistoryResponse } from './apiTypes';

export const dataInitHistoryApi = createApi({
    reducerPath: 'dataInitHistoryApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        // Lấy danh sách lịch sử chỉnh sửa
        getHistory: builder.query<HistoryResponse, { searchParam?: HistoryParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init-history/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}&page=${body.page || 0}&size=${body.size || 10}&sort=createdDate,desc`,
                method: 'GET',
            }),
            transformResponse: (response: { data: HistoryResponse }, meta, arg) => {
                return response.data;
            },
        }),
        getCountDataInfoInit: builder.query<number, { searchParam?: DataInfoInitParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init-history/getPage-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        getDataInfoInitHistoryById: builder.query<any, string>({
            query: (id) => `/tool/data-info-init-history/${id}`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu random ​/tool-so-hoa​/api​/v1​/file​/pdf​/
        getFilePdfById: builder.query<DataFilePdf, any>({
            query: (body) => ({
                url: `/file/pdf/${body}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: DataFilePdf }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật thông tin nhập liệu
        updateDataInfoInit: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info-init/${body.id}/data-key-map`,
                method: 'PUT',
                body: {
                    fieldKeyMaps: body.keyMaps
                },
                params: {
                    communeId: body.communeId,
                    districtId: body.districtId,
                    provinceId: body.provinceId,
                    status: body.status || undefined
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy cấu hình trường dữ liệu theo loại file
        getFieldConfig: builder.query<any, any>({
            query: (body) => ({
                url: `/fields`,
                method: 'GET',
                params: {
                    types: body.types,
                    years: body.years
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useLazyGetHistoryQuery,
    useLazyGetCountDataInfoInitQuery,
    useUpdateDataInfoInitMutation,
    useLazyGetDataInfoInitHistoryByIdQuery,
    useLazyGetFilePdfByIdQuery,
    useLazyGetFieldConfigQuery,
} = dataInitHistoryApi;
export default dataInitHistoryApi;