import { Button, Col, Row, Menu, Dropdown, Table, Pagination, FormInstance, notification, Spin } from "antd";
import { ColumnsType } from "antd/es/table";
import { SettingOutlined } from '@ant-design/icons';
import { ReactComponent as Edit } from '../../../assets/logos/pen-edit.svg';
import { ReactComponent as X } from '../../../assets/logos/x-circle-solid.svg';
import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCheckExitFilePdfMutation, useLazyGetCountDataCheckQuery, useLazyGetDataCheckQuery } from "../redux/dataCheckApi";
import moment from "moment";
import CreateCampaign from "./CreateCampaign";
interface DataListTable {
    key: string,
    index: number,
    createBy?: string,
    createdDate?: string,
    filePdfPath: string,
    campaignName: string,
    fileName: string,
    address: string,
    filePdfExit: any,
    timeFilePdfExit: any,
    contents: any,
}

type Props = {
    form: FormInstance<any>,
    searchParam: any,
    triggerCampaign: () => void,
}

const RegisterInfoList = ({ form, searchParam, triggerCampaign }: Props) => {
    const [triggerDataInfo, responseDataInfo] = useLazyGetDataCheckQuery();
    const [triggerCountDataInfo, responseCountDataInfo] = useLazyGetCountDataCheckQuery();
    const [createCampaignVisible, setCreateCampaignVisible] = useState(false);
    const [checkExitFilePdf] = useCheckExitFilePdfMutation();
    const [tableData, setTableData] = useState<DataListTable[]>()
    const [total, setTotal] = useState(0);
    const [page, setPage] = useState(1);
    const pageSizeRef = useRef(10);
    const navigate = useNavigate();

    useEffect(() => {
        getData(false);
    }, [searchParam]);

    useEffect(() => {
        if (responseDataInfo?.data?.content?.length) {
            let newTableData: DataListTable[] = [];
            responseDataInfo.data.content.forEach((item, index) => {
                newTableData.push({
                    key: item.id,
                    index: index + 1,
                    createBy: item.createBy,
                    createdDate: item.createdDate ? moment(item.createdDate).format("HH:mm DD/MM/YYYY") : "",
                    timeFilePdfExit: item.timeFilePdfExit ? moment(item.timeFilePdfExit).format("HH:mm DD/MM/YYYY") : "",
                    filePdfExit: item.filePdfExit,
                    fileName: item.dataFile.fileAttachDocument.fileName,
                    campaignName: item.campaignName || "",
                    filePdfPath: item.filePdfPath,
                    contents: customContent(item),
                    address: [item.dataFile.province.provinceName, item.dataFile.district.districtName, item.dataFile.commune.communeName].join(", ")
                })
            })
            setTableData(newTableData);
        } else {
            setTableData([]);
        }
    }, [responseDataInfo]);

    const columns: ColumnsType<DataListTable> = [
        {
            title: "STT",
            dataIndex: "index",
            key: "index",
            align: "center",
            render: (value, record, index) => (page - 1) * pageSizeRef.current + index + 1,
            width: 70
        },
        // {
        //     title: "Xử lý",
        //     dataIndex: "action",
        //     key: "action",
        //     align: "center",
        //     width: 70,
        //     render: ((text, record) => (
        //         <Dropdown overlay={getContent(text, record)}>
        //             <SettingOutlined />
        //         </Dropdown>
        //     )),
        // },
        {
            title: "Nội dung",
            dataIndex: "content",
            key: "content",
            render: (value, record, index) => <div>
                {record?.contents?.map((item: any) => <div key={item.dataKey}><span style={{ fontWeight: 700 }}>{item.dataKey}: </span>{item.dataValue}</div>)}
            </div>,
        },
        {
            title: "File pdf",
            dataIndex: "filePdfPath",
            key: "filePdfPath",
            ellipsis: true,
            width: 200,
            render: (value, record, index) => <div>
                <span>{record.filePdfPath}</span>
                <div>
                    {record.filePdfExit ?
                        record.filePdfExit === 1 ? `Tồn tại (${record.timeFilePdfExit})` :
                            `Không tồn tại (${record.timeFilePdfExit})`
                        : "Chưa kiểm tra tồn tại"}
                    <span
                        onClick={(e) => onCheckExitFilePdf(e, record)}
                        style={{ color: "#1890ff", marginLeft: 10, cursor: "pointer" }}
                    >Kiểm tra</span>
                </div>
            </div>,
        },
        {
            title: "File excel",
            dataIndex: "fileName",
            key: "fileName",
        },
        {
            title: "Địa chỉ",
            dataIndex: "address",
            key: "address",
        },
        {
            title: "Chiến dịch hiện tại",
            dataIndex: "campaignName",
            key: "campaignName",
        },
        // {
        //     title: "Người tải",
        //     dataIndex: "createBy",
        //     key: "createBy",
        // },
        // {
        //     title: "Ngày tải",
        //     dataIndex: "createdDate",
        //     key: "createdDate",
        // },
    ];

    const getData = (getCurrentPage: boolean) => {
        triggerDataInfo({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: getCurrentPage ? page - 1 : 0,
            size: pageSizeRef.current
        });
        triggerCountDataInfo({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: getCurrentPage ? page - 1 : 0,
            size: pageSizeRef.current
        })
        if (!getCurrentPage) {
            setPage(1);
        }
    }

    const customContent = (dataInfo: any) => {
        let objectDataKey = dataInfo?.keyMaps?.reduce((a: any, v: any) => ({ ...a, [v.dataKeyAscii]: v }), {});
        let listDataKey = dataInfo?.keyMapSort?.keyMaps?.filter((item: any) => item.isDefault);
        if (!listDataKey || !listDataKey.length) {
            return [];
        }
        return listDataKey.map((item: any) => {
            return {
                ...item,
                dataValue: objectDataKey[item.dataKeyAscii]?.dataValue || ""
            }
        })
    }

    const onCheckExitFilePdf = (e: any, record: DataListTable) => {
        e.stopPropagation();
        checkExitFilePdf(record.key).unwrap()
            .then(res => {
                getData(true);
            }).catch(err => {
                notification.error({ message: "Có lỗi xảy ra!" });
            });
    }

    const getContent = (text: string, record: DataListTable) => {
        return (
            <Menu>
                <Menu.Item onClick={(e) => openDetailModal(e, record)} key="edit">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Edit style={{ width: 24, height: 24, marginRight: 10 }} />Xem chi tiết
                    </div>
                </Menu.Item>
            </Menu>
        )
    }

    const openDetailModal = (e: any, item: DataListTable) => {
        navigate(`/data/detail/${item.key}`, { replace: true, state: searchParam });
    }

    const changePage = (currentPage: number, pageSize: number) => {
        const pageSizeChange = pageSizeRef.current !== pageSize;
        let newPage = currentPage;
        if (pageSizeChange) {
            newPage = 1;
        }
        setPage(newPage);
        pageSizeRef.current = pageSize;
        triggerDataInfo({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
        triggerCountDataInfo({
            searchParam: searchParam ? {
                ...searchParam,
                isCheck: searchParam.isCheck ? (searchParam.isCheck === 1 ? true : false) : undefined
            } : "",
            page: newPage - 1,
            size: pageSizeRef.current
        })
    }

    const toggleModal = () => {
        setCreateCampaignVisible(!createCampaignVisible);
    }

    const createCampaignSuccess = () => {
        toggleModal();
        getData(false);
        triggerCampaign();
    }

    return (
        <div className='content-box'>
            <Row justify="space-between" align="middle" style={{ marginBottom: 15 }}>
                <Col span={6}>
                    <Link to='/data/import'>
                        <Button>Import dữ liệu</Button>
                    </Link>
                </Col>
                <div className='search-box-button'>
                    {/* <Button htmlType="button"
                        type="primary"
                    >Xuất excel</Button> */}
                    <Button
                        disabled={!responseDataInfo?.data?.totalElements}
                        htmlType="button"
                        type="primary"
                        onClick={toggleModal}
                    >Tạo chiến dịch</Button>
                </div>
            </Row>
            <Table<DataListTable>
                onRow={(record, rowIndex) => {
                    return {
                        onClick: event => openDetailModal(event, record), // click row
                        onDoubleClick: event => openDetailModal(event, record), // double click row
                    };
                }}
                loading={responseDataInfo?.isFetching}
                dataSource={tableData}
                columns={columns}
                tableLayout="auto"
                pagination={false}
                scroll={{ x: "max-content" }}
            />

            <Spin spinning={responseCountDataInfo?.isFetching} tip="Đang tải...">
                <Pagination
                    total={responseCountDataInfo?.data || 0}
                    defaultPageSize={pageSizeRef.current}
                    defaultCurrent={1}
                    locale={{ items_per_page: ' dòng' }}
                    pageSizeOptions={[10, 20, 50, 100]}
                    onChange={changePage}
                    showSizeChanger
                    current={page}
                />
            </Spin>

            {createCampaignVisible ?
                <CreateCampaign
                    searchParam={searchParam}
                    visible={createCampaignVisible}
                    toggleModal={toggleModal}
                    createCampaignSuccess={createCampaignSuccess}
                />
                : <></>}
        </div>
    )
}

export default RegisterInfoList;