import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { Campaign, CommonListQuery } from '../../../redux/api/apiTypes';
import { DataInfoInit, DataFilePdf, DataInfoInitParams, DataInfoResponse, HistoryParams, HistoryResponse, DataInfoInitHistory } from './apiTypes';

export const dataSyncApi = createApi({
    reducerPath: 'dataSyncApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getDataInfoInit: builder.query<DataInfoResponse, { searchParam?: DataInfoInitParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init/getPage?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
                params: {
                    page: body.page || 0,
                    size: body.size || 10
                }
            }),
            transformResponse: (response: { data: DataInfoResponse }, meta, arg) => {
                return response.data;
            },
        }),
        getDataInfoInitByFilePdf: builder.query<DataInfoInit[], string>({
            query: (id) => ({
                url: `/tool/data-info-init-compare/list-compare-by-pdf?compareId=${id}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: DataInfoInit[] }, meta, arg) => {
                return response.data;
            },
        }),
        getCountDataInfoInit: builder.query<number, { searchParam?: DataInfoInitParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init/getPage-count?search=${body.searchParam ? encodeURIComponent(JSON.stringify(body.searchParam)) : ""}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: number }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách lịch sử chỉnh sửa
        getHistory: builder.query<DataInfoInitHistory[],  HistoryParams & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `/tool/data-info-init/history`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: DataInfoInitHistory[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy danh sách lịch sử chỉnh sửa
        getAllHistory: builder.query<HistoryResponse, string>({
            query: (dataInfoId) => ({
                url: `/tool/data-info-history/${dataInfoId}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: HistoryResponse }, meta, arg) => {
                return response.data;
            },
        }),
        getDataInfoInitById: builder.query<any, string>({
            query: (id) => `/tool/data-info-init/${id}`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        getDataInfoInitByFilePdfId: builder.query<any, string>({
            query: (id) => `/tool/data-info-init/${id}`,
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu tiếp theo từ bản ghi hiện tại
        getNextDataInfoById: builder.query<DataInfoInit, string>({
            query: (id) => `/tool/data-info/next/${id}`,
            transformResponse: (response: { data: DataInfoInit }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu random
        getRandomDataPdf: builder.query<any, any>({
            query: (id?: string) => ({
                url: `/tool/data-info-init-compare/random-compare`,
                method: 'GET',
                params:{
                    compareId: id?id:''
                }
            }),
            transformResponse: (response: any, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu random ​/tool-so-hoa​/api​/v1​/file​/pdf​/
        getFilePdfById: builder.query<any, any>({
            query: (body) => ({
                url: `/file/pdf/${body}`,
                method: 'GET',
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy dữ liệu random ​/tool-so-hoa​/api​/v1​/file​/pdf​/
        getFilePdfByName: builder.query<DataFilePdf[], any>({
            query: (body) => ({
                url: `/file/pdf-by-name`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: DataFilePdf[] }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật trạng thái file pdf
        updateStatusFilePdf: builder.mutation<any, any>({
            query: (body) => ({
                url: `/file/check-pdf/${body.id}`,
                method: 'POST',
                params: {
                    isCheck: body.isCheck,
                    status: body.status ? 1 : 0
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        updateCompare: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info-init-compare/update-compare`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật trạng thái file pdf
        lackFilePdf: builder.mutation<any, any>({
            query: (body) => ({
                url: `/file/lack-pdf/${body.id}`,
                method: 'POST',
                params: {
                    isLack: body?.isLack,
                    status: body?.status
                },
                body: body?.message
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Thêm mới thông tin nhập liệu
        insertDataInfoInit: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info-init`,
                method: 'POST',
                body: body.keyMaps,
                params: {
                    file_pdf_id: body.file_pdf_id,
                    isCheck: body.isCheck,
                    typeId: body.typeId,
                    communeId: body.communeId,
                    districtId: body.districtId,
                    provinceId: body.provinceId,
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Cập nhật thông tin nhập liệu
        updateDataInfoInit: builder.mutation<any, any>({
            query: (body) => ({
                url: `/tool/data-info-init/${body.id}/data-key-map`,
                method: 'PUT',
                body: {
                    fieldKeyMaps: body.keyMaps
                },
                params: {
                    communeId: body.communeId,
                    districtId: body.districtId,
                    provinceId: body.provinceId,
                    status: body.status || undefined
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Check exists file pd
        checkExistsFilePdf: builder.query<any, any>({
            query: (body) => ({
                url: `/file/check-exits-pdf`,
                method: 'POST',
                body: {
                    fieldKeyMaps: body.keyMaps
                },
                params: {
                    dataInfoInitId: body.dataInfoId,
                    filePDFName: body.filePDFName || undefined
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Lấy cấu hình trường dữ liệu theo loại file
        getFieldConfig: builder.query<any, any>({
            query: (body) => ({
                url: `/fields`,
                method: 'GET',
                params: {
                    types: body.types,
                    years: body.years
                }
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
        // Thêm mới chiến dịch
        createCampaign: builder.mutation<any, Campaign>({
            query: (body) => ({
                url: `/tool/data-info-init/campaign`,
                method: 'POST',
                body
            }),
            transformResponse: (response: { data: any }, meta, arg) => {
                return response.data;
            },
        }),
    })
})

export const {
    useLazyGetAllHistoryQuery,
    useLazyGetHistoryQuery,
    useLazyGetDataInfoInitQuery,
    useLazyGetCountDataInfoInitQuery,
    useLazyGetNextDataInfoByIdQuery,
    useUpdateStatusFilePdfMutation,
    useLackFilePdfMutation,
    useUpdateDataInfoInitMutation,
    useUpdateCompareMutation,
    useInsertDataInfoInitMutation,
    useLazyGetDataInfoInitByIdQuery,
    useLazyGetDataInfoInitByFilePdfQuery,
    useLazyGetRandomDataPdfQuery,
    useLazyGetFilePdfByIdQuery,
    useLazyGetFilePdfByNameQuery,
    useLazyGetFieldConfigQuery,
    useCreateCampaignMutation,
    useLazyCheckExistsFilePdfQuery,
} = dataSyncApi;
export default dataSyncApi;
