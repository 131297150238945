import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
export const loginApi = createApi({
    reducerPath: 'publicApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/public/v1",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        login: builder.mutation({
            query: (credentials) => ({
                url: '/sso/jwt-login',
                method: 'POST',
                body: credentials
            })
        }),

    })
})

export const { useLoginMutation } = loginApi;