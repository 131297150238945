import { Button, Col, Form, Input, notification, Row, Select, Spin, Upload } from "antd";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { TYPE_DATA } from "../../../assets/ts/constant";
import { ReactComponent as XIcon } from '../../../assets/logos/x-solid.svg';
import confirm from "antd/lib/modal/confirm";
import { exportFile } from "../../common/assets/CommonFunctions";
import { useLazyGetCommuneQuery, useLazyGetDistrictQuery, useLazyGetProvinceQuery } from "../../../redux/api/commonApi";
import { Commune, District } from "../../../redux/api/apiTypes";
import { useImportDataInfoMutation, useUploadFilePlaceMutation } from "../redux/dataCheckApi";

const ImportData = () => {
    const [form] = Form.useForm();
    const [loadingFile, setLoadingFile] = useState(false);
    const [fileList, setFileList] = useState<any>([]);
    const [fileError, setFileError] = useState('');
    const [uploadedFiles, setUploadedFiles] = useState<any>([]);
    const [uploadFilePlace] = useUploadFilePlaceMutation();
    const [triggerProvince, responseProvince] = useLazyGetProvinceQuery();
    const [districtTrigger, responseDistrict] = useLazyGetDistrictQuery();
    const [communeTrigger, responseCommune] = useLazyGetCommuneQuery();
    const [triggerImport, { isLoading }] = useImportDataInfoMutation();
    const [districts, setDistricts] = useState<District[]>([]);
    const [communes, setCommunes] = useState<Commune[]>([]);
    const [currentValue, setCurrentValue] = useState<any>();
    const [headers, setHeaders] = useState<any>();
    const navigate = useNavigate();
    useEffect(() => {
        triggerProvince({});
    }, []);
    const onFinish = () => {
        triggerImport({
            ...currentValue,
            typeName: TYPE_DATA[currentValue.typeId],
            fileIds: uploadedFiles.map((item: any) => item.id)
        }).unwrap()
            .then(res => {
                notification.success({
                    message: `Đăng tải dữ liệu thành công!`
                });
                navigate("/data/list", { replace: true });
            }).catch(err => {
                console.log(err);
                notification.error({ message: err?.data?.message || "Đăng tải dữ liệu thất bại!" });
            })
    }
    const customUpload = (options: any) => {
        setLoadingFile(true);
        options.onProgress({ percent: 0 })
        let formData = new FormData();
        formData.append('file', options.file);
        uploadFilePlace({
            file: formData,
            ...currentValue,
            typeName: TYPE_DATA[currentValue.typeId],
        }).unwrap()
            .then(res => {
                options.onProgress({ percent: 100 });
                options.onSuccess(res, options.file);
                setUploadedFiles([...uploadedFiles, res.file]);
                form.setFieldsValue({ attachment: [...uploadedFiles, res.file] });
                setLoadingFile(false);
                if (!headers || headers.length) {
                    setHeaders({
                        file: res.file,
                        headers: res.header
                    });
                }
            }).catch(err => {
                options.onError();
                setLoadingFile(false);
                if (err?.data?.message) {
                    notification.error({
                        message: err?.data?.message
                    })
                }
            })
    }
    const beforeUpload = (files: File[]) => {
        let accepted = true;
        let fileList = [...files];
        fileList = fileList.slice(-1);
        Array.from(fileList).forEach(file => {
            let error = ''
            if (file.type !== "application/vnd.ms-excel" &&
                file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
                accepted = false;
                error = 'Định dạng file không hợp lệ!'
            }
            setFileError(error)
        })
        return accepted || Upload.LIST_IGNORE;
    }
    const onFileRemove = (item: any, index: number) => {
        return new Promise<boolean>((resolve, reject) => {
            confirm({
                title: 'Xoá file đã chọn?',
                okText: "Đồng ý",
                cancelText: "Hủy bỏ",
                onOk: () => {
                    if (uploadedFiles) {
                        let newUploadedFiles = uploadedFiles;
                        newUploadedFiles.splice(index, 1);
                        setUploadedFiles([...newUploadedFiles])
                        form.setFieldsValue({
                            attachment: []
                        })
                        form.validateFields(["attachment"])
                    }
                    resolve(true);
                },
                onCancel: () => {
                    resolve(false)
                }
            })
        })
    }
    const downloadFile = (id: number, name: string) => {
        exportFile(process.env.REACT_APP_API_URL + "/api/v1/file/downloadFile/" + id, name);
    }

    const disableButtonUpload = () => {
        if (!currentValue ||
            !currentValue.typeId ||
            !currentValue.provinceId ||
            !currentValue.districtId ||
            !currentValue.communeId) return true;
        return false;
    }

    const onValuesChanged = (changedValues: any) => {
        if (Object.keys(changedValues)[0] == "provinceId") {
            setCommunes([]);
            setDistricts([]);
            if (changedValues.provinceId) {
                districtTrigger(changedValues.provinceId).unwrap()
                    .then(res => setDistricts(res));
            }
            form.setFieldsValue({
                districtId: undefined,
                communeId: undefined,
            })
            setCurrentValue({
                ...currentValue,
                ...changedValues,
                districtId: undefined,
                communeId: undefined,
            })
        } else if (Object.keys(changedValues)[0] == "districtId") {
            setCommunes([]);
            if (changedValues.districtId) {
                communeTrigger(changedValues.districtId).unwrap()
                    .then(res => setCommunes(res));
            }
            form.setFieldsValue({
                communeId: undefined
            })
            setCurrentValue({
                ...currentValue,
                ...changedValues,
                communeId: undefined,
            })
        } else {
            setCurrentValue({
                ...currentValue,
                ...changedValues,
            })
        }
    }

    return (
        <div className='content-box'>
            <Spin tip="Đang tải..." spinning={isLoading}>
                <Form
                    name="searchBox"
                    onFinish={onFinish}
                    autoComplete="off"
                    form={form}
                    colon={false}
                    labelCol={{ span: 5 }}
                    wrapperCol={{ span: 19 }}
                    onValuesChange={onValuesChanged}
                >
                    <label className='search-title'>Đăng tải dữ liệu</label>
                    <Row gutter={16} style={{ marginTop: 8, marginBottom: 8 }}>
                        <Col span={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Loại dữ liệu"
                                name="typeId"
                                rules={[
                                    { required: true, message: "Dữ liệu bắt buộc!", }
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn loại dữ liệu"
                                    optionFilterProp='label'
                                    options={Object.keys(TYPE_DATA).map(item => ({
                                        label: TYPE_DATA[item],
                                        value: item
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}></Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tên cột file pdf"
                                name="pdfFileHeader"
                                rules={[
                                    { required: true, message: "Dữ liệu bắt buộc!", }
                                ]}
                            >
                                <Input placeholder="Nhập tên cột file pdf" style={{ minWidth: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Tỉnh/Thành phố"
                                name="provinceId"
                                rules={[
                                    { required: true, message: "Dữ liệu bắt buộc!", }
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn tỉnh thành"
                                    optionFilterProp='label'
                                    options={(responseProvince?.data || []).map(item => ({
                                        label: item.provinceName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Quận/Huyện"
                                name="districtId"
                                rules={[
                                    { required: true, message: "Dữ liệu bắt buộc!", }
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn quận huyện"
                                    optionFilterProp='label'
                                    options={(districts || []).map(item => ({
                                        label: item.districtName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="Phường/Xã"
                                name="communeId"
                                rules={[
                                    { required: true, message: "Dữ liệu bắt buộc!", }
                                ]}
                            >
                                <Select
                                    allowClear
                                    placeholder="Chọn phường xã"
                                    options={(communes || []).map(item => ({
                                        label: item.communeName,
                                        value: item.id
                                    }))}
                                    showSearch
                                    showArrow
                                >
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                style={{ marginBottom: 10 }}
                                label="File dữ liệu"
                                name="attachment"
                                rules={[
                                    { required: true, message: "Dữ liệu bắt buộc!", }
                                ]}
                            >
                                <Upload
                                    showUploadList={false}
                                    customRequest={(options) => customUpload(options)}
                                    accept=".xlsx, .xls"
                                    beforeUpload={(file, fileList) => beforeUpload(fileList)}
                                    style={{ marginBottom: 0 }}
                                    multiple={true}
                                    listType="text"
                                >
                                    <Button
                                        type="primary"
                                        loading={loadingFile}
                                        style={{ marginBottom: 15, marginLeft: 0 }}
                                        disabled={disableButtonUpload()}
                                    >Lựa chọn</Button>
                                    {fileError ? <div style={{ color: 'red' }}>{fileError}</div> : <></>}
                                </Upload>
                                {(uploadedFiles && uploadedFiles.length) ? uploadedFiles.map((item: any, index: number) => {
                                    return (
                                        <div className='file-wrapper' style={{ marginTop: 10 }} key={item?.id || item.uid}>
                                            <span style={{ cursor: 'pointer' }} className="anchor-text" onClick={() => downloadFile(item?.id, item.fileName)}>{item.fileName}</span>
                                            <XIcon
                                                onClick={() => onFileRemove(item, index)}
                                                style={{ cursor: 'pointer', width: 20, height: 20, marginLeft: 20, marginBottom: 0 }} />
                                        </div>
                                    )
                                }) : <></>}
                            </Form.Item>
                        </Col>
                    </Row>

                    <div className='search-box-button'>
                        <Link to='/data/list'><Button htmlType='button'>
                            Quay lại
                        </Button></Link>
                        <Button htmlType='submit' type='primary'>Import</Button>
                    </div>
                </Form>
            </Spin>
        </div >
    )
}

export default ImportData;