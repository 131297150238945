import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/dist/query/react";
import { CommonAPIResponse, CommonListQuery } from "../../../redux/api/apiTypes";
import { DataRoleResponse, RoleParams } from "./apiTypes";


export const permissionsApi = createApi({
    reducerPath: 'permissionsApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL + "/api/v1/role",
        prepareHeaders: (headers, { getState }) => {
            const token = localStorage.getItem("accessToken");
            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers;
        }
    }),
    endpoints: (builder) => ({
        getFullRole: builder.query<any, any>({
            query: (body) => ({
                url: `full-role`,
                method: 'GET',
            }),
           transformResponse: (response : {data: any }, meta, arg) => {
                return response.data;
            }
        }),
        // get All
        getAllRole: builder.query<DataRoleResponse[], any>({
            query: (body) => ({
                params: {
                    search: JSON.stringify(body),
                    status: 0,
                    isDelete: 0,
                }, 
                url: `all`,
                method: 'GET',
            }),
           transformResponse: (response : {data: DataRoleResponse[] }, meta, arg) => {
                return response.data;
            }
        }),

        // get Page
        getRole: builder.query<CommonAPIResponse<DataRoleResponse>, { searchParam?: RoleParams } & Partial<CommonListQuery>>({
            query: (body) => ({
                url: `getPage`,
                method: 'GET',
                params: {
                    search: JSON.stringify({
                        roleName: body?.searchParam?.roleName,
                        description: body?.searchParam?.description,
                        status: body?.searchParam?.status,
                    }),
                    pageNumber: body.page || 0,
                    pageSize: body.size || 10,
                    size: body.size || 10,
                    sortBy: body.sortBy || "DESC",
                    sortByProperty: body.sortByProperty || "modifiedDate",
                }
            }),
            transformResponse: (response : {data: CommonAPIResponse<DataRoleResponse>}, meta, arg) => {
                return response.data;
            }
        }),

        // chi tiet Role
        getDetailRole: builder.query<any, any>({
            query: (id) => ({
                url: `/${id}`,
                method: 'GET',
            }),
           transformResponse: (response : {data: any }, meta, arg) => {
                return response.data;
            }
        }),

         // thêm mới 
        addRole: builder.mutation<DataRoleResponse, Partial<DataRoleResponse>>({
            query: (body) => ({
                url: `add`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { body: DataRoleResponse }, meta, arg
            ) => {
                return response.body;
            },
        }),

        // chỉnh sửa 
        editRole: builder.mutation<DataRoleResponse, Partial<DataRoleResponse>>({
            query: (body) => ({
                url: `edit`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DataRoleResponse, message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),

        // xoá 
        deleteRole: builder.mutation<DataRoleResponse[], string[]>({
            query: (body) => ({
                url: `delete`,
                method: "POST",
                body: body,
            }),
            transformResponse: (
                response: { data: DataRoleResponse[], message: string }, meta, arg
            ) => {
                return response.data;
            },
        }),
    

        
    })
})

export const {
    useLazyGetFullRoleQuery,
    useLazyGetAllRoleQuery,
    useLazyGetRoleQuery,
    useLazyGetDetailRoleQuery,
    useAddRoleMutation,
    useEditRoleMutation,
    useDeleteRoleMutation,
} = permissionsApi;
export default permissionsApi;
